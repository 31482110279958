import React, { useState } from 'react';
import {
  IonButton,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react';

import {
  ProcessingFieldsFragment
} from 'apollo/queries/types';

import { arrowForward, chevronDown } from 'ionicons/icons';

interface MaterialProcessingHistoryItemProps {
  processing: ProcessingFieldsFragment & {
    techId?: {
      id: number;
      name?: string | null;
    } | null;
  };
}

const MaterialProcessingHistoryItem: React.FC<MaterialProcessingHistoryItemProps> = ({ processing }) => {
  const [ expanded, setExpanded ] = useState<boolean>(false);

  // @ts-ignore
  return (
    <>
      <IonItem
        onClick={() => setExpanded(!expanded)}
      >
        <IonLabel>
          {processing.name}
        </IonLabel>
        <IonIcon
          className={expanded ? 'rotate-180' : 'rotate-0'}
          size="small"
          icon={chevronDown}
          slot="end"
        />
      </IonItem>
      {
        expanded && (
          <IonCard style={{ width: '100%', margin: '0px 0px 5px 0px', padding: '5px' }}>
            <IonText>
              Technicien: {processing.techId?.name ? processing.techId?.name : 'N/A'}
            </IonText>
            <br />
            <IonItem>
              <IonButton size="small"  fill="clear" slot="end" routerLink={`/processing/${processing.id}/old`}>
                <IonIcon slot="end" icon={arrowForward} />
                Voir plus
              </IonButton>
            </IonItem>
          </IonCard>
        )
      }
    </>

  );
};

export default MaterialProcessingHistoryItem;
