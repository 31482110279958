import React, { useState } from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { InterventionReportFieldsFragment } from 'apollo/queries/types';
import InterventionReport from 'components/InterventionReport';
import { chevronDown } from 'ionicons/icons';

import { formatDateStrForMobile } from 'utils/formatDate';

import './styles.css';

interface InterventionReportListProps {
  reports: InterventionReportFieldsFragment[];
};

const InterventionReportList: React.FC<InterventionReportListProps> = ({ reports }) => {
  const [ expanded, setExpanded ] = useState<number[]>([]);

  const handleExpand = (id: number): void => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter(n => n !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  return (
    <>
      {reports.length === 0 ?
        null :
        reports.length === 1 ?
          <InterventionReport
            report={reports[0]}
            setReport={() => {}}
            edit={false}
          />
          :
          reports.map(report =>
            <React.Fragment key={report.id}>
              <div
                onClick={() => handleExpand(report.id)}
                className="expandable-button-item"
              >
                <IonText slot="start">
                  {
                    (
                      new Date(
                        formatDateStrForMobile(
                          report?.endDate || ''
                        )
                      )
                    ).toLocaleDateString('fr-FR')
                  }
                </IonText>
                <IonIcon
                  className={expanded.includes(report.id) ? 'rotate-180' : 'rotate-0'}
                  style={{ float: 'right' }}
                  size="small"
                  icon={chevronDown}
                />
              </div>
              {expanded.includes(report.id) &&
                  <InterventionReport
                    report={report}
                    setReport={() => {}}
                    edit={false}
                  />
              }
            </React.Fragment>
          )
      }
    </>
  );
};

export default InterventionReportList;