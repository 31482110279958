import React from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonNote, IonMenuToggle, IonIcon, IonLabel, IonImg } from '@ionic/react';
import { alertCircleOutline, alertSharp, logOutOutline, logOutSharp } from 'ionicons/icons';

import useNotifier from 'utils/notification';
import { logout } from 'utils/authentication';
import { formatDate } from 'utils/formatDate';

import './styles.css';
import { useCurrentUserQuery } from 'apollo/queries';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Interventions',
    url: '/interventions',
    iosIcon: alertCircleOutline,
    mdIcon: alertSharp,
  },
];

interface MenuProps {
  afterLogout?: () => void;
};

const getTodayText = () => {
  return formatDate(
    new Date(),
    {
      hour: undefined,
      minute: undefined,
    }
  );
};

const Menu: React.FC<MenuProps> = ({ afterLogout }) => {
  const notify = useNotifier();

  const { data } = useCurrentUserQuery();

  const tryLogout: React.MouseEventHandler<HTMLIonItemElement> = () => {
    logout(data?.getMyData?.name ?? undefined)
      .then(
        (logoutResult) => {
          notify(
            {
              color: 'success',
              message: 'Déconnexion réussie !',
            }
          );

          if (afterLogout  !== undefined) {
            afterLogout();
          }
        }
      )
      .catch(
        (error) => {
          console.error(
            `[ERROR] <Menu.tryLogout> Login failed :`,
            error,
          );

          notify(
            {
              color: 'danger',
              message: 'Échec de la déconnexion.',
            }
          );
        },
      );
  };

  return (
    <>
      <IonMenu side="end" contentId="main" swipeGesture type="overlay">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle slot="start" id="user-image">
              {
                data?.getMyData?.image128 && <IonImg src={`data:image/jpg;base64,${data?.getMyData?.image128}`} />
              }
              <span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>Quicker</span>
            </IonTitle>
            <IonTitle slot="end" size="small">{getTodayText()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonNote>{data?.getMyData?.name}</IonNote>
          <IonList id="main-list">
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={window.location.pathname === appPage.url ? 'selected' : ''}
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
          <IonList>
            <IonMenuToggle autoHide={false}>
              <IonItem
                lines="none"
                detail={false}
                onClick={tryLogout}
              >
                <IonIcon ios={logOutOutline} md={logOutSharp} />
                <IonLabel>
                  Déconnexion
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default Menu;
