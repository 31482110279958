import React from 'react';
import { JSXChild, JSXChildren } from './types';
import { buildLuqComponent } from './compile';


export type AndProps = {
  children: JSXChildren;
};

export const And: React.FC<AndProps> = buildLuqComponent('And');


export type OrProps = {
  children: JSXChildren;
};

export const Or: React.FC<OrProps> = buildLuqComponent('Or');


export type NotProps = {
  children: JSXChild;
};

export const Not: React.FC<NotProps> = buildLuqComponent('Not');


export type DefinedProps = {
  children?: undefined;
  field: string;
};

export const Defined: React.FC<DefinedProps> = buildLuqComponent('Defined');


export type UndefinedProps = {
  children?: undefined;
  field: string;
};

export const Undefined: React.FC<UndefinedProps> = buildLuqComponent('Undefined');


export type EqProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Eq: React.FC<EqProps> = buildLuqComponent('Eq');


export type NeqProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Neq: React.FC<NeqProps> = buildLuqComponent('Neq');


export type GtProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Gt: React.FC<GtProps> = buildLuqComponent('Gt');


export type GteProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Gte: React.FC<GteProps> = buildLuqComponent('Gte');


export type LtProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Lt: React.FC<LtProps> = buildLuqComponent('Lt');


export type LteProps = {
  children?: undefined;
  field: string;
  value: unknown;
};

export const Lte: React.FC<LteProps> = buildLuqComponent('Lte');


export type LikeProps = {
  children?: undefined;
  field: string;
  pattern: unknown;
  caseInsensitive?: boolean;
};

export const Like: React.FC<LikeProps> = buildLuqComponent('Like');


export type InProps = {
  children?: undefined;
  field: string;
  values: unknown[];
};

export const In: React.FC<InProps> = buildLuqComponent('In');


export type NotInProps = {
  children?: undefined;
  field: string;
  values: unknown[];
};

export const NotIn: React.FC<NotInProps> = buildLuqComponent('NotIn');