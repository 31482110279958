import React from 'react';
import { IonText } from '@ionic/react';

interface SectionTitleProps {
  title: string,
  children?: React.ReactNode
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, children }) => {
  return (
    <IonText className="main-title">
      <h3 className="custom-section-title">
        {title}
        {children}
      </h3>
    </IonText>
  );
};

export default SectionTitle;
