import {
  IonItem,
  IonList,
  IonListHeader,
  IonLabel,
  IonIcon,
} from '@ionic/react';

import {
  MaterialFieldsFragment,
  ProcessingFieldsFragment,
} from 'apollo/queries/types';
import { checkmarkCircleOutline, constructOutline, timeOutline } from 'ionicons/icons';

import './styles.css';

const getIcon = (state: string|null|undefined): string => {
  switch (state) {
    case 'waiting':
      return timeOutline;
    case 'open':
      return constructOutline;
    case 'closed':
    case 'report':
      return checkmarkCircleOutline;
    default:
      return timeOutline;
  }
};
interface ProcessingListProps {
  title?: string;
  processings: (ProcessingFieldsFragment & { materialId?: MaterialFieldsFragment|null })[];
}

const ProcessingList: React.FC<ProcessingListProps> = ({ title, processings }) => {
  return (
    <IonList>
      {
        title &&
          <IonListHeader>
            {title}
          </IonListHeader>
      }
      {
        processings.map(
          (processing) => (
            <IonItem
              key={processing.materialId?.id}
              className="processing-list-item"
              routerLink={`/processing/${processing.id}`}
            >
              <IonLabel style={{ whiteSpace: 'unset' }}>
                <h2>{processing.name}</h2> 
                <p>Matériel : {processing.materialId?.materialType?.name}{processing.materialId?.number && <> N°{processing.materialId?.number}</>}</p> 
              </IonLabel>
              <IonIcon color={
                processing.state?.key === 'waiting' ? 
                  'primary' :
                  processing.state?.key === 'open' ?
                    'warning' :
                    'success'
              } slot="end" icon={getIcon(processing.state?.key)} />
            </IonItem>
          )
        )
      }
    </IonList>
  );
};

export default ProcessingList;
