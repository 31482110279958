import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on MaintenanceImage {
    id
    name
    url128
    url1920
  }
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
    street
    street2
    zip
    city
    phone
    mobile
    email
  }
`;
export const SelectionFragmentDoc = gql`
  fragment Selection on Selection {
    key
    value
  }
`;
export const SiteFieldsFragmentDoc = gql`
  fragment SiteFields on MaintenanceSite {
    id
    name
    street
    street2
    zip
    city
    phone
    mobile
    email
    displayAddress
    partnerId {
      ...PartnerFields
    }
    clientId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const InterventionFieldsLightFragmentDoc = gql`
  fragment InterventionFieldsLight on MaintenanceIntervention {
    id
    name
    description
    clientId {
      ...PartnerFields
      childIds {
        ...PartnerFields
      }
      image128
    }
    typeId {
      id
      name
    }
    domainId {
      id
      name
    }
    interventionDate
    state {
      ...Selection
    }
    siteId {
      ...SiteFields
    }
  }
  ${PartnerFieldsFragmentDoc}
  ${SelectionFragmentDoc}
  ${SiteFieldsFragmentDoc}
`;
export const ZoneFieldsFragmentDoc = gql`
  fragment ZoneFields on MaintenanceSiteZone {
    id
    name
    description
  }
`;
export const InterventionFieldsFullFragmentDoc = gql`
  fragment InterventionFieldsFull on MaintenanceIntervention {
    ...InterventionFieldsLight
    comment
    createDate
    orderDate
    writeDate
    startDate
    endDate
    origin
    zoneId {
      ...ZoneFields
    }
  }
  ${InterventionFieldsLightFragmentDoc}
  ${ZoneFieldsFragmentDoc}
`;
export const InterventionReportFieldsFragmentDoc = gql`
  fragment InterventionReportFields on MaintenanceInterventionReport {
    id
    name
    state {
      ...Selection
    }
    startDate
    endDate
    description
    comment
    signature
    onContract
    nbNights
    nbMeals
    journeyTime
    productionStop
    multipleTechs
    multipleTechsComment
    forceCloseIntervention
  }
  ${SelectionFragmentDoc}
`;
export const MaterialFieldsFragmentDoc = gql`
  fragment MaterialFields on MaintenanceMaterial {
    id
    name
    description
    comment
    displayName
    number
    barcode
    installDate
    previousTurnover
    nextTurnover
    materialType {
      id
      name
    }
    clientId {
      id
      name
      image128
    }
    siteId {
      id
      name
    }
    zoneId {
      id
      name
    }
  }
`;
export const OperationFieldsFragmentDoc = gql`
  fragment OperationFields on MaintenanceOperation {
    id
    name
    description
    comment
    date
    done
  }
`;
export const ProcessingFieldsFragmentDoc = gql`
  fragment ProcessingFields on MaintenanceProcessing {
    id
    name
    description
    startDate
    endDate
    result
    materialKeepedStopped
    installationOk
    comment
    state {
      ...Selection
    }
  }
  ${SelectionFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on MaintenanceProduct {
    id
    name
    description
    ref
    barcode
  }
`;
export const StockZoneFieldsFragmentDoc = gql`
  fragment StockZoneFields on MaintenanceStockZone {
    id
    name
    type {
      key
      value
    }
    external
  }
`;
export const StockFieldsFragmentDoc = gql`
  fragment StockFields on MaintenanceStock {
    id
    quantity
    articleId {
      ...ProductFields
    }
    stockZoneId {
      ...StockZoneFields
    }
  }
  ${ProductFieldsFragmentDoc}
  ${StockZoneFieldsFragmentDoc}
`;
export const StockMoveFieldsFragmentDoc = gql`
  fragment StockMoveFields on MaintenanceStockMove {
    id
    displayName
    quantity
    abortMoveId {
      id
    }
    abortedByMoveIds {
      id
    }
    articleId {
      ...ProductFields
    }
    originId {
      ...StockZoneFields
    }
    destinationId {
      ...StockZoneFields
    }
    processingId {
      id
    }
  }
  ${ProductFieldsFragmentDoc}
  ${StockZoneFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    name
    image128
  }
`;
export const AddPhotoToProcessingDocument = gql`
  mutation AddPhotoToProcessing(
    $processingId: Int!
    $base64image: String!
    $name: String
  ) {
    createMaintenanceImage(
      name: $name
      processingId: $processingId
      image1920: $base64image
    ) {
      created {
        ...ImageFields
      }
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export type AddPhotoToProcessingMutationFn = Apollo.MutationFunction<
  Types.AddPhotoToProcessingMutation,
  Types.AddPhotoToProcessingMutationVariables
>;
export type AddPhotoToProcessingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.AddPhotoToProcessingMutation,
    Types.AddPhotoToProcessingMutationVariables
  >;
} &
  TChildProps;
export function withAddPhotoToProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.AddPhotoToProcessingMutation,
    Types.AddPhotoToProcessingMutationVariables,
    AddPhotoToProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.AddPhotoToProcessingMutation,
    Types.AddPhotoToProcessingMutationVariables,
    AddPhotoToProcessingProps<TChildProps, TDataName>
  >(AddPhotoToProcessingDocument, {
    alias: "addPhotoToProcessing",
    ...operationOptions,
  });
}

/**
 * __useAddPhotoToProcessingMutation__
 *
 * To run a mutation, you first call `useAddPhotoToProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhotoToProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhotoToProcessingMutation, { data, loading, error }] = useAddPhotoToProcessingMutation({
 *   variables: {
 *      processingId: // value for 'processingId'
 *      base64image: // value for 'base64image'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddPhotoToProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddPhotoToProcessingMutation,
    Types.AddPhotoToProcessingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddPhotoToProcessingMutation,
    Types.AddPhotoToProcessingMutationVariables
  >(AddPhotoToProcessingDocument, options);
}
export type AddPhotoToProcessingMutationHookResult = ReturnType<
  typeof useAddPhotoToProcessingMutation
>;
export type AddPhotoToProcessingMutationResult = Apollo.MutationResult<Types.AddPhotoToProcessingMutation>;
export type AddPhotoToProcessingMutationOptions = Apollo.BaseMutationOptions<
  Types.AddPhotoToProcessingMutation,
  Types.AddPhotoToProcessingMutationVariables
>;
export const BeginProcessingDocument = gql`
  mutation BeginProcessing(
    $processingId: Int!
    $interventionReportId: Int!
    $currentDate: String!
  ) {
    updateMaintenanceProcessing(
      id: $processingId
      interventionReportId: $interventionReportId
      startDate: $currentDate
    ) {
      updated {
        id
        state {
          ...Selection
        }
        startDate
      }
    }
  }
  ${SelectionFragmentDoc}
`;
export type BeginProcessingMutationFn = Apollo.MutationFunction<
  Types.BeginProcessingMutation,
  Types.BeginProcessingMutationVariables
>;
export type BeginProcessingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.BeginProcessingMutation,
    Types.BeginProcessingMutationVariables
  >;
} &
  TChildProps;
export function withBeginProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.BeginProcessingMutation,
    Types.BeginProcessingMutationVariables,
    BeginProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.BeginProcessingMutation,
    Types.BeginProcessingMutationVariables,
    BeginProcessingProps<TChildProps, TDataName>
  >(BeginProcessingDocument, {
    alias: "beginProcessing",
    ...operationOptions,
  });
}

/**
 * __useBeginProcessingMutation__
 *
 * To run a mutation, you first call `useBeginProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginProcessingMutation, { data, loading, error }] = useBeginProcessingMutation({
 *   variables: {
 *      processingId: // value for 'processingId'
 *      interventionReportId: // value for 'interventionReportId'
 *      currentDate: // value for 'currentDate'
 *   },
 * });
 */
export function useBeginProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BeginProcessingMutation,
    Types.BeginProcessingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.BeginProcessingMutation,
    Types.BeginProcessingMutationVariables
  >(BeginProcessingDocument, options);
}
export type BeginProcessingMutationHookResult = ReturnType<
  typeof useBeginProcessingMutation
>;
export type BeginProcessingMutationResult = Apollo.MutationResult<Types.BeginProcessingMutation>;
export type BeginProcessingMutationOptions = Apollo.BaseMutationOptions<
  Types.BeginProcessingMutation,
  Types.BeginProcessingMutationVariables
>;
export const CancelStockMoveDocument = gql`
  mutation CancelStockMove(
    $id: Int!
    $articleId: Int!
    $originId: Int!
    $destinationId: Int!
    $quantity: Int!
    $processingId: Int!
  ) {
    createMaintenanceStockMove(
      articleId: $articleId
      originId: $destinationId
      destinationId: $originId
      quantity: $quantity
      processingId: $processingId
      abortMoveId: $id
    ) {
      created {
        ...StockMoveFields
      }
    }
  }
  ${StockMoveFieldsFragmentDoc}
`;
export type CancelStockMoveMutationFn = Apollo.MutationFunction<
  Types.CancelStockMoveMutation,
  Types.CancelStockMoveMutationVariables
>;
export type CancelStockMoveProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CancelStockMoveMutation,
    Types.CancelStockMoveMutationVariables
  >;
} &
  TChildProps;
export function withCancelStockMove<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CancelStockMoveMutation,
    Types.CancelStockMoveMutationVariables,
    CancelStockMoveProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CancelStockMoveMutation,
    Types.CancelStockMoveMutationVariables,
    CancelStockMoveProps<TChildProps, TDataName>
  >(CancelStockMoveDocument, {
    alias: "cancelStockMove",
    ...operationOptions,
  });
}

/**
 * __useCancelStockMoveMutation__
 *
 * To run a mutation, you first call `useCancelStockMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStockMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStockMoveMutation, { data, loading, error }] = useCancelStockMoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      articleId: // value for 'articleId'
 *      originId: // value for 'originId'
 *      destinationId: // value for 'destinationId'
 *      quantity: // value for 'quantity'
 *      processingId: // value for 'processingId'
 *   },
 * });
 */
export function useCancelStockMoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CancelStockMoveMutation,
    Types.CancelStockMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CancelStockMoveMutation,
    Types.CancelStockMoveMutationVariables
  >(CancelStockMoveDocument, options);
}
export type CancelStockMoveMutationHookResult = ReturnType<
  typeof useCancelStockMoveMutation
>;
export type CancelStockMoveMutationResult = Apollo.MutationResult<Types.CancelStockMoveMutation>;
export type CancelStockMoveMutationOptions = Apollo.BaseMutationOptions<
  Types.CancelStockMoveMutation,
  Types.CancelStockMoveMutationVariables
>;
export const CloseInterventionReportDocument = gql`
  mutation CloseInterventionReport(
    $reportId: Int!
    $description: String
    $comment: String
    $signature: String
    $currentDate: String
    $onContract: Boolean
    $nbNights: Int
    $nbMeals: Int
    $journeyTime: Float
    $productionStop: Boolean
    $multipleTechs: Boolean
    $multipleTechsComment: String
    $forceCloseIntervention: Boolean
  ) {
    updateMaintenanceInterventionReport(
      id: $reportId
      description: $description
      comment: $comment
      signature: $signature
      endDate: $currentDate
      onContract: $onContract
      nbNights: $nbNights
      nbMeals: $nbMeals
      journeyTime: $journeyTime
      productionStop: $productionStop
      multipleTechs: $multipleTechs
      multipleTechsComment: $multipleTechsComment
      forceCloseIntervention: $forceCloseIntervention
    ) {
      updated {
        id
        name
        description
        comment
        signature
        endDate
        interventionId {
          id
          endDate
          state {
            ...Selection
          }
          currentReportId {
            ...InterventionReportFields
          }
        }
        onContract
        nbNights
        nbMeals
        journeyTime
        productionStop
        multipleTechs
        multipleTechsComment
        forceCloseIntervention
      }
    }
  }
  ${SelectionFragmentDoc}
  ${InterventionReportFieldsFragmentDoc}
`;
export type CloseInterventionReportMutationFn = Apollo.MutationFunction<
  Types.CloseInterventionReportMutation,
  Types.CloseInterventionReportMutationVariables
>;
export type CloseInterventionReportProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CloseInterventionReportMutation,
    Types.CloseInterventionReportMutationVariables
  >;
} &
  TChildProps;
export function withCloseInterventionReport<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CloseInterventionReportMutation,
    Types.CloseInterventionReportMutationVariables,
    CloseInterventionReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CloseInterventionReportMutation,
    Types.CloseInterventionReportMutationVariables,
    CloseInterventionReportProps<TChildProps, TDataName>
  >(CloseInterventionReportDocument, {
    alias: "closeInterventionReport",
    ...operationOptions,
  });
}

/**
 * __useCloseInterventionReportMutation__
 *
 * To run a mutation, you first call `useCloseInterventionReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseInterventionReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeInterventionReportMutation, { data, loading, error }] = useCloseInterventionReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      description: // value for 'description'
 *      comment: // value for 'comment'
 *      signature: // value for 'signature'
 *      currentDate: // value for 'currentDate'
 *      onContract: // value for 'onContract'
 *      nbNights: // value for 'nbNights'
 *      nbMeals: // value for 'nbMeals'
 *      journeyTime: // value for 'journeyTime'
 *      productionStop: // value for 'productionStop'
 *      multipleTechs: // value for 'multipleTechs'
 *      multipleTechsComment: // value for 'multipleTechsComment'
 *      forceCloseIntervention: // value for 'forceCloseIntervention'
 *   },
 * });
 */
export function useCloseInterventionReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CloseInterventionReportMutation,
    Types.CloseInterventionReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CloseInterventionReportMutation,
    Types.CloseInterventionReportMutationVariables
  >(CloseInterventionReportDocument, options);
}
export type CloseInterventionReportMutationHookResult = ReturnType<
  typeof useCloseInterventionReportMutation
>;
export type CloseInterventionReportMutationResult = Apollo.MutationResult<Types.CloseInterventionReportMutation>;
export type CloseInterventionReportMutationOptions = Apollo.BaseMutationOptions<
  Types.CloseInterventionReportMutation,
  Types.CloseInterventionReportMutationVariables
>;
export const CloseProcessingDocument = gql`
  mutation CloseProcessing(
    $id: Int!
    $currentDate: String!
    $comment: String
    $installationOk: Boolean
    $materialKeepedStopped: Boolean
    $result: String
  ) {
    updateMaintenanceProcessing(
      id: $id
      endDate: $currentDate
      comment: $comment
      installationOk: $installationOk
      materialKeepedStopped: $materialKeepedStopped
      result: $result
    ) {
      updated {
        ...ProcessingFields
      }
    }
  }
  ${ProcessingFieldsFragmentDoc}
`;
export type CloseProcessingMutationFn = Apollo.MutationFunction<
  Types.CloseProcessingMutation,
  Types.CloseProcessingMutationVariables
>;
export type CloseProcessingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CloseProcessingMutation,
    Types.CloseProcessingMutationVariables
  >;
} &
  TChildProps;
export function withCloseProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CloseProcessingMutation,
    Types.CloseProcessingMutationVariables,
    CloseProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CloseProcessingMutation,
    Types.CloseProcessingMutationVariables,
    CloseProcessingProps<TChildProps, TDataName>
  >(CloseProcessingDocument, {
    alias: "closeProcessing",
    ...operationOptions,
  });
}

/**
 * __useCloseProcessingMutation__
 *
 * To run a mutation, you first call `useCloseProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeProcessingMutation, { data, loading, error }] = useCloseProcessingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentDate: // value for 'currentDate'
 *      comment: // value for 'comment'
 *      installationOk: // value for 'installationOk'
 *      materialKeepedStopped: // value for 'materialKeepedStopped'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useCloseProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CloseProcessingMutation,
    Types.CloseProcessingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CloseProcessingMutation,
    Types.CloseProcessingMutationVariables
  >(CloseProcessingDocument, options);
}
export type CloseProcessingMutationHookResult = ReturnType<
  typeof useCloseProcessingMutation
>;
export type CloseProcessingMutationResult = Apollo.MutationResult<Types.CloseProcessingMutation>;
export type CloseProcessingMutationOptions = Apollo.BaseMutationOptions<
  Types.CloseProcessingMutation,
  Types.CloseProcessingMutationVariables
>;
export const CreateMaintenanceInterventionDocument = gql`
  mutation CreateMaintenanceIntervention(
    $materialId: Int
    $clientId: Int!
    $siteId: Int!
    $zoneId: Int
    $techId: Int!
    $interventionDate: String!
    $origin: String
    $name: String
  ) {
    createMaintenanceIntervention(
      materialId: $materialId
      clientId: $clientId
      siteId: $siteId
      zoneId: $zoneId
      techId: $techId
      interventionDate: $interventionDate
      origin: $origin
      name: $name
    ) {
      created {
        ...InterventionFieldsFull
      }
    }
  }
  ${InterventionFieldsFullFragmentDoc}
`;
export type CreateMaintenanceInterventionMutationFn = Apollo.MutationFunction<
  Types.CreateMaintenanceInterventionMutation,
  Types.CreateMaintenanceInterventionMutationVariables
>;
export type CreateMaintenanceInterventionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateMaintenanceInterventionMutation,
    Types.CreateMaintenanceInterventionMutationVariables
  >;
} &
  TChildProps;
export function withCreateMaintenanceIntervention<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateMaintenanceInterventionMutation,
    Types.CreateMaintenanceInterventionMutationVariables,
    CreateMaintenanceInterventionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateMaintenanceInterventionMutation,
    Types.CreateMaintenanceInterventionMutationVariables,
    CreateMaintenanceInterventionProps<TChildProps, TDataName>
  >(CreateMaintenanceInterventionDocument, {
    alias: "createMaintenanceIntervention",
    ...operationOptions,
  });
}

/**
 * __useCreateMaintenanceInterventionMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceInterventionMutation, { data, loading, error }] = useCreateMaintenanceInterventionMutation({
 *   variables: {
 *      materialId: // value for 'materialId'
 *      clientId: // value for 'clientId'
 *      siteId: // value for 'siteId'
 *      zoneId: // value for 'zoneId'
 *      techId: // value for 'techId'
 *      interventionDate: // value for 'interventionDate'
 *      origin: // value for 'origin'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateMaintenanceInterventionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMaintenanceInterventionMutation,
    Types.CreateMaintenanceInterventionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateMaintenanceInterventionMutation,
    Types.CreateMaintenanceInterventionMutationVariables
  >(CreateMaintenanceInterventionDocument, options);
}
export type CreateMaintenanceInterventionMutationHookResult = ReturnType<
  typeof useCreateMaintenanceInterventionMutation
>;
export type CreateMaintenanceInterventionMutationResult = Apollo.MutationResult<Types.CreateMaintenanceInterventionMutation>;
export type CreateMaintenanceInterventionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMaintenanceInterventionMutation,
  Types.CreateMaintenanceInterventionMutationVariables
>;
export const CreateMaintenanceProcessingDocument = gql`
  mutation CreateMaintenanceProcessing(
    $interventionId: Int!
    $materialId: Int
  ) {
    createMaintenanceProcessing(
      interventionId: $interventionId
      materialId: $materialId
    ) {
      created {
        ...ProcessingFields
      }
    }
  }
  ${ProcessingFieldsFragmentDoc}
`;
export type CreateMaintenanceProcessingMutationFn = Apollo.MutationFunction<
  Types.CreateMaintenanceProcessingMutation,
  Types.CreateMaintenanceProcessingMutationVariables
>;
export type CreateMaintenanceProcessingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateMaintenanceProcessingMutation,
    Types.CreateMaintenanceProcessingMutationVariables
  >;
} &
  TChildProps;
export function withCreateMaintenanceProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateMaintenanceProcessingMutation,
    Types.CreateMaintenanceProcessingMutationVariables,
    CreateMaintenanceProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateMaintenanceProcessingMutation,
    Types.CreateMaintenanceProcessingMutationVariables,
    CreateMaintenanceProcessingProps<TChildProps, TDataName>
  >(CreateMaintenanceProcessingDocument, {
    alias: "createMaintenanceProcessing",
    ...operationOptions,
  });
}

/**
 * __useCreateMaintenanceProcessingMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceProcessingMutation, { data, loading, error }] = useCreateMaintenanceProcessingMutation({
 *   variables: {
 *      interventionId: // value for 'interventionId'
 *      materialId: // value for 'materialId'
 *   },
 * });
 */
export function useCreateMaintenanceProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMaintenanceProcessingMutation,
    Types.CreateMaintenanceProcessingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateMaintenanceProcessingMutation,
    Types.CreateMaintenanceProcessingMutationVariables
  >(CreateMaintenanceProcessingDocument, options);
}
export type CreateMaintenanceProcessingMutationHookResult = ReturnType<
  typeof useCreateMaintenanceProcessingMutation
>;
export type CreateMaintenanceProcessingMutationResult = Apollo.MutationResult<Types.CreateMaintenanceProcessingMutation>;
export type CreateMaintenanceProcessingMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMaintenanceProcessingMutation,
  Types.CreateMaintenanceProcessingMutationVariables
>;
export const CreateOperationDocument = gql`
  mutation CreateOperation(
    $comment: String
    $date: String
    $description: String
    $processingId: Int!
    $name: String!
    $techId: Int!
  ) {
    createMaintenanceOperation(
      comment: $comment
      date: $date
      description: $description
      processingId: $processingId
      name: $name
      techId: $techId
    ) {
      created {
        ...OperationFields
      }
    }
  }
  ${OperationFieldsFragmentDoc}
`;
export type CreateOperationMutationFn = Apollo.MutationFunction<
  Types.CreateOperationMutation,
  Types.CreateOperationMutationVariables
>;
export type CreateOperationProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateOperationMutation,
    Types.CreateOperationMutationVariables
  >;
} &
  TChildProps;
export function withCreateOperation<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateOperationMutation,
    Types.CreateOperationMutationVariables,
    CreateOperationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateOperationMutation,
    Types.CreateOperationMutationVariables,
    CreateOperationProps<TChildProps, TDataName>
  >(CreateOperationDocument, {
    alias: "createOperation",
    ...operationOptions,
  });
}

/**
 * __useCreateOperationMutation__
 *
 * To run a mutation, you first call `useCreateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationMutation, { data, loading, error }] = useCreateOperationMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *      processingId: // value for 'processingId'
 *      name: // value for 'name'
 *      techId: // value for 'techId'
 *   },
 * });
 */
export function useCreateOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateOperationMutation,
    Types.CreateOperationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateOperationMutation,
    Types.CreateOperationMutationVariables
  >(CreateOperationDocument, options);
}
export type CreateOperationMutationHookResult = ReturnType<
  typeof useCreateOperationMutation
>;
export type CreateOperationMutationResult = Apollo.MutationResult<Types.CreateOperationMutation>;
export type CreateOperationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateOperationMutation,
  Types.CreateOperationMutationVariables
>;
export const CreateStockMoveDocument = gql`
  mutation CreateStockMove(
    $articleId: Int!
    $originId: Int!
    $destinationId: Int!
    $quantity: Int!
    $processingId: Int
  ) {
    createMaintenanceStockMove(
      articleId: $articleId
      originId: $originId
      destinationId: $destinationId
      quantity: $quantity
      processingId: $processingId
    ) {
      created {
        ...StockMoveFields
      }
    }
  }
  ${StockMoveFieldsFragmentDoc}
`;
export type CreateStockMoveMutationFn = Apollo.MutationFunction<
  Types.CreateStockMoveMutation,
  Types.CreateStockMoveMutationVariables
>;
export type CreateStockMoveProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.CreateStockMoveMutation,
    Types.CreateStockMoveMutationVariables
  >;
} &
  TChildProps;
export function withCreateStockMove<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CreateStockMoveMutation,
    Types.CreateStockMoveMutationVariables,
    CreateStockMoveProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.CreateStockMoveMutation,
    Types.CreateStockMoveMutationVariables,
    CreateStockMoveProps<TChildProps, TDataName>
  >(CreateStockMoveDocument, {
    alias: "createStockMove",
    ...operationOptions,
  });
}

/**
 * __useCreateStockMoveMutation__
 *
 * To run a mutation, you first call `useCreateStockMoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMoveMutation, { data, loading, error }] = useCreateStockMoveMutation({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      originId: // value for 'originId'
 *      destinationId: // value for 'destinationId'
 *      quantity: // value for 'quantity'
 *      processingId: // value for 'processingId'
 *   },
 * });
 */
export function useCreateStockMoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateStockMoveMutation,
    Types.CreateStockMoveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateStockMoveMutation,
    Types.CreateStockMoveMutationVariables
  >(CreateStockMoveDocument, options);
}
export type CreateStockMoveMutationHookResult = ReturnType<
  typeof useCreateStockMoveMutation
>;
export type CreateStockMoveMutationResult = Apollo.MutationResult<Types.CreateStockMoveMutation>;
export type CreateStockMoveMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateStockMoveMutation,
  Types.CreateStockMoveMutationVariables
>;
export const DeleteMaintenanceInterventionDocument = gql`
  mutation DeleteMaintenanceIntervention($id: Int!) {
    deleteMaintenanceIntervention(id: $id) {
      ok
    }
  }
`;
export type DeleteMaintenanceInterventionMutationFn = Apollo.MutationFunction<
  Types.DeleteMaintenanceInterventionMutation,
  Types.DeleteMaintenanceInterventionMutationVariables
>;
export type DeleteMaintenanceInterventionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.DeleteMaintenanceInterventionMutation,
    Types.DeleteMaintenanceInterventionMutationVariables
  >;
} &
  TChildProps;
export function withDeleteMaintenanceIntervention<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.DeleteMaintenanceInterventionMutation,
    Types.DeleteMaintenanceInterventionMutationVariables,
    DeleteMaintenanceInterventionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.DeleteMaintenanceInterventionMutation,
    Types.DeleteMaintenanceInterventionMutationVariables,
    DeleteMaintenanceInterventionProps<TChildProps, TDataName>
  >(DeleteMaintenanceInterventionDocument, {
    alias: "deleteMaintenanceIntervention",
    ...operationOptions,
  });
}

/**
 * __useDeleteMaintenanceInterventionMutation__
 *
 * To run a mutation, you first call `useDeleteMaintenanceInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaintenanceInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaintenanceInterventionMutation, { data, loading, error }] = useDeleteMaintenanceInterventionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMaintenanceInterventionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteMaintenanceInterventionMutation,
    Types.DeleteMaintenanceInterventionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteMaintenanceInterventionMutation,
    Types.DeleteMaintenanceInterventionMutationVariables
  >(DeleteMaintenanceInterventionDocument, options);
}
export type DeleteMaintenanceInterventionMutationHookResult = ReturnType<
  typeof useDeleteMaintenanceInterventionMutation
>;
export type DeleteMaintenanceInterventionMutationResult = Apollo.MutationResult<Types.DeleteMaintenanceInterventionMutation>;
export type DeleteMaintenanceInterventionMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteMaintenanceInterventionMutation,
  Types.DeleteMaintenanceInterventionMutationVariables
>;
export const RestartInterventionDocument = gql`
  mutation RestartIntervention($id: Int!, $techId: Int!, $startDate: String) {
    createMaintenanceInterventionReport(
      interventionId: $id
      techId: $techId
      startDate: $startDate
    ) {
      created {
        ...InterventionReportFields
      }
    }
  }
  ${InterventionReportFieldsFragmentDoc}
`;
export type RestartInterventionMutationFn = Apollo.MutationFunction<
  Types.RestartInterventionMutation,
  Types.RestartInterventionMutationVariables
>;
export type RestartInterventionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.RestartInterventionMutation,
    Types.RestartInterventionMutationVariables
  >;
} &
  TChildProps;
export function withRestartIntervention<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.RestartInterventionMutation,
    Types.RestartInterventionMutationVariables,
    RestartInterventionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.RestartInterventionMutation,
    Types.RestartInterventionMutationVariables,
    RestartInterventionProps<TChildProps, TDataName>
  >(RestartInterventionDocument, {
    alias: "restartIntervention",
    ...operationOptions,
  });
}

/**
 * __useRestartInterventionMutation__
 *
 * To run a mutation, you first call `useRestartInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartInterventionMutation, { data, loading, error }] = useRestartInterventionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      techId: // value for 'techId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useRestartInterventionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RestartInterventionMutation,
    Types.RestartInterventionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RestartInterventionMutation,
    Types.RestartInterventionMutationVariables
  >(RestartInterventionDocument, options);
}
export type RestartInterventionMutationHookResult = ReturnType<
  typeof useRestartInterventionMutation
>;
export type RestartInterventionMutationResult = Apollo.MutationResult<Types.RestartInterventionMutation>;
export type RestartInterventionMutationOptions = Apollo.BaseMutationOptions<
  Types.RestartInterventionMutation,
  Types.RestartInterventionMutationVariables
>;
export const StartInterventionDocument = gql`
  mutation StartIntervention($id: Int!, $techId: Int!, $startDate: String) {
    updateMaintenanceIntervention(id: $id, startDate: $startDate) {
      updated {
        id
        startDate
        state {
          ...Selection
        }
      }
    }
    createMaintenanceInterventionReport(
      interventionId: $id
      techId: $techId
      startDate: $startDate
    ) {
      created {
        ...InterventionReportFields
      }
    }
  }
  ${SelectionFragmentDoc}
  ${InterventionReportFieldsFragmentDoc}
`;
export type StartInterventionMutationFn = Apollo.MutationFunction<
  Types.StartInterventionMutation,
  Types.StartInterventionMutationVariables
>;
export type StartInterventionProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.StartInterventionMutation,
    Types.StartInterventionMutationVariables
  >;
} &
  TChildProps;
export function withStartIntervention<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.StartInterventionMutation,
    Types.StartInterventionMutationVariables,
    StartInterventionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.StartInterventionMutation,
    Types.StartInterventionMutationVariables,
    StartInterventionProps<TChildProps, TDataName>
  >(StartInterventionDocument, {
    alias: "startIntervention",
    ...operationOptions,
  });
}

/**
 * __useStartInterventionMutation__
 *
 * To run a mutation, you first call `useStartInterventionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartInterventionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startInterventionMutation, { data, loading, error }] = useStartInterventionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      techId: // value for 'techId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useStartInterventionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StartInterventionMutation,
    Types.StartInterventionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StartInterventionMutation,
    Types.StartInterventionMutationVariables
  >(StartInterventionDocument, options);
}
export type StartInterventionMutationHookResult = ReturnType<
  typeof useStartInterventionMutation
>;
export type StartInterventionMutationResult = Apollo.MutationResult<Types.StartInterventionMutation>;
export type StartInterventionMutationOptions = Apollo.BaseMutationOptions<
  Types.StartInterventionMutation,
  Types.StartInterventionMutationVariables
>;
export const UpdateInterventionReportDocument = gql`
  mutation UpdateInterventionReport(
    $reportId: Int!
    $description: String
    $comment: String
    $signature: String
    $onContract: Boolean
    $nbNights: Int
    $nbMeals: Int
    $journeyTime: Float
    $productionStop: Boolean
    $multipleTechs: Boolean
    $multipleTechsComment: String
  ) {
    updateMaintenanceInterventionReport(
      id: $reportId
      description: $description
      comment: $comment
      signature: $signature
      onContract: $onContract
      nbNights: $nbNights
      nbMeals: $nbMeals
      journeyTime: $journeyTime
      productionStop: $productionStop
      multipleTechs: $multipleTechs
      multipleTechsComment: $multipleTechsComment
    ) {
      updated {
        id
        description
        comment
        signature
        onContract
        nbNights
        nbMeals
        journeyTime
        productionStop
        multipleTechs
        multipleTechsComment
      }
    }
  }
`;
export type UpdateInterventionReportMutationFn = Apollo.MutationFunction<
  Types.UpdateInterventionReportMutation,
  Types.UpdateInterventionReportMutationVariables
>;
export type UpdateInterventionReportProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateInterventionReportMutation,
    Types.UpdateInterventionReportMutationVariables
  >;
} &
  TChildProps;
export function withUpdateInterventionReport<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateInterventionReportMutation,
    Types.UpdateInterventionReportMutationVariables,
    UpdateInterventionReportProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateInterventionReportMutation,
    Types.UpdateInterventionReportMutationVariables,
    UpdateInterventionReportProps<TChildProps, TDataName>
  >(UpdateInterventionReportDocument, {
    alias: "updateInterventionReport",
    ...operationOptions,
  });
}

/**
 * __useUpdateInterventionReportMutation__
 *
 * To run a mutation, you first call `useUpdateInterventionReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterventionReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterventionReportMutation, { data, loading, error }] = useUpdateInterventionReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      description: // value for 'description'
 *      comment: // value for 'comment'
 *      signature: // value for 'signature'
 *      onContract: // value for 'onContract'
 *      nbNights: // value for 'nbNights'
 *      nbMeals: // value for 'nbMeals'
 *      journeyTime: // value for 'journeyTime'
 *      productionStop: // value for 'productionStop'
 *      multipleTechs: // value for 'multipleTechs'
 *      multipleTechsComment: // value for 'multipleTechsComment'
 *   },
 * });
 */
export function useUpdateInterventionReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateInterventionReportMutation,
    Types.UpdateInterventionReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateInterventionReportMutation,
    Types.UpdateInterventionReportMutationVariables
  >(UpdateInterventionReportDocument, options);
}
export type UpdateInterventionReportMutationHookResult = ReturnType<
  typeof useUpdateInterventionReportMutation
>;
export type UpdateInterventionReportMutationResult = Apollo.MutationResult<Types.UpdateInterventionReportMutation>;
export type UpdateInterventionReportMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateInterventionReportMutation,
  Types.UpdateInterventionReportMutationVariables
>;
export const UpdateMaintenanceOperationDocument = gql`
  mutation UpdateMaintenanceOperation(
    $id: Int!
    $name: String
    $done: Boolean
    $description: String
    $comment: String
    $date: String
  ) {
    updateMaintenanceOperation(
      id: $id
      name: $name
      done: $done
      description: $description
      comment: $comment
      date: $date
    ) {
      updated {
        id
        name
        done
        description
        comment
        date
      }
    }
  }
`;
export type UpdateMaintenanceOperationMutationFn = Apollo.MutationFunction<
  Types.UpdateMaintenanceOperationMutation,
  Types.UpdateMaintenanceOperationMutationVariables
>;
export type UpdateMaintenanceOperationProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateMaintenanceOperationMutation,
    Types.UpdateMaintenanceOperationMutationVariables
  >;
} &
  TChildProps;
export function withUpdateMaintenanceOperation<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateMaintenanceOperationMutation,
    Types.UpdateMaintenanceOperationMutationVariables,
    UpdateMaintenanceOperationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateMaintenanceOperationMutation,
    Types.UpdateMaintenanceOperationMutationVariables,
    UpdateMaintenanceOperationProps<TChildProps, TDataName>
  >(UpdateMaintenanceOperationDocument, {
    alias: "updateMaintenanceOperation",
    ...operationOptions,
  });
}

/**
 * __useUpdateMaintenanceOperationMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceOperationMutation, { data, loading, error }] = useUpdateMaintenanceOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      done: // value for 'done'
 *      description: // value for 'description'
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateMaintenanceOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateMaintenanceOperationMutation,
    Types.UpdateMaintenanceOperationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateMaintenanceOperationMutation,
    Types.UpdateMaintenanceOperationMutationVariables
  >(UpdateMaintenanceOperationDocument, options);
}
export type UpdateMaintenanceOperationMutationHookResult = ReturnType<
  typeof useUpdateMaintenanceOperationMutation
>;
export type UpdateMaintenanceOperationMutationResult = Apollo.MutationResult<Types.UpdateMaintenanceOperationMutation>;
export type UpdateMaintenanceOperationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateMaintenanceOperationMutation,
  Types.UpdateMaintenanceOperationMutationVariables
>;
export const UpdateProcessingDocument = gql`
  mutation UpdateProcessing(
    $id: Int!
    $comment: String
    $installationOk: Boolean
    $materialKeepedStopped: Boolean
    $result: String
  ) {
    updateMaintenanceProcessing(
      id: $id
      comment: $comment
      installationOk: $installationOk
      materialKeepedStopped: $materialKeepedStopped
      result: $result
    ) {
      updated {
        ...ProcessingFields
      }
    }
  }
  ${ProcessingFieldsFragmentDoc}
`;
export type UpdateProcessingMutationFn = Apollo.MutationFunction<
  Types.UpdateProcessingMutation,
  Types.UpdateProcessingMutationVariables
>;
export type UpdateProcessingProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateProcessingMutation,
    Types.UpdateProcessingMutationVariables
  >;
} &
  TChildProps;
export function withUpdateProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateProcessingMutation,
    Types.UpdateProcessingMutationVariables,
    UpdateProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateProcessingMutation,
    Types.UpdateProcessingMutationVariables,
    UpdateProcessingProps<TChildProps, TDataName>
  >(UpdateProcessingDocument, {
    alias: "updateProcessing",
    ...operationOptions,
  });
}

/**
 * __useUpdateProcessingMutation__
 *
 * To run a mutation, you first call `useUpdateProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessingMutation, { data, loading, error }] = useUpdateProcessingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *      installationOk: // value for 'installationOk'
 *      materialKeepedStopped: // value for 'materialKeepedStopped'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useUpdateProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateProcessingMutation,
    Types.UpdateProcessingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateProcessingMutation,
    Types.UpdateProcessingMutationVariables
  >(UpdateProcessingDocument, options);
}
export type UpdateProcessingMutationHookResult = ReturnType<
  typeof useUpdateProcessingMutation
>;
export type UpdateProcessingMutationResult = Apollo.MutationResult<Types.UpdateProcessingMutation>;
export type UpdateProcessingMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProcessingMutation,
  Types.UpdateProcessingMutationVariables
>;
