export class HTTPResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super(`HTTP Error Response: ${response.status} ${response.statusText}`);
    this.response = response;
  }
}

export const checkStatus = (response: Response): Response => {
  // response.status >= 200 && response.status < 300
  if (response.ok) {
    return response;
  } else {
    throw new HTTPResponseError(response);
  }
};
