import {
  IonItem,
  IonToggle,
} from '@ionic/react';
import { SyntheticEvent } from 'react';

import { ProcessingFieldsFragment, OperationFieldsFragment } from 'apollo/queries/types';
import { useUpdateMaintenanceOperationMutation } from '../../../apollo/mutations';

interface OperationListProps {
  processing: ProcessingFieldsFragment | null | undefined,
  operation: OperationFieldsFragment,
  validated: boolean
}

const OperationListItem: React.FC<OperationListProps> = ({ processing, operation, validated }) => {
  const [ updateMaintenanceOperation ] = useUpdateMaintenanceOperationMutation();

  const handleToggle = (e: SyntheticEvent) => {
    updateMaintenanceOperation({
      variables:{
        id: operation.id,
        done: !operation.done,
        date: operation.done ? null : new Date().toISOString(),
      }
    });
    e.stopPropagation();
  };

  return (
    <IonItem
      key={operation.id}
      className="intervention-item"
      routerLink={`/processing/${processing?.id}/editOperation/${operation.id}/`}
    >
      {operation.name} - {operation.comment}
      <IonToggle
        slot="end"
        disabled={validated}
        checked={operation.done as boolean}
        onClick={handleToggle}
      />
    </IonItem>
  );
};

export default OperationListItem;
