import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline, constructOutline, timeOutline } from 'ionicons/icons';

const getIcon = (state: string | null | undefined): string => {
  switch (state) {
    case 'planned':
      return timeOutline;
    case 'doing':
      return constructOutline;
    case 'done':
    case 'closed':
      return checkmarkCircleOutline;
    default:
      return timeOutline;
  }
};

const StateIcon = ({ state, style }: { state: string, style: {[key: string]: any} }) => (
  <IonIcon
    color={
      ['planned', 'partially_closed'].includes(state) ?
        'primary' :
        state === 'doing' ?
          'warning' :
          'success'
    }
    style={style}
    icon={getIcon(state)}
  />
);

export default StateIcon;