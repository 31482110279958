import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { computeLocalQueryPolicies } from 'store/store';
import config from 'config/config.json';

const httpLink: ApolloLink = createHttpLink(
  {
    uri: config.ODOO_HOST + config.GRAPHQL_ENDPOINT,
    credentials: config.AUTH_METHOD === 'cookie' ? 'include' : 'omit',
  },
);

// const logContextMiddleware: ApolloLink = new ApolloLink(
//   (operation: Operation, forward: NextLink) => {
//     /* Print the operation's headers */
//     console.log(`[DEBUG] <logMiddleware> context :`, operation.getContext());

//     /* Execute the next link in the chain */
//     return forward(operation);
//   },
// );

const authMiddleware: ApolloLink = setContext(
  (request, previousContext) => {
    // console.log(`[DEBUG] <authMiddleware> Active auth method :`, config.AUTH_METHOD);

    if (config.AUTH_METHOD === 'token') {
      const token: string | null = localStorage.getItem('session_id');

      // console.log(`[DEBUG] <authMiddleware> token :`, token);

      return {
        ...previousContext,
        headers: {
          ...previousContext.headers,
          'X-Openerp-Session-Id': token || null,
        }
      };
    }
  }
);

const errorLink: ApolloLink = onError(
  ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        },
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

export const client = new ApolloClient(
  {
    /**
     * Middlewares first
     * then local links
     * then auth link
     * then error link
     * then terminating link
     */
    link: ApolloLink.from(
      [
        authMiddleware, /* Middlewares first */
        // logContextMiddleware,
        errorLink,
        httpLink, /* Should always be last, it is the terminating link */
      ],
    ),
    cache: new InMemoryCache(
      {
        typePolicies: {
          Query: {
            fields: computeLocalQueryPolicies(),
          },
        },
      },
    ),
  },
);