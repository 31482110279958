import React from 'react';
import {
  IonImg,
  IonText,
  IonCard,
} from '@ionic/react';

import { formatDateForSQL } from 'utils/formatDate';
import compile from 'utils/domain';
import * as Luq from 'luq';

import { useMaterialProcessingHistoryQuery } from 'apollo/queries';

import Spinner from 'components/Spinner';
import Alert from 'components/Alert';

import MaterialProcessingHistoryItem from './MaterialProcessingHistoryItem';

interface MaterialProcessingHistoryViewProps {
  materialID: number;
}

const MaterialProcessingHistoryView: React.FC<MaterialProcessingHistoryViewProps> = ({ materialID }) => {
  const {
    loading: loadingMaterialProcessingHistory,
    error: errorMaterialProcessingHistory,
    data: dataMaterialProcessingHistory,
    refetch: refetchMaterialProcessingHistory,
  } = useMaterialProcessingHistoryQuery(
    {
      variables: {
        filter: compile(
          <Luq.And>
            <Luq.Eq field="materialId" value={materialID} />
            <Luq.Eq field="state" value={'closed'} />
          </Luq.And>
        ),
      },
    }
  );

  if (loadingMaterialProcessingHistory) {
    return (
      <Spinner />
    );
  }

  const materialProcessingHistory = !errorMaterialProcessingHistory ?
    dataMaterialProcessingHistory?.processingHistory
    : null;

  if (!materialProcessingHistory) {
    return (
      <Alert
        message="Il n'y a pas d'historique de traitements à afficher."
        secondaryMessage="Merci de rafraîchir la page."
      />
    );
  } else if (materialProcessingHistory.length < 1) {
    return (
      <div
        style={
          {
            textAlign: 'center',
          }
        }
      >
        <IonImg
          style={
            {
              maxWidth: '25%',
              display: 'inline-block',
            }
          }
          src="/assets/empty-data.svg"
        />
        <h6>
          {`Pas d'historique pour ce matériel`}
        </h6>
      </div>
    );
  }

  return (
    <>
      {
        materialProcessingHistory.map(
          (pProcessing) => {
            return (
              <MaterialProcessingHistoryItem
                key={pProcessing.id}
                processing={pProcessing}
              />
            );
          }
        )
      }
    </>
  );
};

export default MaterialProcessingHistoryView;
