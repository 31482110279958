import React from 'react';

import InterventionReport from 'components/InterventionReport';

import { MutationUpdateMaintenanceInterventionReportArgs } from 'apollo/types';
import { InterventionReportFieldsFragment } from 'apollo/mutations/types';

import './styles.css';
import SectionTitle from 'components/SectionTitle';
import InterventionReportList from 'components/InterventionReportList';
interface InterventionReportViewProps {
  edit: boolean;
  openReport: MutationUpdateMaintenanceInterventionReportArgs|null|undefined;
  setOpenReport?: (newReport: MutationUpdateMaintenanceInterventionReportArgs) => void;
  saveReport?: () => void;
  allReports: InterventionReportFieldsFragment[];
}

const InterventionReportView: React.FC<InterventionReportViewProps> = ({ edit, openReport, setOpenReport, saveReport, allReports }) => {
  const oldReports = allReports.filter(({ state }) => state?.key === 'closed');

  return (
    <>
      {openReport && 
        <>
          {oldReports.length > 0 && <SectionTitle title="Rapport en cours" />}
          <InterventionReport
            edit={true} 
            report={openReport} 
            setReport={report => setOpenReport && setOpenReport(report)}
            saveReport={saveReport}
          />
        </>
      }
      {oldReports.length > 0 && 
        <>
          {openReport && <SectionTitle title="Rapports précédents" />}
          <InterventionReportList
            reports={oldReports}
          />
        </>
      }
    </>
  );
};

export default InterventionReportView;
