import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';
import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';
import Spinner from 'components/Spinner';

import { useZoneQuery } from 'apollo/queries';

import './styles.css';

interface ZonePageProps
  extends RouteComponentProps<{
    zoneId: string;
  }> {}

const ZonePage: React.FC<ZonePageProps> = ({ match }) => {
  const zoneID: number = Number(match.params.zoneId);

  const {
    loading: loadingZone,
    error: errorZone,
    data: dataZone,
    refetch: refetchZone,
  } = useZoneQuery({
    variables: {
      id: zoneID,
    },
  });

  if (loadingZone) {
    return (
      <Spinner />
    );
  }

  const zoneInformation = !errorZone ? dataZone?.getMaintenanceSiteZone : null;

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Zone"
      onRefresh={
        async () => {
          await refetchZone(
            {
              id: zoneID,
            },
          );
        }
      }
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {/* <img
              alt={`Logo de l'entreprise ${zoneInformation?.name}`}
              // src={`data:image/png;base64, ${zoneInformation?.image256}`}
            />
            <br /> */}
            {zoneInformation?.name}
            <br />
            <IonButton disabled>
              {zoneInformation?.clientId?.name}
            </IonButton>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonCardSubtitle>
            Materiels
          </IonCardSubtitle>
          <IonList>
            {
              zoneInformation?.materialIds.map(
                (pMaterial) => {
                  /* pSite is a { key;value } object */
                  return (
                    <IonItem
                      key={pMaterial.id}
                      routerLink={`/material/${pMaterial.id}`}
                    >
                      <IonLabel>
                        {pMaterial.name}
                      </IonLabel>
                      <IonNote slot="end" color="primary">
                        {pMaterial.id}
                      </IonNote>
                    </IonItem>
                  );
                }
              )
            }
          </IonList>
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Client
          </IonCardSubtitle>
          <IonList>
            <IonItem
              routerLink={`/client/${zoneInformation?.clientId?.id}`}
            >
              <IonLabel>
                {zoneInformation?.clientId?.name}
              </IonLabel>
              <IonNote slot="end" color="primary">
                {zoneInformation?.clientId?.id}
              </IonNote>
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
    </PageLayout>
  );
};

export default withAuthentication(ZonePage);
