import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  // IonTabBar,
  // IonTabButton,
  // IonTabs,
} from '@ionic/react';
import { useCurrentUserQuery, useLoggedInQuery } from 'apollo/queries';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/globalStyles.css';

import Menu from 'layouts/Menu';

import LoginPage from './pages/LoginPage';
import InterventionPage from './pages/InterventionPage';
import ProcessingPage from './pages/ProcessingPage';
import InterventionList from './pages/InterventionListPage';
import CreateOperation from 'pages/CreateOperation';
import EditOperation from 'pages/EditOperation';
// import Settings from './pages/Settings';
import ClientPage from 'pages/ClientPage';
import ContactPage from 'pages/ContactPage';
import MaterialPage from 'pages/MaterialPage';
import SitePage from 'pages/SitePage';
import ZonePage from 'pages/ZonePage';

import ScannerPage from 'pages/ScannerPage';

import Spinner from 'components/Spinner';

import setLoggedIn from 'store/loggedIn';
import CreateStockMove from 'pages/CreateStockMove';

const App: React.FC = () => {
  const history = useHistory();

  const { data: loggedInData } = useLoggedInQuery();
  const {
    loading: loadingCurrentUserData,
    error: currentUserDataError,
  } = useCurrentUserQuery({
    skip: !loggedInData?.isLoggedIn
  });

  useEffect(
    () => {
      if (loggedInData?.isLoggedIn && currentUserDataError !== undefined) {
        setLoggedIn(false);
        window.location.reload();
      }
    },
    [
      loggedInData?.isLoggedIn,
      currentUserDataError,
    ],
  );

  useEffect(
    () => {
      const THEME_COLOR_META_TAG_NAME: string = 'theme-color';

      const toggleDarkTheme = (prefersDark: boolean): void => {
        let themeColorMetaTag: Element | null = document.querySelector(`meta[name=${THEME_COLOR_META_TAG_NAME}]`);

        if (!themeColorMetaTag) {
          themeColorMetaTag = document.createElement<'meta'>('meta');
          themeColorMetaTag.setAttribute(
            'name',
            THEME_COLOR_META_TAG_NAME,
          );
          themeColorMetaTag.setAttribute(
            'content',
            '#FFFFFF',
          );
        }

        if (prefersDark) {
          themeColorMetaTag?.setAttribute(
            'content',
            '#000000',
          );
        } else {
          themeColorMetaTag?.setAttribute(
            'content',
            '#FFFFFF',
          );
        }
      };

      const toggleDarkThemeEventHandler = (mediaQueryEvent: MediaQueryListEvent): void => {
        const prefersDark: boolean = mediaQueryEvent.matches;

        toggleDarkTheme(prefersDark);
      };

      const prefersDarkQuery: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

      toggleDarkTheme(prefersDarkQuery.matches);

      prefersDarkQuery.addEventListener(
        'change',
        toggleDarkThemeEventHandler,
      );

      return () => {
        prefersDarkQuery.removeEventListener(
          'change',
          toggleDarkThemeEventHandler,
        );
      };
    },
    [],
  );

  return (
    <IonApp>
      {
        loadingCurrentUserData ?
          <Spinner /> :
          <>
            {
              /* Don't render the menu on the login page */
              loggedInData?.isLoggedIn === true &&
                <Menu afterLogout={() => setLoggedIn(false)} />
            }
            <IonRouterOutlet id="main">
              <Route exact path="/scanner" component={ScannerPage} />
              <Route exact path="/interventions" component={InterventionList} />
              <Route exact path="/intervention/:interventionId" component={InterventionPage} />
              <Route exact path="/client/:clientId" component={ClientPage} />
              <Route exact path="/site/:siteId/" component={SitePage} />
              <Route exact path="/contact/:contactId/" component={ContactPage} />
              <Route exact path="/material/:materialId/" component={MaterialPage} />
              <Route exact path="/zone/:zoneId/" component={ZonePage} />
              <Route exact path="/processing/:processingId" component={ProcessingPage} />
              <Route exact path="/processing/:processingId/old" component={ (props: any) => (<ProcessingPage {...props} isOld={ true } />) } />
              <Route exact path="/processing/:processingId/createOperation" component={CreateOperation} />
              <Route exact path="/processing/:processingId/editOperation/:operationId" component={EditOperation} />
              <Route exact path="/processing/:processingId/createStockMove" component={CreateStockMove} />
              {/* <Route exact path="/settings" render={() => <Settings afterLogout={() => setLoggedIn(false)} />} /> */}
              <Route
                exact
                path="/login"
                render={
                  () => {
                    if (loggedInData?.isLoggedIn === true) {
                      return (
                        <Redirect to="/interventions" />
                      );
                    }

                    return (
                      <LoginPage
                        afterLogin={
                          () => {
                            setLoggedIn(true);
                            history.push('/interventions');
                          }
                        }
                        onFailure={
                          (error) => {
                            console.error(`[ERROR] <LoginPage> login failed :`, error);
                          }
                        }
                      />
                    );
                  }
                }
              />
              <Route exact path="/" render={() => <Redirect to="/interventions" />} />
            </IonRouterOutlet>
          </>
      }
    </IonApp>
  );
};

export default App;
