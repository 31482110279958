import React, { useState } from 'react';

import {
  IonItem,
  IonLabel,
  IonNote,
  IonButton,
  IonIcon,
  IonAvatar,
  IonImg,
} from '@ionic/react';
import {
  businessOutline,
  informationCircleOutline,
  mapOutline,
} from 'ionicons/icons';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';
import InterventionCreationModal from './InterventionCreationModal';
import CodeIonItem from './CodeIonItem';

import * as Luq from 'luq';
import compile from 'utils/domain';
import { useSearchMaterialQuery } from 'apollo/queries';

import type { QuaggaJSResultObject } from '@ericblade/quagga2/type-definitions/quagga';
import type { MaterialFieldsFragment } from 'apollo/queries/types';

interface InterventionCreatorProps {
  latestScanResult: QuaggaJSResultObject;
  onDismissCreate: React.MouseEventHandler<HTMLIonButtonElement>;
}

const InterventionCreator: React.FC<InterventionCreatorProps> = (
  {
    latestScanResult,
    onDismissCreate,
  }
) => {
  const [ modalOpen, setModalOpen ] = useState<boolean>(false);

  const {
    data: materialData,
    loading: isLoadingMaterial,
    error: materialError,
  } = useSearchMaterialQuery(
    {
      variables: {
        filter: compile(
          <Luq.Eq field="barcode" value={latestScanResult.codeResult.code} />
        ),
      },
      skip: latestScanResult.codeResult.code === null,
    },
  );

  const onCreateIntervention: React.MouseEventHandler<HTMLIonButtonElement> = (pEvent) => {
    setModalOpen(true);
  };

  const materialFound: boolean =
    materialData?.materials !== undefined &&
    materialData?.materials.length > 0;

  if (isLoadingMaterial) {
    return (
      <Spinner />
    );
  }

  if (materialError) {
    return (
      <Alert
        message="Impossible de récupérer le matériel associé à ce code"
        secondaryMessage={materialError.message}
      />
    );
  }

  if (!materialFound) {
    return (
      <Alert
        message="Aucun matériel n'est associé à ce code"
      />
    );
  }

  const material: MaterialFieldsFragment = materialData?.materials[0]!;

  return (
    <div>
      <div>
        <CodeIonItem
          codeFormat={latestScanResult.codeResult.format}
          code={latestScanResult.codeResult.code}
        />
        <IonItem>
          <IonAvatar
            slot="start"
            className="list-item-icon"
          >
            <IonIcon
              color="primary"
              icon={informationCircleOutline}
              style={{ width: '100%' }}
              size="large"
            />
          </IonAvatar>
          <IonLabel>
            {`Nom`}
          </IonLabel>
          <IonNote>
            {material?.name}
          </IonNote>
        </IonItem>
        <IonItem>
          <IonAvatar
            slot="start"
            className="list-item-icon"
          >
            {
              !material?.clientId?.image128 ?
                <IonIcon
                  icon={businessOutline}
                  color="primary"
                  style={{ width: '100%' }}
                  size="large"
                /> :
                <IonImg
                  src={`data:image/png;base64,${material?.clientId.image128}`}
                />
            }
          </IonAvatar>
          <IonLabel>
            {`Client`}
          </IonLabel>
          <IonNote slot="end">
            {material?.clientId?.name}
          </IonNote>
        </IonItem>
        <IonItem>
          <IonAvatar
            slot="start"
            className="list-item-icon"
          >
            <IonIcon
              color="primary"
              icon={mapOutline}
              style={{ width: '100%' }}
              size="large"
            />
          </IonAvatar>
          <IonLabel>
            {`Site`}
          </IonLabel>
          <IonNote slot="end">
            {material?.siteId?.name}
          </IonNote>
        </IonItem>
        <IonItem>
          <IonAvatar
            slot="start"
            className="list-item-icon"
          >
            <IonIcon
              color="primary"
              icon={mapOutline}
              style={{ width: '100%' }}
              size="large"
            />
          </IonAvatar>
          <IonLabel>
            {`Zone`}
          </IonLabel>
          <IonNote slot="end">
            {material?.zoneId?.name}
          </IonNote>
        </IonItem>
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '1rem',
              textAlign: 'center'
            }
          }
        >
          {'Voulez vous créer une intervention pour ce matériel ?'}
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }
            }
          >
            <IonButton
              onClick={onCreateIntervention}
              expand="block"
              style={
                {
                  margin: '1rem',
                  flex: 1,
                }
              }
            >
              Oui
            </IonButton>
            <IonButton
              onClick={onDismissCreate}
              expand="block"
              style={
                {
                  margin: '1rem',
                  flex: 1,
                }
              }
            >
              Non
            </IonButton>
          </div>
        </div>
      </div>
      <InterventionCreationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        material={material}
      />
    </div>
  );
};

export default InterventionCreator;
