import React from 'react';
import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
} from '@ionic/react';

import {
  InterventionFieldsFullFragment,
  UserFieldsFragment,
} from 'apollo/queries/types';

import { formatDateStrForMobile } from 'utils/formatDate';

import SectionTitle from 'components/SectionTitle';
import SanitizeHTML from 'components/SanitizeHTML';

import './styles.css';
import {
  informationCircleOutline,
  businessOutline,
  calendarOutline,
  callOutline,
  chatbubbleEllipsesOutline,
  clipboardOutline,
  constructOutline,
  cubeOutline,
  mapOutline,
  newspaperOutline,
  personCircleOutline
} from 'ionicons/icons';

interface InterventionInfoViewProps {
  intervention: InterventionFieldsFullFragment & {
    managerId?: UserFieldsFragment | null,
  } | null | undefined;
}

const InterventionInfoView: React.FC<InterventionInfoViewProps> = ({ intervention }) => {
  // const onClientClick: React.MouseEventHandler<HTMLIonItemElement> = (pEvent) => {
  //   console.log(`[DEBUG] <InterventionInfoView.onClientClick> client ID :`, intervention?.clientId.id);
  // };

  // const onSiteClick: React.MouseEventHandler<HTMLIonItemElement> = (pEvent) => {
  //   console.log(`[DEBUG] <InterventionInfoView.onSiteClick> site ID :`, intervention?.siteId.id);
  // };

  return (
    <>
      <SectionTitle title="Infos Intervention" />
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon">
          <IonIcon
            color="primary"
            icon={informationCircleOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Nom
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.name}
        </IonNote>
      </IonItem>
      <IonItem
        button
        // onClick={onClientClick}
        routerLink={`/client/${intervention?.clientId.id}`}
      >
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          {
            !intervention?.clientId?.image128 ?
              <IonIcon
                icon={businessOutline}
                color="primary"
                style={{ width: '100%' }}
                size="large"
              /> :
              <IonImg
                src={`data:image/png;base64,${intervention?.clientId.image128}`}
              />
          }
        </IonAvatar>
        <IonLabel>
          Client
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.clientId.name}
        </IonNote>
      </IonItem>
      <IonItem
        button
        // onClick={onSiteClick}
        routerLink={`/site/${intervention?.siteId.id}`}
      >
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={mapOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Site
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.siteId.name}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={clipboardOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          État
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.state?.value}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={callOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Date d'appel
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {
            intervention?.orderDate &&
              (
                new Date(
                  formatDateStrForMobile(
                    intervention.orderDate
                  )
                )
              ).toLocaleDateString('fr-FR')
          }
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={calendarOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Prévue le
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {
            intervention?.interventionDate &&
              <>
                {
                  `${
                    (
                      new Date(
                        formatDateStrForMobile(
                          intervention.interventionDate
                        )
                      )
                    ).toLocaleDateString('fr-FR')
                  }`
                }
                <br />
                {
                  `à ${
                    (
                      new Date(
                        formatDateStrForMobile(
                          intervention.interventionDate
                        )
                      )
                    ).toLocaleTimeString('fr-FR')
                  }`
                }
              </>
          }
        </IonNote>
      </IonItem>
      {
        intervention?.startDate &&
          <IonItem>
            <IonAvatar
              slot="start"
              className="list-item-icon"
            >
              <IonIcon
                color="primary"
                icon={calendarOutline}
                style={{ width: '100%' }}
                size="large"
              />
            </IonAvatar>
            <IonLabel>
              Démarrée le
            </IonLabel>
            <IonNote
              slot="end"
              color="primary"
            >

              {
                intervention?.startDate &&
                  <>
                    {
                      `${
                        (
                          new Date(
                            formatDateStrForMobile(
                              intervention.startDate
                            )
                          )
                        ).toLocaleDateString('fr-FR')
                      }`
                    }
                    <br />
                    {
                      `à ${
                        (
                          new Date(
                            formatDateStrForMobile(
                              intervention.startDate
                            )
                          )
                        ).toLocaleTimeString('fr-FR')
                      }`
                    }
                  </>
              }
            </IonNote>
          </IonItem>
      }
      {
        intervention?.endDate &&
          <IonItem>
            <IonAvatar
              slot="start"
              className="list-item-icon"
            >
              <IonIcon
                color="primary"
                icon={calendarOutline}
                style={{ width: '100%' }}
                size="large"
              />
            </IonAvatar>
            <IonLabel>
              Terminée le
            </IonLabel>
            <IonNote
              slot="end"
              color="primary"
            >
              {intervention?.endDate &&
                <>
                  {
                    (
                      new Date(
                        intervention.endDate
                      )
                    ).toLocaleDateString('fr-FR')
                  }
                  <br />
                  {
                    `à ${
                      (
                        new Date(
                          intervention.endDate
                        )
                      ).toLocaleTimeString('fr-FR')
                    }`
                  }
                </>
              }
            </IonNote>
          </IonItem>
      }
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={chatbubbleEllipsesOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Origine de la demande
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.origin}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={constructOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Type d'intervention
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.typeId?.name}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={cubeOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Domaine
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.domainId?.name}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={personCircleOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Nom du gestionnaire
        </IonLabel>
        <IonNote
          slot="end"
          color="primary"
        >
          {intervention?.managerId?.name}
        </IonNote>
      </IonItem>
      <IonItem className="noborder-list-item">
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={newspaperOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Description
        </IonLabel>
      </IonItem>
      <div className="bordered-text-block">
        <IonText>
          {intervention?.description}
        </IonText>
      </div>
      <IonItem className="noborder-list-item">
        <IonAvatar
          slot="start"
          className="list-item-icon"
        >
          <IonIcon
            color="primary"
            icon={chatbubbleEllipsesOutline}
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Commentaires
        </IonLabel>
      </IonItem>
      <SanitizeHTML
        html={intervention?.comment || ''}
        className="bordered-text-block"
      />
    </>
  );
};

export default InterventionInfoView;
