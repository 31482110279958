import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonAlert,
  IonButton,
} from '@ionic/react';
import { useInterventionQuery } from 'apollo/queries';
import PageLayout from 'layouts/PageLayout';
import Spinner from 'components/Spinner';

import { InterventionStateKeysEnum } from 'utils/InterventionState';
import { formatDateForSQL } from 'utils/formatDate';

import InterventionInfoView from './InterventionInfoView';
import InterventionMaterialListView from './InterventionMaterialListView';
import InterventionReportView from './InterventionReportView';

import {
  informationCircleOutline,
  constructOutline,
  documentTextOutline,
  informationCircle as informationCircleFull,
  construct as constructFull,
  documentText as documentTextFull,
  play,
} from 'ionicons/icons';

import Alert from 'components/Alert';
import withAuthentication from 'components/AuthenticatedRoute';

import './styles.css';
import {
  useCloseInterventionReportMutation,
  useStartInterventionMutation,
  useUpdateInterventionReportMutation,
  useRestartInterventionMutation
} from 'apollo/mutations';
import useUserId from 'utils/userId';
import { MutationUpdateMaintenanceInterventionReportArgs } from 'apollo/types';
import useCurrentIntervention from 'hooks/useCurrentIntervention';
import _ from 'lodash';

interface InterventionPageProps
  extends RouteComponentProps<{
    interventionId: string;
  }> {}

type InterventionViewKeys = 'intervention-info' |
  'intervention-materiel' |
  'intervention-rapport';

type InterventionViews = {
  [key in InterventionViewKeys]: () => React.ReactNode;
};

// eslint-disable-next-line init-declarations
let timer: NodeJS.Timeout;

const InterventionPage: React.FC<InterventionPageProps> = ({ match }) => {
  const interventionId = Number(match.params.interventionId);
  const [ interventionViewKey, setInterventionViewKey ] = useState('intervention-info' as InterventionViewKeys);
  const [ alertMessage, setAlertMessage ] = useState('');
  const history = useHistory();

  const { loading, error, data, refetch } = useInterventionQuery(
    {
      variables: {
        id: interventionId,
      },
    }
  );
  const intervention =
    !loading && !error ? data?.getMaintenanceIntervention : null;
  const processings =
    !loading && !error ? intervention?.processingIds : null;

  const currentInterventionId = useCurrentIntervention();
  const [ startIntervention ] = useStartInterventionMutation(
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(
            {
              __typename: 'MaintenanceIntervention',
              id: interventionId,
            }
          ),
          fields: {
            interventionReportIds(oldReports = []) {
              return [
                ...oldReports,
                {
                  __ref: cache.identify(
                    {
                      __typename: 'MaintenanceInterventionReport',
                      id: data?.createMaintenanceInterventionReport?.created?.id,
                    }
                  ),
                },
              ];
            },
            currentReportId() {
              return {
                __ref: cache.identify({
                  __typename: 'MaintenanceInterventionReport',
                  id: data?.createMaintenanceInterventionReport?.created?.id,
                })
              };
            }
          },
        });
      },
    }
  );
  const [ restartIntervention ] = useRestartInterventionMutation(
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(
            {
              __typename: 'MaintenanceIntervention',
              id: interventionId,
            }
          ),
          fields: {
            state() {
              return {
                __typename: 'Selection',
                key: 'doing',
                value: 'En cours',
              };
            },
            interventionReportIds(oldReports = []) {
              return [
                ...oldReports,
                {
                  __ref: cache.identify(
                    {
                      __typename: 'MaintenanceInterventionReport',
                      id: data?.createMaintenanceInterventionReport?.created?.id,
                    }
                  ),
                },
              ];
            },
            currentReportId() {
              return {
                __ref: cache.identify(
                  {
                    __typename: 'MaintenanceInterventionReport',
                    id: data?.createMaintenanceInterventionReport?.created?.id,
                  }
                )
              };
            }
          },
        });
      },
    }
  );
  const [ updateInterventionReport, { loading: loadingUpdateReport } ] = useUpdateInterventionReportMutation({
    fetchPolicy: 'no-cache',
  });
  const [ closeInterventionReport, { loading: loadingCloseReport } ] = useCloseInterventionReportMutation(
    {
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(
            {
              __typename: 'MaintenanceIntervention',
              id: interventionId,
            }
          ),
          fields: {
            state() {
              return _.cloneDeep(data?.updateMaintenanceInterventionReport?.updated?.interventionId?.state);
            },
            endDate() {
              return data?.updateMaintenanceInterventionReport?.updated?.interventionId?.endDate;
            },
            currentReportId() {
              return {
                __ref: cache.identify(
                  {
                    __typename: 'MaintenanceInterventionReport',
                    id: data?.updateMaintenanceInterventionReport?.updated?.interventionId?.currentReportId?.id
                  }
                ),
              };
            },
          },
        });
      },
    }
  );
  const userId = useUserId();

  const [localReport, setLocalReport] = useState<MutationUpdateMaintenanceInterventionReportArgs>({
    id: intervention?.currentReportId?.id || 0,
    description: intervention?.currentReportId?.description || '',
    comment: intervention?.currentReportId?.comment || '',
    signature: intervention?.currentReportId?.signature || '',
    onContract: intervention?.currentReportId?.onContract || false,
    nbMeals: intervention?.currentReportId?.nbMeals || null,
    nbNights: intervention?.currentReportId?.nbNights || null,
    journeyTime: intervention?.currentReportId?.journeyTime || 0,
    productionStop: intervention?.currentReportId?.productionStop || false,
    multipleTechs: intervention?.currentReportId?.multipleTechs || false,
    multipleTechsComment: intervention?.currentReportId?.multipleTechsComment || '',
  });

  const isReportValid = localReport.description !== '' && localReport.signature !== '';

  useEffect(() => {
    setLocalReport({
      id: intervention?.currentReportId?.id || 0,
      description: intervention?.currentReportId?.description || '',
      comment: intervention?.currentReportId?.comment || '',
      signature: intervention?.currentReportId?.signature || '',
      onContract: intervention?.currentReportId?.onContract || false,
      nbMeals: intervention?.currentReportId?.nbMeals || null,
      nbNights: intervention?.currentReportId?.nbNights || null,
      journeyTime: intervention?.currentReportId?.journeyTime || 0,
      productionStop: intervention?.currentReportId?.productionStop || false,
      forceCloseIntervention: intervention?.currentReportId?.forceCloseIntervention || false,
    });
  }, [intervention, setLocalReport]);

  const onSegmentChanged = (pEvent: CustomEvent): void => {
    setInterventionViewKey(pEvent.detail.value as InterventionViewKeys);
  };

  let interventionStateKey: InterventionStateKeysEnum = InterventionStateKeysEnum.unknown;
  switch (intervention?.state?.key) {
    case InterventionStateKeysEnum.unplanned:
    case InterventionStateKeysEnum.planned:
    case InterventionStateKeysEnum.doing:
    case InterventionStateKeysEnum.done:
    case InterventionStateKeysEnum.closed:
    case InterventionStateKeysEnum.partially_closed:
      interventionStateKey = intervention?.state?.key;
      break;
    /* default : stays at initial value : InterventionStateKeysEnum.unknown */
  }

  const onClickInterventionButton: React.MouseEventHandler<HTMLIonButtonElement> = async (): Promise<void> => {
    if (!intervention) {
      console.warn(`[WARN ] <InterventionPage.onClickInterventionButton> intervention is undefined or null`);
      return;
    }

    if (currentInterventionId) {
      setAlertMessage('Une autre intervention est en cours. Si vous voulez en démarrer une autre, vous devez finaliser la précédente.');
      return;
    }

    /* Change the state of the intervention to "start" it */
    switch (interventionStateKey) {
      case InterventionStateKeysEnum.unplanned:
      case InterventionStateKeysEnum.planned:
        /* Start the intervention */
        try {
          if (userId) {
            await startIntervention(
              {
                variables: {
                  id: intervention.id,
                  techId: userId,
                  startDate: formatDateForSQL(new Date()),
                },
                refetchQueries: ['SearchInterventionsLight', 'SearchInterventionsFull'],
              }
            );
          } else {
            throw new Error('Hook useUserId returned undefined.');
          }
        } catch (pException) {
          console.error(`[ERROR] <InterventionPage.onClickInterventionButton> startIntervention failed :`, pException);
        }
        break;
      case InterventionStateKeysEnum.doing:
        /* Take a picture */
        break;
      case InterventionStateKeysEnum.done:
        /* Close the intervention */
        break;
      case InterventionStateKeysEnum.closed:
      case InterventionStateKeysEnum.partially_closed:
        try {
          if (userId) {
            await restartIntervention(
              {
                variables: {
                  id: intervention.id,
                  techId: userId,
                  startDate: formatDateForSQL(new Date()),
                },
                refetchQueries: ['SearchInterventionsLight', 'SearchInterventionsFull'],
              }
            );
          } else {
            throw new Error('Hook useUserId returned undefined.');
          }
        } catch (pException) {
          console.error(`[ERROR] <InterventionPage.onClickInterventionButton> restartIntervention failed :`, pException);
        }
        break;
      default:
        console.error(`[ERROR] <InterventionPage.onClickInterventionButton> Unknown current state : ${interventionStateKey}`);
        throw new Error(`[ERROR] <InterventionPage.onClickInterventionButton> Unknown current state : ${interventionStateKey}`);
    }
  };

  const onSaveReport = () => {
    clearTimeout(timer);
    if (localReport) {
      timer = setTimeout(() => {
        updateInterventionReport({
          variables: {
            reportId: localReport.id,
            description: localReport.description,
            comment: localReport.comment,
            signature: localReport.signature,
            onContract: localReport.onContract,
            nbNights: localReport.nbNights || 0,
            nbMeals: localReport.nbMeals || 0,
            journeyTime: localReport.journeyTime,
            productionStop: localReport.productionStop,
          },
        });
      }, 1000);
    }
  };

  const onValidateReport = () => {
    clearTimeout(timer);
    if (localReport) {
      closeInterventionReport({
        variables: {
          reportId: localReport.id,
          description: localReport.description,
          comment: localReport.comment,
          signature: localReport.signature,
          currentDate: formatDateForSQL(new Date()),
          onContract: localReport.onContract,
          nbNights: localReport.nbNights || 0,
          nbMeals: localReport.nbMeals || 0,
          journeyTime: localReport.journeyTime,
          productionStop: localReport.productionStop,
          multipleTechs: localReport.multipleTechs,
          multipleTechsComment: localReport.multipleTechsComment,
          forceCloseIntervention: localReport.forceCloseIntervention || false,
        },
        refetchQueries: ['SearchInterventionsLight', 'SearchInterventionsFull']
      }).then(() => {
        history.push('/');
      });
    }
  };

  const interventionViews: InterventionViews = {
    'intervention-info': () => {
      return (
        <InterventionInfoView
          intervention={intervention}
        />
      );
    },
    'intervention-materiel': () => {
      return (
        <InterventionMaterialListView
          processingIds={processings || []}
        />
      );
    },
    'intervention-rapport': () => {
      if (!intervention || !intervention.currentReportId) {
        return (
          <InterventionReportView
            edit={false}
            openReport={null}
            allReports={intervention?.interventionReportIds || []}
          />
        );
      } else {
        return (
          <InterventionReportView
            edit={intervention?.state?.key === InterventionStateKeysEnum.doing}
            openReport={localReport}
            setOpenReport={setLocalReport}
            saveReport={onSaveReport}
            allReports={intervention.interventionReportIds || []}
          />
        );
      }
    },
  };

  const interventionFooters: InterventionViews = {
    'intervention-info': () => {
      if ([
        InterventionStateKeysEnum.planned,
        InterventionStateKeysEnum.partially_closed
      ].includes(intervention?.state?.key as InterventionStateKeysEnum)) {
        return (
          <IonButton
            expand="full"
            color="success"
            onClick={onClickInterventionButton}
          >
            <IonIcon icon={play} />
            {
              interventionStateKey === InterventionStateKeysEnum.planned ?
                'Démarrer' :
                'Reprendre'
            } l'intervention
          </IonButton>
        );
      }
    },
    'intervention-materiel': () => {
      return null;
    },
    'intervention-rapport': () => {
      if (intervention?.state?.key === InterventionStateKeysEnum.doing) {
        return <IonButton
          expand="full"
          disabled={!isReportValid}
          onClick={onValidateReport}
        >
          Valider
        </IonButton>;
      }
    },
  };

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Intervention"
      footer={
        [
          InterventionStateKeysEnum.planned,
          InterventionStateKeysEnum.partially_closed
        ].includes(intervention?.state?.key as InterventionStateKeysEnum) ?
          interventionFooters['intervention-info']() :
          interventionFooters[interventionViewKey]()
      }
      onRefresh={
        async () => {
          await refetch(
            {
              id: intervention?.id,
            },
          );
        }
      }
    >
      <IonAlert
        isOpen={alertMessage !== ''}
        onDidDismiss={() => setAlertMessage('')}
        header={'Erreur'}
        message={alertMessage}
        buttons={[
          {
            text: 'Voir',
            cssClass: 'primary',
            handler: () => {
              setAlertMessage('');
              history.push(`/intervention/${currentInterventionId}`);
            }
          },
          {
            text: 'Annuler',
            role: 'cancel',
            cssClass: 'secondary',
          }
        ]}
      />
      {
        loading
          ? (
            <Spinner />
          )
          : error
            ? (
              <Alert
                type="error"
                title="Erreur"
                message={error.message}
                showIcon
              />
            )
            : (
              <>
                <IonToolbar>
                  <IonSegment
                    value={interventionViewKey}
                    onIonChange={onSegmentChanged}
                  >
                    <IonSegmentButton
                      value="intervention-info"
                    >
                      {
                        interventionViewKey === 'intervention-info' ?
                          <IonIcon icon={informationCircleFull} /> :
                          <IonIcon icon={informationCircleOutline} />
                      }
                      Infos
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="intervention-materiel"
                    >
                      {
                        interventionViewKey === 'intervention-materiel' ?
                          <IonIcon icon={constructFull} /> :
                          <IonIcon icon={constructOutline} />
                      }
                      Matériels
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="intervention-rapport"
                    >
                      {
                        interventionViewKey === 'intervention-rapport' ?
                          <IonIcon icon={documentTextFull} /> :
                          <IonIcon icon={documentTextOutline} />
                      }
                      Rapport
                    </IonSegmentButton>
                  </IonSegment>
                </IonToolbar>
                {interventionViews[interventionViewKey]()}
              </>
            )
      }
    </PageLayout>
  );
};

export default withAuthentication(InterventionPage);
