import React from 'react';
import sanitizeHtml from 'sanitize-html';

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'br' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
  allowedIframeHostnames: ['www.youtube.com']
};

const sanitize = (dirty: string, options: sanitizeHtml.IOptions | undefined) => ({
  __html: sanitizeHtml(
    dirty,
    {
      ...defaultOptions,
      ...options,
    },
  )
});

interface SanitizeHTMLProps {
  html: string;
  options?: sanitizeHtml.IOptions;
}

const SanitizeHTML: React.FC<SanitizeHTMLProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ html, options, ...props }) => (
  <div
    dangerouslySetInnerHTML={sanitize(html, options)}
    {...props}
  />
);

export default SanitizeHTML;
