import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
} from '@ionic/react';
import React, { ReactNode } from 'react';

import './styles.css';

interface InterventionProps {
  children: React.ReactNode;
  backButtonLink?: string;
  title?: string;
  menu?: boolean;
  onRefresh?: () => Promise<any>;
  footer?: ReactNode|null;
}

const PageLayout: React.FC<InterventionProps> = ({ children, backButtonLink, title, menu, onRefresh, footer }) => {
  const onRefreshPage = (pEvent: CustomEvent) => {
    onRefresh && onRefresh().finally(
      () => {
        pEvent.detail.complete();
      }
    );
  };

  return (
    <IonPage>
      {
        (backButtonLink !== undefined || title !== undefined || menu !== undefined) && (
          <IonHeader>
            <IonToolbar>
              {
                backButtonLink !== undefined && (
                  <IonButtons slot="start">
                    <IonBackButton defaultHref={backButtonLink} text="Retour" />
                  </IonButtons>
                )
              }
              {
                menu && (
                  <IonButtons slot="end">
                    <IonMenuButton />
                  </IonButtons>
                )
              }
              {
                title !== undefined &&
                  <>
                    <IonTitle>
                      {title}
                    </IonTitle>
                  </>
              }
            </IonToolbar>
          </IonHeader>
        )
      }
      <IonContent fullscreen>
        {
          title !== undefined && (
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">
                  {title}
                </IonTitle>
              </IonToolbar>
            </IonHeader>
          )
        }
        {
          onRefresh &&
            <IonRefresher slot="fixed" onIonRefresh={onRefreshPage}>
              <IonRefresherContent />
            </IonRefresher>
        }
        {children}
        <div style={{ height: '100px' }} />
      </IonContent>
      {
        footer &&
          <IonFooter>
            {footer}
          </IonFooter>
      }
    </IonPage>
  );
};

export default PageLayout;
