import { useState } from 'react';
import { useCamera } from '@ionic/react-hooks/camera';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import { CameraResultType, CameraSource } from '@capacitor/core';

export interface Photo {
  filepath: string;
  webviewPath?: string;
  base64data: string;
}

export function usePhotoGallery() {

  const { getPhoto } = useCamera();
  const [photos, setPhotos] = useState<Photo[]>([]);

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      quality: 90
    });
    const fileName = new Date().getTime() + '.jpeg';
    const newPhotos = [{
      filepath: fileName,
      webviewPath: cameraPhoto.webPath,
      base64data: await base64FromPath(cameraPhoto.webPath || ''),
    }, ...photos];
    setPhotos(newPhotos);
  };

  const removePhoto = (toRemove: Photo) => {
    setPhotos(photos.filter(photo => photo.filepath !== toRemove.filepath));
  };

  const clear = () => {
    setPhotos([]);
  };

  return {
    photos,
    takePhoto,
    removePhoto,
    clear
  };
}