export type OnLastFormElementHandler = () => void;

export const handleBlurEnterInput = (submitHandler: OnLastFormElementHandler | null): React.KeyboardEventHandler<HTMLInputElement | HTMLIonInputElement> => (event) => {
  if (event.key.toLowerCase() === 'enter') {
    event.preventDefault();

    const target = event.target as HTMLInputElement;

    target.blur();

    if (submitHandler !== null) {
      submitHandler();
    }
  }
};

export const handleEnter = (submitHandler?: OnLastFormElementHandler | null): React.KeyboardEventHandler<HTMLFormElement> => (event) => {
  if (event.key.toLowerCase() === 'enter') {
    event.preventDefault();

    const inputElement: HTMLInputElement = event.target as HTMLInputElement;

    if ('form' in inputElement && inputElement.form !== null) {
      const formElement: HTMLFormElement = inputElement.form;

      let nextInputElement: Element | undefined = undefined;
      for (let i: number = 0; i < formElement.elements.length; ++i) {
        if (formElement[i] === inputElement) {
          nextInputElement = formElement.elements[i + 1];
          break;
        }
      }

      inputElement.blur();

      if (nextInputElement !== undefined) {
        if ('focus' in nextInputElement) {
          (nextInputElement as HTMLElement).focus();
        }
      } else {
        if (
          submitHandler !== null &&
          submitHandler !== undefined
        ) {
          submitHandler();
        }
      }
    }
  }
};
