import React from 'react';

import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
} from '@ionic/react';

import { InterventionFieldsLightFragment } from 'apollo/queries/types';
import {
  businessOutline,
} from 'ionicons/icons';
import { formatDateStrForMobile } from 'utils/formatDate';

import '../styles.css';
import StateIcon from 'components/StateIcon';

interface InterventionsListItemProps {
  intervention: InterventionFieldsLightFragment;
}

const getTime = (dateStr: string | null | undefined): string => {
  if (!dateStr) return '';
  const date = new Date(formatDateStrForMobile(dateStr));
  const today = new Date();

  if (date.toLocaleDateString('fr-FR') === today.toLocaleDateString('fr-FR')) {
    // date is today, we display the time
    return `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
  } else {
    // date is not today, we display the date
    return date.toLocaleDateString('fr-FR');
  }
};

const InterventionListItem: React.FC<InterventionsListItemProps> = ({ intervention }) => {
  let lCityInfo = '';

  if (intervention.siteId.zip) {
    lCityInfo += `${intervention.siteId.zip}`;
    if (intervention.siteId.city) {
      lCityInfo += ` - ${intervention.siteId.city}`;
    }
  } else {
    if (intervention.siteId.city) {
      lCityInfo += `${intervention.siteId.city}`;
    }
  }

  return (
    <IonItem
      className="intervention-list-item"
      routerLink={`/intervention/${intervention.id}`}
    >
      {
        intervention.clientId.image128 ?
          (
            <IonAvatar
              slot="start"
              style={{ margin: '0 10px' }}
            >
              <IonImg src={`data:image/png;base64,${intervention.clientId.image128}`} />
            </IonAvatar>
          ) :
          (
            <IonIcon
              icon={businessOutline}
              slot="start"
              style={{ margin: '0 15px 0 20px' }}
            />
          )
      }
      <IonLabel>
        <h2 style={{ float: 'left' }}>
          {intervention.clientId.name}
        </h2>
        <IonText color="primary" style={{ float: 'right', fontWeight: 'bold' }}>
          {getTime(intervention.interventionDate)}
        </IonText>
        <div style={{ clear: 'both' }} />
        <h3 style={{ float: 'left' }}>
          {intervention.name}
        </h3>
        {
          intervention.typeId?.name &&
            <IonText color="primary" style={{ float: 'right', fontWeight: 'bold' }}>
              {intervention.typeId?.name}
            </IonText>
        }
        <br />
        <hr className="solid" />
        <p style={{ float: 'left' }}>
          {intervention.siteId.name}
        </p>
        <div style={{ clear: 'both' }} />
        <p>
          {
            intervention.siteId.street &&
            <>
              {intervention.siteId.street}
            </>
          }
          {
            intervention.siteId.street2 &&
            <>
              <br />
              {intervention.siteId.street2}
            </>
          }
        </p>
        {
          lCityInfo !== '' &&
            <p style={{ float: 'left' }}>
              {lCityInfo}
            </p>
        }
        <StateIcon 
          state={intervention.state?.key || ''}
          style={{ float: 'right' }}
        />
      </IonLabel>
    </IonItem>
  );
};

export default InterventionListItem;
