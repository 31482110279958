import {
  IonItem,
  IonList,
} from '@ionic/react';

import { OperationFieldsFragment, ProcessingFieldsFragment } from 'apollo/queries/types';

import './styles.css';
import OperationListItem from './OperationListItem';

interface OperationListProps {
  processing: ProcessingFieldsFragment | null | undefined
  operations: OperationFieldsFragment[],
  validated: boolean
}

const OperationList: React.FC<OperationListProps> = ({ processing, operations, validated }) => {
  return (
    <IonList>
      {
        operations.length === 0 ?
          <IonItem lines="none">
          Aucune opération pratiquée pour le moment
          </IonItem> :
          operations.map(
            (operation) => (
              <OperationListItem
                processing={processing}
                operation={operation}
                validated={validated}
              />
            )
          )
      }
    </IonList>
  );
};

export default OperationList;
