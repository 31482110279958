import { NodeType, JSXChildren, Nodes, JSXNode, BuiltNode, JSXBuiltNode, CompilerType } from './types';


export const buildLuqComponent = <PropsType>(type: NodeType) => {
  const Component = (props: PropsType) => {
    return {
      key: null,
      props: { ...props, type },
      type: Component,
    };
  };

  return Component;
};

const getChildrenArray = (children: JSXChildren): Nodes<JSXNode> => {
  if (!(children instanceof Array)) {
    return [children];
  }

  return children;
};

const flattenChildrenArray = (childrenArray: Nodes<JSXNode>, flatChildren: Nodes<JSXNode> = []): Nodes<JSXNode> => {
  return childrenArray.reduce((flatChildren, child) => {
    if (child !== undefined && child !== null) {
      if (child instanceof Array) {
        flattenChildrenArray(child, flatChildren);
      }
      else {
        flatChildren.push(child);
      }
    }

    return flatChildren;
  }, flatChildren);
};

const getNextNode = (tree: JSXNode): JSXBuiltNode | JSXNode => {
  const builtReactNode = tree.type(tree.props);

  if ((builtReactNode as { $$typeof?: symbol }).$$typeof) {
    return getNextNode(builtReactNode as JSXNode);
  }

  return builtReactNode;
};

const buildTree = (tree: JSXNode): BuiltNode => {  
  const builtReactNode = getNextNode(tree) as JSXBuiltNode;

  const { type, children, ...props } = builtReactNode.props;

  const builtNode: BuiltNode = {
    type,
    props,
    children: (children === undefined ?
      null :
      flattenChildrenArray(getChildrenArray(children)).map(buildTree)),
  };

  return builtNode;
};

const createCompiler = (compiler: CompilerType) => (tree: JSXNode) => {
  const builtTree = buildTree(tree);

  return compiler(builtTree);
};


export default createCompiler;