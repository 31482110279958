import config from 'config/config.json';

import {
  HTTPResponseError,
  checkStatus,
} from './fetchHelpers';

export interface OdooError {
  code: number;
  data: {
    name: string;
    debug?: string;
  };
  message: string;
}

export interface OdooResult {
  result: any;
}

export interface OdooResponseBody {
  result?: any;
  error?: OdooError;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

const odooRequest = async (route: string, body: object): Promise<OdooResponseBody> => {
  const token = localStorage.getItem('session_id');

  const customHeader: HeadersInit = (
    config.AUTH_METHOD === 'token' &&
    token !== null &&
    token !== undefined
  ) ?
    {
      'X-Openerp-Session-Id': token,
    } :
    {};

  const fetchResponse: Response = await fetch(
    `${config.ODOO_HOST}${route}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      /* Comment this line to use session_ids tokens yourself instead of cookies. */
      credentials: config.AUTH_METHOD === 'cookie' ? 'include' : 'omit',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...customHeader,
      },
    }
  );

  try {
    checkStatus(fetchResponse);
  } catch (exception) {
    const lErrorBody: string = await (exception as HTTPResponseError).response.text();

    console.error(`[ERROR] <odooRequest> Request to ${config.ODOO_HOST}${route} w/ body ${body} failed :`, exception);
    console.error(`        Error body: ${lErrorBody}`);

    throw exception;
  }

  const odooRequestResultBody: OdooResponseBody = await fetchResponse.json();

  if (
    odooRequestResultBody.error !== undefined &&
    odooRequestResultBody.error !== null
  ) {
    const odooError: OdooError = odooRequestResultBody.error;
    console.error(`[ERROR] <odooRequest> Got an error for '${route}' :`, odooError);
    throw odooError;
  }

  return odooRequestResultBody;
};

export const logout = async (username?: string): Promise<OdooResponseBody> => {
  try {
    const logoutResults: OdooResponseBody = await odooRequest(
      '/web/session/signout',
      {},
    );

    console.log(`[DEBUG] <logout> Got response :`, logoutResults);

    /* Reset the session cookie/token */
    localStorage.removeItem('session_id'); /* If not found, doesn't throw */
    document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    return logoutResults;
  } catch (exception) {
    console.error(`[ERROR] <logout> Failed to logout user ${username} from ${config.ODOO_HOST} :`, exception);
    throw exception;
  }
};

export const login = async (loginCredentials: LoginCredentials): Promise<OdooResponseBody> => {
  try {
    const loginResults: OdooResponseBody = await odooRequest(
      '/web/session/authenticate',
      {
        params: {
          login: loginCredentials.email,
          password: loginCredentials.password,
          db: config.ODOO_DATABASE,
        },
      }
    );

    // console.log(`[DEBUG] <login> Got response :`, loginResults);

    if (
      loginResults.result?.session_id !== undefined &&
      loginResults.result?.session_id !== null
    ) {
      if (config.AUTH_METHOD === 'token') {
        localStorage.setItem('session_id', loginResults.result.session_id);
        // console.log(`[DEBUG] <login> Got token :`, loginResults.result.session_id);
      }
    }
    /* 'cookie' AUTH_METHOD handled automatically by browser & Set-Cookie header */

    return loginResults;
  } catch (exception) {
    console.error(`[ERROR] <login> Failed to authenticate user ${loginCredentials.email} to ${config.ODOO_HOST} :`, exception);
    throw exception;
  }
};
