import React from 'react';
import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonTextarea,
} from '@ionic/react';
import { MutationUpdateMaintenanceProcessingArgs } from 'apollo/types';

import './styles.css';

interface ProcessingReportViewProps {
  report: MutationUpdateMaintenanceProcessingArgs;
  setReport: (newReport: MutationUpdateMaintenanceProcessingArgs) => void;
  saveReport: () => void;
  edit: boolean;
}

const ProcessingReportView: React.FC<ProcessingReportViewProps> = ({ report, setReport, saveReport, edit }) => {
  const onChange = (key: string, value: string|boolean|null|undefined) => {
    setReport({
      ...report,
      [key]: value,
    });
  };

  return (
    <>
      <IonItem>
        <IonLabel>
          Résultat <span style={{ color: 'red' }}>*</span>
        </IonLabel>
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea
          value={report?.result}
          autoGrow
          onIonChange={e => onChange('result', e.detail.value)}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </div>
      <IonItem>
        <IonLabel>
          Commentaires
        </IonLabel>
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea
          value={report?.comment}
          autoGrow
          onIonChange={e => onChange('comment', e.detail.value)}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </div>
      <IonItem>
        <IonLabel>
          Matériel maintenu à l'arrêt ?
        </IonLabel>
        <IonCheckbox
          checked={report?.materialKeepedStopped || false}
          onIonChange={e => {
            onChange('materialKeepedStopped', e.detail.checked);
          }}
          disabled={!edit}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Installation conforme ?
        </IonLabel>
        <IonCheckbox
          checked={report?.installationOk || false}
          onIonChange={(e) => {
            onChange('installationOk', e.detail.checked);
          }}
          disabled={!edit}
        />
      </IonItem>
    </>
  );
};

export default ProcessingReportView;