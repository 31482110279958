import {
  IonRow,
  IonCol,
  IonModal,
  IonContent,
  IonButton,
  IonFooter,
} from '@ionic/react';
import { ImageFieldsFragment } from 'apollo/mutations/types';
import { useEffect, useState } from 'react';
import config from 'config/config.json';

import './styles.css';
import { fetchPhoto } from 'utils/fetchPhoto';

interface ProcessingPhotosProps {
  photos: ImageFieldsFragment[];
};

interface PhotoBooleanList {
  [id: string]: boolean;
}

const ProcessingPhotos: React.FC<ProcessingPhotosProps> = ({ photos }) => {
  const [ photoOpen, setPhotoOpen ] = useState<ImageFieldsFragment | null>(null);
  const [ loadingStatus, setLoadingStatus ] = useState<PhotoBooleanList>(
    photos.reduce(
      (pAcc: Record<string, boolean>, pCurrent: ImageFieldsFragment, pIndex: number) => {
        return {
          ...pAcc,
          [pIndex.toString()]: false,
        };
      },
      {},
    ),
  );

  const imageLoadedHandler = (pIndex: number): React.ReactEventHandler<HTMLImageElement> => (pEvent) => {
    setLoadingStatus(
      {
        ...loadingStatus,
        [pIndex]: true,
      },
    );
  };

  const loadPhotosSD = async (pPhotos: ImageFieldsFragment[]) => {
    await Promise.all(
      pPhotos
        .filter(
          (photo: ImageFieldsFragment): boolean => photo.url128 !== null && photo.url128 !== undefined && photo.url128.length > 0,
        )
        .map(
          async (photo: ImageFieldsFragment, index: number): Promise<void> => {
            if (photo.url128) {
              const photoFile = await fetchPhoto(
                photo.url128,
                photo.name || undefined,
              );

              const reader = new FileReader();
              reader.readAsDataURL(photoFile);
              reader.onloadend = () => {
                const base64Data = reader.result;
                const imgTag: HTMLImageElement = document.getElementById(`processing-photo-${photo.name || photo.id.toString()}`) as HTMLImageElement;
                imgTag.src = `data:${photoFile.type};base64 ${base64Data}`;
              };
            }
          }
        )
    );
  };

  const loadPhotosHD = async (pPhoto: ImageFieldsFragment) => {
    const photoRoute: string | null | undefined = pPhoto.url1920 || pPhoto.url128;

    if (photoRoute) {
      const photoFile = await fetchPhoto(
        photoRoute,
        pPhoto.name || undefined,
      );

      const reader = new FileReader();
      reader.readAsDataURL(photoFile);
      reader.onloadend = () => {
        const base64Data = reader.result;
        const divTag: HTMLDivElement = document.getElementById(`processing-photo-opened`) as HTMLDivElement;
        divTag.style.backgroundImage = `url("data:${photoFile.type};base64 ${base64Data}")`;
      };
    }
  };

  useEffect(
    () => {
      loadPhotosSD(photos).catch(console.error);
    },
    [
      photos,
    ],
  );

  useEffect(
    () => {
      if (photoOpen !== null) {
        loadPhotosHD(photoOpen).catch(console.error);
      }
    },
    [
      photoOpen,
    ],
  );

  console.log(`[DEBUG] <ProcessingPhotos> photos :`, photos);

  return (
    <>
      <IonRow>
        {
          photos.map(
            (photo, i) =>
              <IonCol size="4" key={i}>
                <img
                  alt={photo.name || photo.id.toString()}
                  className="processing-photo"
                  onClick={() => setPhotoOpen(photo)}
                  onLoad={imageLoadedHandler(i)}
                  id={`processing-photo-${photo.name || photo.id.toString()}`}
                />
                {
                  !loadingStatus[i] && (
                    <div className="overlay">
                      <div className="overlay__inner">
                        <div className="overlay__content">
                          <span className="spinner" />
                        </div>
                      </div>
                    </div>
                  )
                }
              </IonCol>
          )
        }
      </IonRow>
      <IonModal isOpen={photoOpen !== null}>
        <IonContent>
          {
            photoOpen !== null &&
            <div
              className="fullscreen-image-container"
              id="processing-photo-opened"
            />
          }
        </IonContent>
        <IonFooter>
          <IonButton expand="full" onClick={() => setPhotoOpen(null)}>
            Quitter
          </IonButton>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default ProcessingPhotos;
