import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
} from '@ionic/react';

import {
  callOutline,
  phonePortraitOutline,
  mailOutline,
} from 'ionicons/icons';

import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';
import Spinner from 'components/Spinner';

import { useClientQuery } from 'apollo/queries';

import './styles.css';

interface ClientPageProps
  extends RouteComponentProps<{
    clientId: string;
  }> {}

const ClientPage: React.FC<ClientPageProps> = ({ match }) => {
  const clientID: number = Number(match.params.clientId);

  const {
    loading: loadingClient,
    error: errorClient,
    data: dataClient,
    refetch: refetchClient,
  } = useClientQuery({
    variables: {
      id: clientID,
    },
  });


  if (loadingClient) {
    return (
      <Spinner />
    );
  }

  const clientInformation = !errorClient ? dataClient?.getResPartner : null;

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Client"
      onRefresh={
        async () => {
          await refetchClient(
            {
              id: clientID,
            },
          );
        }
      }
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {
              clientInformation?.image256 &&
                <>
                  <img
                    alt={`Logo de l'entreprise ${clientInformation?.name}`}
                    src={`data:image/png;base64, ${clientInformation?.image256}`}
                  />
                  <br />
                </>
            }
            {clientInformation?.name}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          style={
            {
              textAlign: 'center'
            }
          }
        >
          {
            clientInformation?.street &&
                <>
                  {clientInformation?.street}
                  <br />
                </>
          }
          {
            clientInformation?.street2 &&
                <>
                  {clientInformation?.street2}
                  <br />
                </>
          }
          {
            clientInformation?.zip &&
                <>
                  {`${clientInformation?.zip} `}
                </>
          }
          {
            clientInformation?.city &&
                <>
                  {clientInformation?.city}
                  <br />
                </>
          }
          <br />
          {
            clientInformation?.phone &&
              <IonButton
                expand="block"
                href={`tel:${clientInformation?.phone}`}
              >
                <IonIcon icon={callOutline} />
                &nbsp;
                {clientInformation?.phone}
              </IonButton>
          }
          {
            clientInformation?.mobile &&
              <IonButton
                expand="block"
                href={`tel:${clientInformation?.mobile}`}
              >
                <IonIcon icon={phonePortraitOutline} />
                &nbsp;
                {clientInformation?.mobile}
              </IonButton>
          }
          {
            clientInformation?.email &&
              <IonButton
                expand="block"
                href={`mailto:${clientInformation?.email}`}
              >
                <IonIcon slot="start" icon={mailOutline} />
                &nbsp;
                <IonText style={{ fontSize: '12px' }}>{clientInformation?.email}</IonText>
              </IonButton>
          }
          {/* <hr className="solid" /> */}
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Sites
          </IonCardSubtitle>
          <IonList>
            {
              clientInformation?.siteIds.map(
                (pSite) => {
                  /* pSite is a { key;value } object */
                  return (
                    <IonItem
                      key={pSite.id}
                      routerLink={`/site/${pSite.id}`}
                    >
                      <IonLabel>
                        {pSite.name}
                      </IonLabel>
                    </IonItem>
                  );
                }
              )
            }
          </IonList>
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Contacts
          </IonCardSubtitle>
          <IonList>
            {
              clientInformation?.childIds.map(
                (pContact) => {
                  /* pSite is a { key;value } object */
                  return (
                    <IonItem
                      key={pContact.id}
                      routerLink={`/contact/${pContact.id}`}
                    >
                      <IonLabel>
                        {pContact.name}
                      </IonLabel>
                    </IonItem>
                  );
                }
              )
            }
          </IonList>
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Commentaires
          </IonCardSubtitle>
          {clientInformation?.comment || 'N/A'}
        </IonCardContent>
      </IonCard>
    </PageLayout>
  );
};

export default withAuthentication(ClientPage);
