import React from 'react';
import { Redirect } from 'react-router-dom';

import { useLoggedInQuery, useCurrentUserQuery } from 'apollo/queries';

const AuthenticatedRoute: React.FC = ({ children }) => {
  const { data: loggedInData } = useLoggedInQuery();

  const {
    error: errorCurrentUser,
  } = useCurrentUserQuery(
    {
      skip: !loggedInData?.isLoggedIn
    }
  );

  if (!loggedInData?.isLoggedIn || errorCurrentUser) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <>
      { children }
    </>
  );
};

const withAuthentication = <T extends object>(Component: React.FC<T>) => (props: T) => {
  return (
    <AuthenticatedRoute>
      <Component {...props} />
    </AuthenticatedRoute>
  );
};

export default withAuthentication;
