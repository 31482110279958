import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import { useCancelStockMoveMutation } from 'apollo/mutations';
import { StockFieldsFragment, StockMoveFieldsFragment, StockZoneFieldsFragment } from 'apollo/queries/types';
import SectionTitle from 'components/SectionTitle';
import { arrowUndo } from 'ionicons/icons';

import './styles.css';

const stockZoneDisplayName = (stockZone: StockZoneFieldsFragment|null|undefined) => {
  if (!stockZone) {
    return '';
  }
  if (stockZone.type?.key === 'manual') {
    return stockZone.name;
  } else {
    return stockZone.type?.value;
  }
};

interface ProcessingStockProps {
  processingId: number;
  materialStocks: StockFieldsFragment[];
  siteStocks: StockFieldsFragment[];
  processingStockMoves: StockMoveFieldsFragment[];
  edit?: boolean;
};

const ProcessingStock: React.FC<ProcessingStockProps> = ({ processingId, materialStocks, siteStocks, processingStockMoves, edit=false }) => {
  const [ cancelStockMove ] = useCancelStockMoveMutation();

  const handleAbortMove = (move: StockMoveFieldsFragment) => {
    if (
      move.articleId &&
      move.originId &&
      move.destinationId && 
      move.quantity &&
      move.processingId
    ) {
      cancelStockMove({
        variables: {
          id: move.id,
          articleId: move.articleId.id,
          originId: move.originId.id,
          destinationId: move.destinationId.id,
          quantity: move.quantity,
          processingId: move.processingId.id,
        },
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({
              __typename: 'MaintenanceProcessing',
              id: processingId,
            }),
            fields: {
              moveIds(oldMoveIds = []) {
                return [
                  ...oldMoveIds, 
                  {
                    __ref: cache.identify({
                      __typename: 'MaintenanceStockMove',
                      id: data?.createMaintenanceStockMove?.created?.id
                    })
                  },
                ];
              }
            },
          });
          cache.modify({
            id: cache.identify({
              __typename: 'MaintenanceStockMove',
              id: move.id
            }),
            fields: {
              abortedByMoveIds() {
                return [
                  {
                    __ref: cache.identify({
                      __typename: 'MaintenanceStockMove',
                      id: data?.createMaintenanceStockMove?.created?.id,
                    })
                  }
                ];
              }
            }
          });
        }
      });
    }
  };

  return (<>
    <IonText color="primary"><SectionTitle title="Articles sur le matériel" /></IonText>
    <IonList>
      {materialStocks.map(stock => 
        <IonItem key={stock.id} className="stock-list-item">
          <IonLabel className="ion-text-wrap"><span style={{ fontWeight: 'bold' }}>{stock.articleId?.name}</span> - Réf : {stock.articleId?.ref}</IonLabel> 
          <IonText slot="end">{stock.quantity}</IonText>
        </IonItem>
      )}
    </IonList>
    <IonText color="primary"><SectionTitle title="Stock sur site" /></IonText>
    <IonList>
      {siteStocks.map(stock => 
        <IonItem key={stock.id} className="stock-list-item">
          <IonLabel className="ion-text-wrap"><span style={{ fontWeight: 'bold' }}>{stock.articleId?.name}</span> - Réf : {stock.articleId?.ref}</IonLabel> 
          <IonText slot="end">{stock.quantity}</IonText>
        </IonItem>
      )}
    </IonList>
    <IonText color="primary"><SectionTitle title="Mouvements de stock" /></IonText>
    <IonList>
      {processingStockMoves.map(stockMove => 
        <IonRow key={stockMove.id}>
          <IonCol size={edit ? '10.5' : '12'} style={{ padding: 0 }}>
            <IonItem  key={stockMove.id} className="stock-list-item">
              <IonLabel className="ion-text-wrap">
                {stockZoneDisplayName(stockMove.originId)} ➔ {stockZoneDisplayName(stockMove.destinationId)}<br />{stockMove.articleId?.name}
              </IonLabel> 
              <IonText slot="end">{stockMove.quantity}</IonText>
            </IonItem>
          </IonCol>
          {edit && <IonCol size="1.5">
            {!stockMove.abortMoveId && stockMove.abortedByMoveIds.length < 1 &&
              <IonButton fill="clear" className="list-button" onClick={() => handleAbortMove(stockMove)}>
                <IonIcon icon={arrowUndo} className="list-button-icon" />
              </IonButton>
            }
          </IonCol>}
        </IonRow>
      )}
    </IonList>
  </>);
};

export default ProcessingStock;