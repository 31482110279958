import React from 'react';

import {
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';

interface CodeIonItemProp {
  codeFormat: string;
  code: string | null;
}

const CodeIonItem: React.FC<CodeIonItemProp> = (
  {
    codeFormat,
    code,
  }
) => {
  return (
    <IonItem>
      <IonLabel>
        {`Code`}
      </IonLabel>
      <IonNote>
        {`[${codeFormat} : ${code}]`}
      </IonNote>
    </IonItem>
  );
};

export default CodeIonItem;
