import { useState } from 'react';
import {
  IonButton,
  IonInput,
  IonItem,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  IonLabel,
  IonSpinner,
} from '@ionic/react';

import {
  lockOpenOutline,
  lockClosedOutline,
} from 'ionicons/icons';

import PageLayout from 'layouts/PageLayout';

import { login } from 'utils/authentication';
import useNotifier from 'utils/notification';

import {
  useCurrentUserQuery,
  useLoggedInQuery,
} from 'apollo/queries';

import './styles.css';
import { handleEnter } from 'utils/handleEnter';

interface LoginProps {
  afterLogin?: () => void;
  onFailure?: (err: Error) => void;
};

const LoginPage: React.FC<LoginProps> = ({ afterLogin, onFailure }) => {
  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const notify = useNotifier();

  const { data: loggedInData } = useLoggedInQuery();
  const {
    loading: loadingCurrentUserData,
    error: currentUserDataError,
  } = useCurrentUserQuery(
    {
      skip: !loggedInData?.isLoggedIn
    }
  );

  const tryLogin = (): void => {
    login(
      {
        email: email,
        password: password,
      },
    )
      .then(
        (loginResult) => {
          notify(
            {
              color: 'success',
              message: 'Connexion réussie !',
            }
          );

          if (afterLogin !== undefined) {
            afterLogin();
          }
        }
      )
      .catch(
        (error) => {
          console.log(
            `[ERROR] <LoginPage.tryLogin> Login failed :`,
            error,
          );

          if (
            error.message === 'Odoo Server Error' &&
            error.data.name === 'odoo.exceptions.AccessDenied'
          ) {
            notify(
              {
                color: 'danger',
                message: 'Identifiant ou mot de passe incorrect',
              }
            );
          } else {
            notify(
              {
                color: 'danger',
                message: "Erreur d'authentification",
              }
            );
          }
          if (onFailure !== undefined) {
            onFailure(error);
          }
        }
      );
  };

  return (
    <PageLayout title="Connexion">
      <div
        style={
          {
            padding: '20px'
          }
        }
      >
        <IonRow>
          <IonCol>
            <IonImg
              src={'/assets/quicker-icon/logo-quicker.png'}
              style={
                {
                  height: '100px'
                }
              }
            />
          </IonCol>
        </IonRow>
        <form
          onSubmit={e => {
            e.preventDefault();
            tryLogin();
          }}
          onKeyDown={handleEnter(tryLogin)}
          noValidate
        >
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">
                  Email
                </IonLabel>
                <IonInput
                  type="email"
                  name="email"
                  value={email}
                  onIonChange={e => setEmail(e.detail.value || '')}
                  placeholder="Adresse mail..."
                  required
                  enterkeyhint="next"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">
                  Mot de passe
                </IonLabel>
                <IonInput
                  type="password"
                  name="password"
                  value={password}
                  onIonChange={e => setPassword(e.detail.value || '')}
                  placeholder="Mot de passe..."
                  required
                  enterkeyhint="go"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                type="submit"
                disabled={!email || !password || loadingCurrentUserData}
                expand="block"
              >
                {
                  loadingCurrentUserData ?
                    <IonSpinner /> :
                    <>
                      <IonIcon
                        icon={
                          (!email || !password) ?
                            lockClosedOutline :
                            lockOpenOutline
                        }
                      />
                      &nbsp;
                      Se connecter
                    </>
                }
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </div>
    </PageLayout>
  );
};

export default LoginPage;
