import React, { useState } from 'react';

import {
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from '@ionic/react';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';
import SectionTitle from 'components/SectionTitle';
import InterventionCreator from './InterventionCreator';
import CodeIonItem from './CodeIonItem';

import type { QuaggaJSResultObject } from '@ericblade/quagga2/type-definitions/quagga';
import type { ApolloError } from '@apollo/client';
import type { InterventionFieldsFullFragment } from 'apollo/queries/types';
import InterventionList from 'components/InterventionList';

interface ScanResultDisplayProps {
  loadingInterventions: boolean;
  interventions: InterventionFieldsFullFragment[]; /* TODO : We have to find a better type than this */
  latestScanResult: QuaggaJSResultObject;
  errorInterventions?: ApolloError;
}

const ScanResultDisplay: React.FC<ScanResultDisplayProps> = (
  {
    loadingInterventions,
    interventions,
    latestScanResult,
    errorInterventions,
  }
) => {
  const [ creatingInter, setCreatingInter ] = useState<boolean>(false);

  if (loadingInterventions) {
    return (
      <Spinner />
    );
  }

  /* Case : failed to fetch data from the back-end */
  if (errorInterventions && latestScanResult) {
    return (
      <Alert
        message="Impossible de récupérer l'intervention associé à ce code"
        secondaryMessage={errorInterventions.message}
      />
    );
  }

  return (
    <>
      <SectionTitle title="Résultats du scan" />
      <CodeIonItem
        codeFormat={latestScanResult.codeResult.format}
        code={latestScanResult.codeResult.code}
      />
      <SectionTitle title="Interventions correspondantes" />
      {
        interventions.length > 0 ?
          <InterventionList
            interventions={interventions}
          /> :
          <Alert
            type="warning"
            title="Intervention requise"
            message={
              <>
                {`Aucune intervention n'est associée à ce matériel`}
                <br />
                <br />
                {`Est-ce que vous souhaitez créer une intervention pour ce matériel ?`}
              </>
            }
          />
      }
      {
        creatingInter ?
          <>
            <SectionTitle title="Création d'une intervention" />
            <InterventionCreator
              latestScanResult={latestScanResult}
              onDismissCreate={
                () => {
                  setCreatingInter(false);
                }
              }
            />
          </> :
          <IonButton
            expand="block"
            onClick={
              () => {
                setCreatingInter(true);
              }
            }
            style={
              {
                margin: '1rem',
              }
            }
          >
            Créer une intervention
          </IonButton>
      }
    </>
  );
};

export default ScanResultDisplay;
