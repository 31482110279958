import { OperationFieldsFragment, ProcessingFieldsFragment } from 'apollo/queries/types';

import OperationList from 'components/OperationList';

import './styles.css';

import { ProcessingStateKeysEnum } from '../../../utils/ProcessingState';

interface ProcessingOperationsProps {
  interventionId?: number;
  processing: ProcessingFieldsFragment & { operationIds: OperationFieldsFragment[] } | null | undefined;
  edit: boolean;
}

const ProcessingOperations: React.FC<ProcessingOperationsProps> = ({ interventionId, processing, edit }) => {
  return (
    <>
      <OperationList
        processing={processing}
        operations={processing?.operationIds || []}
        validated={processing?.state?.key !== ProcessingStateKeysEnum.open}
      />
    </>
  );
};

export default ProcessingOperations;