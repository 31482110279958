import React, { useCallback, useState } from 'react';
import { IonButton, IonCheckbox, IonCol, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea } from '@ionic/react';
import SignatureCanvas from 'react-signature-canvas';

import { MutationUpdateMaintenanceInterventionReportArgs } from 'apollo/types';
import ReactSignatureCanvas from 'react-signature-canvas';

import './styles.css';
import { pencil } from 'ionicons/icons';
import { stringToTime, timeToString } from 'utils/formatDate';

interface InterventionReportProps {
  edit: boolean;
  report: MutationUpdateMaintenanceInterventionReportArgs;
  setReport: (newReport: MutationUpdateMaintenanceInterventionReportArgs) => void;
  saveReport?: () => void;
}

const InterventionReport: React.FC<InterventionReportProps> = ({ edit, report, setReport, saveReport }) => {
  
  const [showModal, setShowModal] = useState<boolean>(false);
  const [signRef, setSignRef] = useState<ReactSignatureCanvas|null>(null);

  const measuredRef = useCallback((ref: SignatureCanvas) => {
    const resizeCanvas = (canvas: HTMLCanvasElement) => {
      canvas.width = canvas?.parentElement?.clientWidth || 360;
      canvas.height = canvas?.parentElement?.clientHeight || 640;
    };

    if (ref !== null) {
      setSignRef(ref);
      resizeCanvas(ref.getCanvas());
    }
  }, []);

  const onChange = (key: string, value: string|boolean|number|null) => {
    setReport({
      ...report,
      [key]: value,
    });
  };

  const onSignValidate = () => {
    const base64Data = signRef?.toDataURL().split(',')[1] || '';
    onChange('signature', base64Data);
    setShowModal(false);
  };

  return (
    <>
      <IonItem>
        <IonLabel>
          Résultat <span style={{ color: 'red' }}>*</span>
        </IonLabel>
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea 
          value={report?.description} 
          autoGrow 
          onIonChange={e => onChange('description', e.detail.value || '')} 
          disabled={!edit}
          onIonBlur={saveReport}
        /> 
      </div>
      <IonItem>
        <IonLabel>
          Commentaires
        </IonLabel>
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea 
          value={report?.comment} 
          autoGrow 
          onIonChange={e => onChange('comment', e.detail.value || '')} 
          disabled={!edit}
          onIonBlur={saveReport}
        /> 
      </div>
      <IonItem>
        <IonLabel>
          Forcer la clôture de l'intervention
        </IonLabel>
        <IonCheckbox 
          checked={report?.forceCloseIntervention || false} 
          onIonChange={e => { 
            onChange('forceCloseIntervention', e.detail.checked);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          L'intervention est sous contrat ?
        </IonLabel>
        <IonCheckbox 
          checked={report?.onContract || false} 
          onIonChange={e => { 
            onChange('onContract', e.detail.checked);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Nombre de nuits
        </IonLabel>
        <IonInput
          value={report?.nbNights || null} 
          placeholder="0"
          type="number"
          onIonChange={e => { 
            onChange('nbNights', e.detail.value !== null && e.detail.value !== undefined ? parseInt(e.detail.value) : null);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Nombre de repas
        </IonLabel>
        <IonInput
          value={report?.nbMeals || null} 
          placeholder="0"
          type="number"
          onIonChange={e => { 
            onChange('nbMeals', e.detail.value !== null && e.detail.value !== undefined ? parseInt(e.detail.value) : null);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Temps de trajet A/R
        </IonLabel>
        <IonDatetime
          value={report?.journeyTime !== null && report?.journeyTime !== undefined ? timeToString(report?.journeyTime) : null} 
          onIonChange={e => { 
            console.log(e.detail.value);
            console.log(stringToTime(e.detail.value ?? '00:00'));
            onChange('journeyTime', stringToTime(e.detail.value ?? '00:00'));
          }}
          disabled={!edit}
          onIonBlur={saveReport}
          displayFormat="HH:mm"
          cancelText="Annuler"
          doneText="Valider"
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Arrêt de production
        </IonLabel>
        <IonCheckbox 
          checked={report?.productionStop || false} 
          onIonChange={e => { 
            onChange('productionStop', e.detail.checked);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      <IonItem>
        <IonLabel>
          Plusieurs techniciens ?
        </IonLabel>
        <IonCheckbox 
          checked={report?.multipleTechs || false} 
          onIonChange={e => { 
            onChange('multipleTechs', e.detail.checked);
          }}
          disabled={!edit}
          onIonBlur={saveReport}
        />
      </IonItem>
      {report?.multipleTechs && 
        <>
          <IonItem>
            <IonLabel>
              Détails
            </IonLabel>
          </IonItem>
          <div className="bordered-input-textarea">
            <IonTextarea 
              value={report?.multipleTechsComment} 
              autoGrow 
              onIonChange={e => onChange('multipleTechsComment', e.detail.value || '')} 
              disabled={!edit}
              onIonBlur={saveReport}
            /> 
          </div>
        </>
      }
      <IonItem>
        <IonLabel>
          Signature <span style={{ color: 'red' }}>*</span>
        </IonLabel>
        {edit && 
          <IonButton onClick={() => setShowModal(true)} shape="round">
            <IonIcon icon={pencil} />
          </IonButton>
        }
      </IonItem>
      <div style={{ padding: '0px 5px 0px 5px' }}>
        {report.signature && <img src={`data:image/png;base64,${report.signature}`} alt="Signature" />}
      </div>
      
      <IonModal
        isOpen={showModal}
        cssClass="transparent-modal"
        onDidDismiss={saveReport}
      >
        <div className="sig-container">
          <SignatureCanvas
            canvasProps={{ className: 'sig-pad' }}
            ref={measuredRef}
          /> 
        </div>
        <IonRow>
          <IonCol size="6">
            <IonButton 
              expand="full" 
              onClick={() => signRef?.clear()}
            >
              Reprendre
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton 
              expand="full" 
              onClick={onSignValidate}
            >
              Valider
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>
    </>
  );
};

export default InterventionReport;
