import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/react';

import {
  callOutline,
  phonePortraitOutline,
  mailOutline,
} from 'ionicons/icons';

import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';
import Spinner from 'components/Spinner';

import { useContactQuery } from 'apollo/queries';

import './styles.css';

interface ContactPageProps
  extends RouteComponentProps<{
    contactId: string;
  }> {}

const ContactPage: React.FC<ContactPageProps> = ({ match }) => {
  const contactID: number = Number(match.params.contactId);

  const {
    loading: loadingContact,
    error: errorContact,
    data: dataContact,
    refetch: refetchContact,
  } = useContactQuery({
    variables: {
      id: contactID,
    },
  });


  if (loadingContact) {
    return (
      <Spinner />
    );
  }

  const contactInformation = !errorContact ? dataContact?.getResPartner : null;

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Contact"
      onRefresh={
        async () => {
          await refetchContact(
            {
              id: contactID,
            },
          );
        }
      }
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {
              contactInformation?.image256 &&
              <>
                <img
                  alt={`Avatar de ${contactInformation?.name}`}
                  src={`data:image/png;base64, ${contactInformation?.image256}`}
                  style={{ borderRadius: '50%' }}
                />
                <br />
              </>
            }
            {contactInformation?.name}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          style={
            {
              textAlign: 'center'
            }
          }
        >
          {
            contactInformation?.street &&
                <>
                  {contactInformation?.street}
                  <br />
                </>
          }
          {
            contactInformation?.street2 &&
                <>
                  {contactInformation?.street2}
                  <br />
                </>
          }
          {
            contactInformation?.zip &&
                <>
                  {`${contactInformation?.zip} `}
                </>
          }
          {
            contactInformation?.city &&
                <>
                  {contactInformation?.city}
                  <br />
                </>
          }
          <br />
          {
            contactInformation?.phone &&
              <IonButton
                expand="block"
                href={`tel:${contactInformation?.phone}`}
              >
                <IonIcon icon={callOutline} />
                &nbsp;
                {contactInformation?.phone}
              </IonButton>
          }
          {
            contactInformation?.mobile &&
              <IonButton
                expand="block"
                href={`tel:${contactInformation?.mobile}`}
              >
                <IonIcon icon={phonePortraitOutline} />
                &nbsp;
                {contactInformation?.mobile}
              </IonButton>
          }
          {
            contactInformation?.email &&
              <IonButton
                expand="block"
                href={`mailto:${contactInformation?.email}`}
              >
                <IonIcon icon={mailOutline} />
                &nbsp;
                {contactInformation?.email}
              </IonButton>
          }
          {/* <hr className="solid" /> */}
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Commentaires
          </IonCardSubtitle>
          {contactInformation?.comment || 'N/A'}
        </IonCardContent>
      </IonCard>
    </PageLayout>
  );
};

export default withAuthentication(ContactPage);
