import React from 'react';
import {
  IonItem,
  IonLabel,
  IonNote,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonAvatar,
  IonIcon,
} from '@ionic/react';

import Alert from 'components/Alert';
import SectionTitle from 'components/SectionTitle';
import MaterialInfoView from 'components/MaterialInfoView';
import MaterialProcessingHistoryView from 'components/MaterialProcessingHistoryView';

import {
  ProcessingFieldsFragment,
  MaterialFieldsFragment,
} from 'apollo/queries/types';

import './styles.css';
import { bookOutline, calendarOutline, clipboardOutline, documentTextOutline } from 'ionicons/icons';
interface ProcessingInfoViewProps {
  processing: ProcessingFieldsFragment & {
    materialId?: MaterialFieldsFragment|null
  } | null | undefined;
}

const ProcessingInfoView: React.FC<ProcessingInfoViewProps> = ({ processing }) => {

  if (!processing) {
    return (
      <Alert
        message="Il n'y a pas de traitement à afficher."
        secondaryMessage="Merci de rafraîchir la page."
      />
    );
  }

  const material = processing?.materialId;

  if (!material) {
    return (
      <Alert
        message="Il n'y a pas de materiel à afficher."
        secondaryMessage="Merci de rafraîchir la page."
      />
    );
  }

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {material.name}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
      <SectionTitle title="Matériel" />
      <MaterialInfoView material={material} />
      <SectionTitle title="Traitement" />
      <div className="info-list">
        <IonItem>
          <IonAvatar slot="start" className="list-item-icon">
            <IonIcon color="primary" icon={documentTextOutline} style={{ width: '100%' }} size="large" />
          </IonAvatar>
          <IonLabel>
            Titre
          </IonLabel>
          <IonNote
            slot="end"
            color="primary"
          >
            {processing.name || 'N/A'}
          </IonNote>
        </IonItem>
        <IonItem className="noborder-list-item">
          <IonAvatar slot="start" className="list-item-icon">
            <IonIcon color="primary" icon={bookOutline} style={{ width: '100%' }} size="large" />
          </IonAvatar>
          <IonLabel>
            Description
          </IonLabel>
        </IonItem>
        <div className="bordered-text-block">
          <IonText>
            {processing.description || 'N/A'}
          </IonText>
        </div>
        <IonItem>
          <IonAvatar slot="start" className="list-item-icon">
            <IonIcon color="primary" icon={clipboardOutline} style={{ width: '100%' }} size="large" />
          </IonAvatar>
          <IonLabel>
            État
          </IonLabel>
          <IonNote
            slot="end"
            color="primary"
          >
            {processing.state?.value}
          </IonNote>
        </IonItem>
        {processing?.startDate && <IonItem>
          <IonAvatar slot="start" className="list-item-icon">
            <IonIcon color="primary" icon={calendarOutline} style={{ width: '100%' }} size="large" />
          </IonAvatar>
          <IonLabel>
            Démarré le
          </IonLabel>
          <IonNote slot="end" color="primary">
            {
              (
                new Date(
                  processing.startDate
                )
              ).toLocaleDateString('fr-FR')
            }
            <br />
            {
              `à ${
                (
                  new Date(
                    processing.startDate
                  )
                ).toLocaleTimeString('fr-FR')
              }`
            }
          </IonNote>
        </IonItem>}
        {processing?.endDate && <IonItem>
          <IonAvatar slot="start" className="list-item-icon">
            <IonIcon color="primary" icon={calendarOutline} style={{ width: '100%' }} size="large" />
          </IonAvatar>
          <IonLabel>
            Terminé le
          </IonLabel>
          <IonNote slot="end" color="primary">
            {
              (
                new Date(
                  processing.endDate
                )
              ).toLocaleDateString('fr-FR')
            }
            <br />
            {
              `à ${
                (
                  new Date(
                    processing.endDate
                  )
                ).toLocaleTimeString('fr-FR')
              }`
            }
          </IonNote>
        </IonItem>}
      </div>
      <SectionTitle title="Historique des traitements" />
      <MaterialProcessingHistoryView materialID={material.id} />
    </>
  );
};

export default ProcessingInfoView;
