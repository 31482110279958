import compile from 'utils/domain';
import * as Luq from 'luq';
import useUserId from 'utils/userId';
import { useSearchInterventionsLightQuery } from 'apollo/queries';

const useCurrentIntervention = () => {
  const userId = useUserId();
  const { data } = useSearchInterventionsLightQuery(
    {
      variables: {
        filter: compile(
          <Luq.And>
            <Luq.Eq field="techId" value={userId} />
            <Luq.Eq field="state" value="doing" />
          </Luq.And>
        ),
      },
    }
  );

  if (
    data?.interventions &&
    data?.interventions.length > 0
  ) {
    return data?.interventions[0].id;
  } else {
    return null;
  }
};

export default useCurrentIntervention;
