import config from 'config';
import {
  HTTPResponseError,
  checkStatus,
} from './fetchHelpers';

export const fetchPhoto = async (photoRoute: string, filename: string = 'untitled.jpg'): Promise<File> => {
  const token: string | null = localStorage.getItem('session_id');

  const customHeader: HeadersInit = (
    config.AUTH_METHOD === 'token' &&
    token !== null &&
    token !== undefined
  ) ?
    {
      'X-Openerp-Session-Id': token,
    } :
    {};

  const fetchResponse: Response = await fetch(
    `${config.ODOO_HOST}${photoRoute}`,
    {
      method: 'GET',
      /* Comment this line to use session_ids tokens yourself instead of cookies. */
      credentials: config.AUTH_METHOD === 'cookie' ? 'include' : 'omit',
      headers: {
        Accept: 'image/*',
        'Content-Type': 'image/*',
        ...customHeader,
      },
    }
  );

  try {
    checkStatus(fetchResponse);
  } catch (exception) {
    const lErrorBody: string = await (exception as HTTPResponseError).response.text();

    console.error(`[ERROR] <fetchPhoto> Request to ${config.ODOO_HOST}${photoRoute} failed :`, exception);
    console.error(`        Error body: ${lErrorBody}`);

    throw exception;
  }

  const odooRequestResultBody: File = new File(
    [
      await fetchResponse.blob(),
    ],
    filename,
  );

  return odooRequestResultBody;
};
