import React, { useEffect, useMemo, useState } from 'react';
import { IonButton, IonCheckbox, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSearchbar, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { caretDownSharp, chevronDown } from 'ionicons/icons';

import './styles.css';

interface SearchableSelectOptionProps<T> {
  value: T;
  label?: string;
  group?: string;
};

type Option<T> = {
  value: T;
  label?: string;
  group?: string;
};

type SearchableSelectEventChange<T> = {
  value?: T,
  values?: T[],
};

type ChildrenType<T> = React.ReactElement<SearchableSelectOptionProps<T>>;

interface SearchableSelectProps<T> {
  label: string;
  value?: T;
  values?: T[];
  onChange: (event: SearchableSelectEventChange<T>) => void;
  children: ChildrenType<T>[] | ChildrenType<T>;
  multi?: boolean;
  groups?: {
    [key: string]: string;
  };
  searchValue: string;
  searchFunction: (value: string) => void;
  loading?: boolean;
  closeModal?: () => void;
};

const getChildrenArray = <T,>(children: ChildrenType<T>[] | ChildrenType<T>): ChildrenType<T>[] => {
  if (!(children instanceof Array)) {
    return [children];
  }

  return children;
};

const SearchableSelect = <T,>(props: SearchableSelectProps<T>) => {
  const { label, value, values, onChange, children, multi = false, groups = false, searchValue, searchFunction, loading = false, closeModal } = props;

  const [showSelect, setShowSelect] = useState(false);
  const [selected, setSelected] = useState<T[]>(values || (value && [value]) || []);

  useEffect(() => {
    setSelected(values || (value && [value]) || []);
  }, [values, value]);

  const [expanded, setExpanded] = useState<string[]>([]);

  const options: Option<T>[] = getChildrenArray(children).filter(child => child?.props?.value).map(child => child.props);
  const filteredOptions = options.filter(option => option.label && option.label.toUpperCase().includes(searchValue.toUpperCase()));

  const optionsByGroup = groups ?
    Object.keys(groups).reduce((acc, cv: string) => ({
      ...acc,
      [cv]: [],
    }), {} as { [key: string]: Option<T>[] }) :
    { '': [] };

  filteredOptions.forEach(option => {
    if (!option.group || !groups) {
      optionsByGroup[''].push(option);
    } else if (optionsByGroup[option.group] !== undefined) {
      optionsByGroup[option.group].push(option);
    } else {
      optionsByGroup[option.group] = [option];
    }
  });

  const handleExpand = (group: string): void => {
    if (expanded.includes(group)) {
      setExpanded(expanded.filter(n => n !== group));
    } else {
      setExpanded([...expanded, group]);
    }
  };

  const handleSelected = (value: T, checked: boolean): void => {
    if (selected.includes(value) && !checked) {
      setSelected(selected.filter(n => n !== value));
    }
    if (!selected.includes(value) && checked) {
      if (multi) {
        setSelected([...selected, value]);
      } else {
        setSelected([value]);
      }
    }
  };

  const handleValidate = (): void => {
    if (multi && selected.length > 0) {
      onChange({
        values: selected,
      });
      setShowSelect(false);
      if (closeModal !== undefined){
        closeModal();
      }
    } else if (!multi && selected.length === 1) {
      onChange({
        value: selected[0],
      });
      setShowSelect(false);
      if (closeModal !== undefined){
        closeModal();
      }
    } else {
      alert('Erreur: sélection échouée.');
    }
  };

  const selectedLabels = useMemo(() => {
    if (selected.length > 0) {
      return selected.map((select) => filteredOptions.find(elt => elt.value === select)?.label).filter((label) => label !== undefined);
    }
  }, [filteredOptions, selected]);

  return (
    <>
      <IonItem onClick={() => setShowSelect(true)} className="noborder-list-item">
        <IonLabel>{label}</IonLabel>
        <div style={{ width: '19px', height: '19px' }}>
          <IonIcon
            style={{ color: 'black', opacity: 0.33, width: '14px', height: '14px', marginTop: '3px', marginLeft: '3px' }}
            slot="end"
            size="small"
            icon={caretDownSharp}
          />
        </div>
      </IonItem>
      <div className="searchable-select-selection">
        {selectedLabels !== undefined && selectedLabels.length > 0 &&
          <ul>
            {selectedLabels.map((label, i) => <li key={i}>{label}</li>)}
          </ul>
        }
      </div>
      <IonModal isOpen={showSelect}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            value={searchValue}
            onIonChange={e => {
              e.detail.value !== undefined && e.detail.value !== null && searchFunction(e.detail.value);
            }}
            placeholder="Rechercher..."
          />
          {
            loading ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IonSpinner style={{
                  width: '64px',
                  height: '64px',
                }}
                />
              </div>
              :
              <React.Fragment>
                {groups ?
                  Object.keys(optionsByGroup).filter(key => optionsByGroup[key].length > 0).map(key => <React.Fragment key={key}>
                    <div
                      onClick={() => handleExpand(key)}
                      className="expandable-button-item"
                    >
                      <IonText style={{ float: 'left' }}>{groups[key]}</IonText>
                      <IonIcon
                        className={expanded.includes(key) ? 'rotate-180' : 'rotate-0'}
                        style={{ float: 'right' }}
                        icon={chevronDown}
                      />
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    {expanded.includes(key) &&
                      <IonList>
                        {optionsByGroup[key].map((option, i) =>
                          <IonItem key={i} className={i === optionsByGroup[key].length - 1 ? 'noborder-list-item' : ''}>
                            <IonLabel>{option.label}</IonLabel>
                            <IonCheckbox
                              key={`${selected.includes(option.value) ? 't' : 'f'}-${option.value}`}
                              checked={selected.includes(option.value)}
                              onIonChange={e => handleSelected(option.value, e.detail.checked)}
                            />
                          </IonItem>
                        )}
                      </IonList>
                    }
                  </React.Fragment>) :
                  <IonList>
                    {filteredOptions.map((option, i) => <IonItem key={i}>
                      <IonLabel>{option.label}</IonLabel>
                      <IonCheckbox
                        key={`${selected.includes(option.value) ? 't' : 'f'}-${option.value}`}
                        checked={selected.includes(option.value)}
                        onIonChange={e => handleSelected(option.value, e.detail.checked)}
                      />
                    </IonItem>)}
                  </IonList>
                }
              </React.Fragment>
          }
        </IonContent>
        <IonFooter>
          <IonRow>
            <IonCol size="6">
              <IonButton
                onClick={() => setShowSelect(false)}
                expand="full"
              >
                Annuler
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton
                expand="full"
                onClick={handleValidate}
                disabled={selected.length < 1}
              >
                Valider
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default SearchableSelect;
SearchableSelect.Option = <T,>(props: SearchableSelectOptionProps<T>) => null;