import React from 'react';

import {
  IonText,
  IonIcon,
} from '@ionic/react';

import {
  informationCircleOutline,
  alertCircleOutline,
  warningOutline,
  checkmarkOutline,
} from 'ionicons/icons';

import './styles.css';

type AlertType =
  'error' |
  'warning' |
  'info' |
  'success';

interface AlertProps {
  type?: AlertType;
  title?: string;
  message: React.ReactNode;
  secondaryMessage?: React.ReactNode;
  showIcon?: boolean;
}

interface AlertStyle {
  subClassName: AlertType;
  icon: string;
}

type AlertStyleByType = {
  [key in AlertType]: AlertStyle;
}

const Alert: React.FC<AlertProps> = (
  {
    type = 'error',
    title  = 'Erreur',
    message,
    secondaryMessage,
    showIcon = true,
    ...rest
  }
) => {
  const showAlertIcon: boolean = showIcon !== undefined ? showIcon : false;

  const alertContents: AlertStyleByType = {
    'error': {
      subClassName: 'error',
      icon: alertCircleOutline,
    },
    'warning': {
      subClassName: 'warning',
      icon: warningOutline,
    },
    'info': {
      subClassName: 'info',
      icon: informationCircleOutline,
    },
    'success': {
      subClassName: 'success',
      icon: checkmarkOutline,
    },
  };

  return (
    <div
      className={`custom-alert ${alertContents[type].subClassName}`}
      {...rest}
    >
      <IonText>
        <h4>
          {
            showAlertIcon &&
              <IonIcon
                className="icon"
                icon={alertContents[type].icon}
              />
          }
          <span className="text">
            <b>
              {title}
            </b>
          </span>
        </h4>
      </IonText>
      <br />
      <IonText>
        {message}
      </IonText>
      {
        secondaryMessage &&
          <>
            <br />
            <hr className="solid" />
            <IonText>
              {secondaryMessage}
            </IonText>
          </>
      }
    </div>
  );
};

export default Alert;
