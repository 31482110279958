import React from 'react';

import { MaterialFieldsFragment, ProcessingFieldsFragment } from 'apollo/queries/types';

import ProcessingList from 'components/ProcessingList';
import SectionTitle from 'components/SectionTitle';

interface InterventionMaterialListViewProps {
  processingIds: (ProcessingFieldsFragment & { materialId?: MaterialFieldsFragment|null})[],
}

const InterventionMaterialListView: React.FC<InterventionMaterialListViewProps> = ({ processingIds }) => {
  return (
    <>
      <SectionTitle title="Materiels à traiter" />
      <ProcessingList processings={processingIds} />
    </>
  );
};

export default InterventionMaterialListView;
