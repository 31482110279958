import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  businessOutline,
  calendarOutline,
  closeOutline,
  informationCircleOutline,
  mapOutline,
  personCircleOutline,
} from 'ionicons/icons';

import type { InputChangeEventDetail } from '@ionic/core';
import type { MaterialFieldsFragment } from 'apollo/queries/types';
import {
  useCreateMaintenanceInterventionMutation,
  useCreateMaintenanceProcessingMutation,
  useDeleteMaintenanceInterventionMutation,
} from 'apollo/mutations';
import { useCurrentUserQuery } from 'apollo/queries';
import useNotifier from 'utils/notification';
import Alert from 'components/Alert';
import { formatDateForSQL } from 'utils/formatDate';

interface InterventionCreationModalProps {
  open: boolean;
  onClose: () => void;
  material: MaterialFieldsFragment
}

const InterventionCreationModal: React.FC<InterventionCreationModalProps> = (
  {
    open,
    onClose,
    material,
  }
) => {
  const history = useHistory();
  const notify = useNotifier();

  /* This will change & each render to always be up to date */
  const currentDate: Date = new Date();

  const {
    data: userData,
    loading: isLoadingUser,
    error: userDataError,
  } = useCurrentUserQuery();
  const [ createInter ] = useCreateMaintenanceInterventionMutation();
  const [ createProcessing ] = useCreateMaintenanceProcessingMutation();
  const [ deleteInter ] = useDeleteMaintenanceInterventionMutation();

  const onCloseModal: React.MouseEventHandler<HTMLIonButtonElement> = (pEvent) => {
    onClose();
  };

  const onSubmitForm: React.MouseEventHandler<HTMLIonButtonElement> = async (pEvent) => {
    let interID = -1, procID = -1, interName = '';
    try {
      /* Create the intervention */
      const {
        data: createdInter,
      } = await createInter(
        {
          variables: {
            materialId: material.id,
            clientId: material.clientId.id,
            siteId: material.siteId.id,
            zoneId: material.zoneId?.id,
            techId: userData?.getMyData?.id!,
            interventionDate: formatDateForSQL(currentDate),
            origin: 'Technicien sur site',
            name: `[Sur site] ${material.name}`,
          },
          refetchQueries: ['InterventionList']
        }
      );

      interID = createdInter?.createMaintenanceIntervention?.created?.id ?? -1;
      interName = createdInter?.createMaintenanceIntervention?.created?.name ?? '';

      console.log(`[DEBUG] <InterventionCreationModal.onSubmitForm> Created intervention ${interID} :`, createdInter?.createMaintenanceIntervention?.created);
    } catch (pException) {
      console.error(`[ERROR] <InterventionCreationModal.onSubmitForm> createInter failed :`, pException);

      notify(
        {
          position: 'top',
          color: 'danger',
          message: "Erreur lors de la création de l'intervention.",
        },
      );

      return;
    }

    notify(
      {
        position: 'top',
        color: 'success',
        message: `L'intervention ${interName} a bien été créée.`
      }
    );

    history.replace(`/intervention/${interID}`);
    onClose();
  };

  return (
    <IonModal
      isOpen={open}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onCloseModal}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {"Création de l'intervention"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          userDataError ?
            <Alert
              message="Nous n'avons pas réussi a récupérer votre identifiant technicien."
            /> :
            <>
              <IonItem>
                <IonAvatar
                  slot="start"
                  className="list-item-icon"
                >
                  <IonIcon
                    color="primary"
                    icon={calendarOutline}
                    style={{ width: '100%' }}
                    size="large"
                  />
                </IonAvatar>
                <IonLabel>
                  Prévue le
                </IonLabel>
                <IonNote
                  slot="end"
                  color="primary"
                >
                  {`${currentDate.toLocaleDateString('fr-FR')}`}
                  <br />
                  {`à ${currentDate.toLocaleTimeString('fr-FR')}`}
                </IonNote>
              </IonItem>
              <IonItem>
                <IonAvatar
                  slot="start"
                  className="list-item-icon"
                >
                  {
                    !material?.clientId?.image128 ?
                      <IonIcon
                        icon={businessOutline}
                        color="primary"
                        style={{ width: '100%' }}
                        size="large"
                      /> :
                      <IonImg
                        src={`data:image/png;base64,${material?.clientId.image128}`}
                      />
                  }
                </IonAvatar>
                <IonLabel>
                  {'Client'}
                </IonLabel>
                <IonInput
                  readonly
                  required
                  spellcheck
                  type="text"
                  name="client-name"
                  placeholder="Client"
                  slot="end"
                  value={material.clientId.name}
                />
              </IonItem>
              <IonItem>
                <IonAvatar
                  slot="start"
                  className="list-item-icon"
                >
                  <IonIcon
                    color="primary"
                    icon={mapOutline}
                    style={{ width: '100%' }}
                    size="large"
                  />
                </IonAvatar>
                <IonLabel>
                  {'Site'}
                </IonLabel>
                <IonInput
                  readonly
                  required
                  spellcheck
                  type="text"
                  name="site-name"
                  placeholder="Site"
                  slot="end"
                  value={material.siteId.name}
                />
              </IonItem>
              <IonItem>
                <IonAvatar
                  slot="start"
                  className="list-item-icon"
                >
                  <IonIcon
                    color="primary"
                    icon={mapOutline}
                    style={{ width: '100%' }}
                    size="large"
                  />
                </IonAvatar>
                <IonLabel>
                  {'Zone'}
                </IonLabel>
                <IonInput
                  readonly
                  required
                  spellcheck
                  type="text"
                  name="zone-name"
                  placeholder="Zone"
                  slot="end"
                  value={material.zoneId?.name}
                />
              </IonItem>
              {
                isLoadingUser && userData?.getMyData?.name !== undefined ?
                  null :
                  <IonItem>
                    <IonAvatar
                      slot="start"
                      className="list-item-icon"
                    >
                      <IonIcon
                        color="primary"
                        icon={personCircleOutline}
                        style={{ width: '100%' }}
                        size="large"
                      />
                    </IonAvatar>
                    <IonLabel>
                      {'Technicien'}
                    </IonLabel>
                    <IonInput
                      readonly
                      required
                      spellcheck
                      type="text"
                      name="tech-name"
                      placeholder="Technicien"
                      slot="end"
                      value={userData?.getMyData?.name!}
                    />
                  </IonItem>
              }
              <IonButton
                expand="block"
                // type="submit"
                disabled={isLoadingUser}
                onClick={onSubmitForm}
              >
                {"Créer l'intervention"}
              </IonButton>
            </>
        }
      </IonContent>
    </IonModal>
  );
};

export default InterventionCreationModal;
