import React, {
  useLayoutEffect,
  useCallback,
} from 'react';

import Quagga from '@ericblade/quagga2';

import QrCodeReader from './QRReader';
import {
  getMedianOfCodeErrors
} from './ScannerHelpers';

import type {
  QuaggaJSResultCallbackFunction,
  QuaggaJSConfigObject,
  QuaggaJSResultObject,
} from '@ericblade/quagga2/type-definitions/quagga';

import type { ScannerDecoderType } from './ScannerDecoderType';

/* Notes ----------------------------------------------- */
/* This code is inspired from a React usage example from Quagga2's maintainer.
 * This example can be found @ https://github.com/ericblade/quagga2-react-example
 */

Quagga.registerReader('qr_code', QrCodeReader);

interface ScannerProps {
  onDetected: QuaggaJSResultCallbackFunction;
  onProcessed?: QuaggaJSResultCallbackFunction;
  scannerViewRef: React.RefObject<HTMLInputElement>;
  onScannerReady?: (...args: any[]) => any;
  cameraId?: ConstrainDOMString;
  facingMode?: ConstrainDOMString;
  constraints?: {
    width: ConstrainULong;
    height: ConstrainULong;
  },
  locator?: {
    patchSize: string;
    halfSample: boolean;
  };
  numOfWorkers?: number;
  decoders?: ScannerDecoderType[];
  locate?: boolean;
  onShotDetection?: boolean;
}

const defaultConstraints: MediaTrackConstraintSet = {
  width: 480,
  height: 640,
};

const defaultLocatorSettings = {
  patchSize: 'medium',
  halfSample: true,
};

const defaultDecoders: ScannerDecoderType[] = [
  'ean_reader',
  'qr_code',
];

const Scanner: React.FC<ScannerProps> = (
  {
    onDetected,
    onProcessed,
    scannerViewRef,
    onScannerReady,
    cameraId,
    facingMode,
    constraints = defaultConstraints,
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 1,
    decoders = defaultDecoders,
    locate = true,
    onShotDetection = true,
  }
) => {
  /* Configure the Quagga scanner */
  const scannerConfig: QuaggaJSConfigObject = {
    inputStream: {
      type: 'LiveStream',
      constraints: {
        ...constraints,
        ...(cameraId ?
          {
            deviceId: cameraId
          } :
          {
            facingMode
          }
        ),
      },
      target: scannerViewRef.current as Element | string, /* TODO : Why doesn't React.RefObject<HTMLInputElement> work ? */
      singleChannel: false,
    },
    decoder: {
      readers: decoders,
    },
    locate: locate,
    locator: locator,
    numOfWorkers: numOfWorkers,
  };

  const stopScanner = () => {
    // console.log(`[DEBUG] <stopScanner> Stopping Quagga scanner`);
    /* Unregister the event callbacks */
    onProcessed && Quagga.offProcessed(onProcessed);
    Quagga.offDetected(errorCheckCallback);

    /* Stop the Quagga scanner */
    Quagga.stop()
      .then(
        (pResult) => {
          // console.log(`[DEBUG] <stopScanner> Successfully stopped Quagga scanner :`, pResult);
        }
      )
      .catch(
        (pException) => {
          // console.error(`[ERROR] <stopScanner> Failed to stop Quagga scanner :`, pException);
        }
      );
  };

  const errorCheckCallback = useCallback(
    (pResult: QuaggaJSResultObject) => {
      if (!onDetected) {
        return;
      }

      if (pResult.codeResult.format === 'qr_code') {
        onDetected(pResult);
      } else {
        /* Code detection reliability check */
        const lErrorProbability: number = getMedianOfCodeErrors(pResult.codeResult.decodedCodes);
        // if Quagga is at least 75% certain that it read correctly, then accept the code.
        if (lErrorProbability < 0.25) {
          onDetected(pResult);
        }
      }

      if (onShotDetection) {
        stopScanner();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onDetected,
    ],
  );

  useLayoutEffect(
    () => {
      /* Initialize the Quagga scanner with out configuration */
      Quagga.init(
        scannerConfig,
        (pError) => {
          if (pError) {
            return console.error('[ERROR] <Scanner> Error starting Quagga scanner :', pError);
          } else {
            // console.log(`[DEBUG] <Scanner> Starting Quagga scanner`);
          }

          /* Register a callback to the onProcessed event */
          onProcessed && Quagga.onProcessed(onProcessed);
          /* Register a callback to the onDetected event */
          Quagga.onDetected(errorCheckCallback);

          if (scannerViewRef && scannerViewRef.current) {
            /* Start the Quagga scanner */
            Quagga.start();
            onScannerReady && onScannerReady();
          }
        }
      );

      return stopScanner;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
};

export default Scanner;
