export enum InterventionStateKeysEnum {
  unplanned = 'unplanned',
  planned = 'planned',
  doing = 'doing',
  done = 'done',
  closed = 'closed',
  unknown = 'unknown',
  // eslint-disable-next-line camelcase
  partially_closed = 'partially_closed',
};

export const InterventionState = {
  unplanned: 'Nouveau',
  planned: 'Planifiée',
  doing: 'En cours',
  done: 'Réalisée',
  closed: 'Terminée',
  unknown: 'État inconnu',
  // eslint-disable-next-line camelcase
  partially_closed: 'Partiellement réalisé',
};

export const interventionStateFromEnum = (key: InterventionStateKeysEnum): string => {
  return InterventionState[key];
};
