export const getMedian = (arr: number[]) => {
  arr.sort(
    (a: number, b: number) => {
      return (a - b);
    }
  );

  const half = Math.floor(arr.length / 2);

  if (arr.length % 2 === 1) {
    return arr[half];
  }

  return (arr[half - 1] + arr[half]) / 2;
};

export const getMedianOfCodeErrors = (decodedCodes: any[]) => {
  const errors = decodedCodes.filter(
    (x: any) => {
      return x.error !== undefined;
    }
  ).map(
    (x: any) => {
      return x.error;
    }
  );

  const medianOfErrors = getMedian(errors);

  return medianOfErrors;
};
