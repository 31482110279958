import { Nodes, BuiltNode, NodeType } from './types';


type NodeCompiler = (node: BuiltNode, compile: InternalCompilerType) => unknown[];

type NodesCompiler = { [key in NodeType]: NodeCompiler };

type InternalCompilerType = (node: BuiltNode) => unknown[];

const odooForChildren = (operator: string, children: Nodes<BuiltNode>, compile: InternalCompilerType): unknown[] => {
  if (children.length === 1) {
    return compile(children[0]);
  }
  else if (children.length >= 2) {
    const [firstChild, ...rest] = children;

    return [
      operator,
      ...compile(firstChild),
      ...odooForChildren(operator, rest, compile),
    ];
  }

  return [];
};

const translateOdooFieldsRegex = /([A-Z])/g;
const translateOdooFields = (field: string) => field.replace(translateOdooFieldsRegex, '_$1').toLowerCase();

const nodesCompiler: NodesCompiler = {
  And: (node, compile) => odooForChildren('&', node.children as Nodes<BuiltNode>, compile),
  Or: (node, compile) => odooForChildren('|', node.children as Nodes<BuiltNode>, compile),
  Not: (node, compile) => ['!', ...compile((node.children as Nodes<BuiltNode>)[0])],
  Defined: (node) => [[translateOdooFields(node.props.field as string), '!=', false]],
  Undefined: (node) => [[translateOdooFields(node.props.field as string), '=', false]],
  Eq: (node) => [[translateOdooFields(node.props.field as string), '=', node.props.value]],
  Neq: (node) => [[translateOdooFields(node.props.field as string), '!=', node.props.value]],
  Gt: (node) => [[translateOdooFields(node.props.field as string), '>', node.props.value]],
  Gte: (node) => [[translateOdooFields(node.props.field as string), '>=', node.props.value]],
  Lt: (node) => [[translateOdooFields(node.props.field as string), '<', node.props.value]],
  Lte: (node) => [[translateOdooFields(node.props.field as string), '<=', node.props.value]],
  Like: (node) => [[translateOdooFields(node.props.field as string), `=${node.props.caseInsensitive ? 'i' : ''}like`, node.props.pattern]],
  In: (node) => [[translateOdooFields(node.props.field as string), 'in', node.props.values]],
  NotIn: (node) => [[translateOdooFields(node.props.field as string), 'not in', node.props.values]],
};

const odooCompiler = (builtTree: BuiltNode): string => {
  const compile: InternalCompilerType = (node: BuiltNode) => {
    return nodesCompiler[node.type](node, compile);
  };

  return JSON.stringify(compile(builtTree));
};

export default odooCompiler;