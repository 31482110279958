import React from 'react';
import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
} from '@ionic/react';
import { formatDateStrForMobile } from 'utils/formatDate';

import {
  MaterialFieldsFragment,
} from 'apollo/queries/types';

import './styles.css';
import { barcodeOutline, bookOutline, calendarNumberOutline, chatbubbleEllipsesOutline, cubeOutline, fingerPrintOutline } from 'ionicons/icons';

interface MaterialInfoViewProps {
  material: MaterialFieldsFragment;
}

const MaterialInfoView: React.FC<MaterialInfoViewProps> = ({ material }) => {
  return (
    <div className="info-list">
      <IonItem>
        <IonAvatar slot="start" className="list-item-icon">
          <IonIcon
            icon={cubeOutline}
            color="primary"
            style={{ width: '100%' }}
            size="large"
          />
        </IonAvatar>
        <IonLabel>
          Type de matériel
        </IonLabel>
        <IonNote slot="end" color="primary">
          {material.materialType?.name}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start" className="list-item-icon">
          <IonIcon color="primary" icon={fingerPrintOutline} style={{ width: '100%' }} size="large" />
        </IonAvatar>
        <IonLabel>
          Numéro de série
        </IonLabel>
        <IonNote slot="end" color="primary">
          {material.number || 'N/A'}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start" className="list-item-icon">
          <IonIcon color="primary" icon={barcodeOutline} style={{ width: '100%' }} size="large" />
        </IonAvatar>
        <IonLabel>
        Code-barres
        </IonLabel>
        <IonNote slot="end" color="primary">
          {material.barcode || 'N/A'}
        </IonNote>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start" className="list-item-icon">
          <IonIcon color="primary" icon={calendarNumberOutline} style={{ width: '100%' }} size="large" />
        </IonAvatar>
        <IonLabel>
          Date d'installation
        </IonLabel>
        <IonNote slot="end" color="primary">
          {
            material.installDate ?
              (
                new Date(
                  formatDateStrForMobile(
                    material.installDate
                  )
                )
              ).toLocaleDateString('fr-FR') :
              'N/A'
          }
        </IonNote>
      </IonItem>
      {
        material.description &&
          <>
            <IonItem className="noborder-list-item">
              <IonAvatar slot="start" className="list-item-icon">
                <IonIcon color="primary" icon={bookOutline} style={{ width: '100%' }} size="large" />
              </IonAvatar>
              <IonLabel>
                Description
              </IonLabel>
            </IonItem>
            <div className="bordered-text-block">
              <IonText>
                {material.description}
              </IonText>
            </div>
          </>
      }
      {
        material.comment &&
          <>
            <IonItem className="noborder-list-item">
              <IonAvatar slot="start" className="list-item-icon">
                <IonIcon color="primary" icon={chatbubbleEllipsesOutline} style={{ width: '100%' }} size="large" />
              </IonAvatar>
              <IonLabel>
                Commentaires
              </IonLabel>
            </IonItem>
            <div className="bordered-text-block">
              <IonText>
                {material.comment || 'N/A'}
              </IonText>
            </div>
          </>
      }
    </div>
  );
};

export default MaterialInfoView;
