import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { IonReactRouter } from '@ionic/react-router';
import { NotificationsProvider } from 'utils/notification';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';

ReactDOM.render(
  <React.StrictMode>
    <IonReactRouter>
      <ApolloProvider client={client}>
        <NotificationsProvider>
          <App />
        </NotificationsProvider>
      </ApolloProvider>
    </IonReactRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
  {
    onUpdate: registration => {
      alert('Nouvelle version disponible !');

      if (registration && registration.waiting) {
        registration.waiting.postMessage(
          {
            type: 'SKIP_WAITING',
          },
        );
      }

      window.location.reload();
    },
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
