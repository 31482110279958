import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';

import {
  callOutline,
  phonePortraitOutline,
  mailOutline,
} from 'ionicons/icons';

import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';
import Spinner from 'components/Spinner';

import { useSiteQuery } from 'apollo/queries';

import './styles.css';

interface SitePageProps
  extends RouteComponentProps<{
    siteId: string;
  }> {}

const SitePage: React.FC<SitePageProps> = ({ match }) => {
  const siteID: number = Number(match.params.siteId);

  const {
    loading: loadingSite,
    error: errorSite,
    data: dataSite,
    refetch: refetchSite,
  } = useSiteQuery({
    variables: {
      id: siteID,
    },
  });

  if (loadingSite) {
    return (
      <Spinner />
    );
  }

  const siteInformation = !errorSite ? dataSite?.getMaintenanceSite : null;

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Site"
      onRefresh={
        async () => {
          await refetchSite(
            {
              id: siteID,
            },
          );
        }
      }
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {/* <img
              alt={`Logo de l'entreprise ${siteInformation?.name}`}
              // src={`data:image/png;base64, ${siteInformation?.image256}`}
            />
            <br /> */}
            {siteInformation?.name}
            <br />
            <IonButton disabled>
              {siteInformation?.clientId?.name}
            </IonButton>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          style={
            {
              textAlign: 'center'
            }
          }
        >
          {
            siteInformation?.street &&
                <>
                  {siteInformation?.street}
                  <br />
                </>
          }
          {
            siteInformation?.street2 &&
                <>
                  {siteInformation?.street2}
                  <br />
                </>
          }
          {
            siteInformation?.zip &&
                <>
                  {`${siteInformation?.zip} `}
                </>
          }
          {
            siteInformation?.city &&
                <>
                  {siteInformation?.city}
                  <br />
                </>
          }
          <br />
          {
            siteInformation?.phone &&
              <IonButton
                expand="block"
                href={`tel:${siteInformation?.phone}`}
              >
                <IonIcon icon={callOutline} />
                &nbsp;
                {siteInformation?.phone}
              </IonButton>
          }
          {
            siteInformation?.mobile &&
              <IonButton
                expand="block"
                href={`tel:${siteInformation?.mobile}`}
              >
                <IonIcon icon={phonePortraitOutline} />
                &nbsp;
                {siteInformation?.mobile}
              </IonButton>
          }
          {
            siteInformation?.email &&
              <IonButton
                expand="block"
                href={`mailto:${siteInformation?.email}`}
              >
                <IonIcon icon={mailOutline} />
                &nbsp;
                {siteInformation?.email}
              </IonButton>
          }
          {/* <hr className="solid" /> */}
        </IonCardContent>
        {
          siteInformation?.hasDeliveryAddress &&
            <IonCardContent>
              <IonCardSubtitle>
                Adresse de livraison
              </IonCardSubtitle>
              {
                siteInformation?.deliveryStreet &&
                  <>
                    {siteInformation?.deliveryStreet}
                    <br />
                  </>
              }
              {
                siteInformation?.deliveryStreet2 &&
                  <>
                    {siteInformation?.deliveryStreet2}
                    <br />
                  </>
              }
              {
                siteInformation?.deliveryZip &&
                  <>
                    {`${siteInformation?.deliveryZip} `}
                  </>
              }
              {
                siteInformation?.city &&
                  <>
                    {siteInformation?.deliveryCity}
                    <br />
                  </>
              }
            </IonCardContent>
        }
        {
          siteInformation?.hasInvoicingAddress &&
            <IonCardContent>
              <IonCardSubtitle>
                Adresse de facturation
              </IonCardSubtitle>
              {
                siteInformation?.invoicingStreet &&
                  <>
                    {siteInformation?.invoicingStreet}
                    <br />
                  </>
              }
              {
                siteInformation?.invoicingStreet2 &&
                  <>
                    {siteInformation?.invoicingStreet2}
                    <br />
                  </>
              }
              {
                siteInformation?.invoicingZip &&
                  <>
                    {`${siteInformation?.invoicingZip} `}
                  </>
              }
              {
                siteInformation?.city &&
                  <>
                    {siteInformation?.invoicingCity}
                    <br />
                  </>
              }
            </IonCardContent>
        }
        <IonCardContent>
          <IonCardSubtitle>
            Zones
          </IonCardSubtitle>
          <IonList>
            {
              siteInformation?.zoneIds.map(
                (pZone) => {
                  /* pZone is a { key;value } object */
                  return (
                    <IonItem
                      key={pZone.id}
                      routerLink={`/zone/${pZone.id}`}
                    >
                      <IonLabel>
                        {pZone.name}
                      </IonLabel>
                    </IonItem>
                  );
                }
              )
            }
          </IonList>
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Materiels
          </IonCardSubtitle>
          <IonList>
            {
              siteInformation?.materialIds.map(
                (pMaterial) => {
                  /* pSite is a { key;value } object */
                  return (
                    <IonItem
                      key={pMaterial.id}
                      routerLink={`/material/${pMaterial.id}`}
                    >
                      <IonLabel>
                        {pMaterial.name}
                      </IonLabel>
                    </IonItem>
                  );
                }
              )
            }
          </IonList>
        </IonCardContent>
        <IonCardContent>
          <IonCardSubtitle>
            Contact
          </IonCardSubtitle>
          <IonList>
            <IonItem
              routerLink={`/contact/${siteInformation?.partnerId?.id}`}
            >
              <IonLabel>
                {siteInformation?.partnerId?.name}
              </IonLabel>
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
    </PageLayout>
  );
};

export default withAuthentication(SitePage);
