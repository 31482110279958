import React from 'react';

import {
  IonButton,
  IonIcon,
} from '@ionic/react';
import {
  qrCodeOutline,
} from 'ionicons/icons';

import type { QuaggaJSResultObject } from '@ericblade/quagga2/type-definitions/quagga';

interface ScanButtonDisplayProps {
  loadingInterventions: boolean;
  interventions?: any[]; /* TODO : We have to find a better type than this */
  scanning: boolean;
  latestScanResult?: QuaggaJSResultObject;
  onScanButtonClick: React.MouseEventHandler<HTMLIonButtonElement>;
}

const ScanButtonDisplay: React.FC<ScanButtonDisplayProps> = (
  {
    loadingInterventions,
    interventions,
    scanning,
    latestScanResult,
    onScanButtonClick,
  }
) => {

  /* Case : Loading */
  /* Case : scanning */
  /* Case : First render */
  if (
    loadingInterventions ||
    scanning ||
    (!interventions && !latestScanResult)
  ) {
    return null;
  }

  /* Case : Intervention not found */
  /* Case : Back-end error */
  /* Case : Got more than one result */
  return (
    <IonButton
      onClick={onScanButtonClick}
      expand="full"
    >
      <IonIcon icon={qrCodeOutline} />
      &nbsp;
      Réessayer
    </IonButton>
  );
};

export default ScanButtonDisplay;
