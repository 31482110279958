import React, {
  useState,
  useRef,
} from 'react';

import { useSearchInterventionsFullLazyQuery } from 'apollo/queries';

import { InterventionStateKeysEnum } from 'utils/InterventionState';
import useNotifier from 'utils/notification';
import compile from 'utils/domain';
import * as Luq from 'luq';

import type {
  QuaggaJSResultObject,
} from '@ericblade/quagga2/type-definitions/quagga';

import PageLayout from 'layouts/PageLayout';

import Scanner from 'components/Scanner';
import withAuthentication from 'components/AuthenticatedRoute';
import ScanButtonDisplay from './ScanButtonDisplay';
import ScanResultDisplay from './ScanResultDisplay';

import type { ScannerDecoderType } from 'components/Scanner/ScannerDecoderType';
import type { InterventionFieldsFullFragment } from 'apollo/queries/types';

const decoders: ScannerDecoderType[] = [
  'ean_reader',
  'qr_code',
  'code_128_reader',
  'ean_8_reader',
  'code_39_reader',
  'code_39_vin_reader',
  'codabar_reader',
  'upc_reader',
  'upc_e_reader',
  'i2of5_reader',
  '2of5_reader',
  'code_93_reader',
  'code_32_reader',
];

const ScannerPage: React.FC = () => {
  const notify = useNotifier();

  const [ scanning, setScanning ] = useState<boolean>(true);
  const [ renderScanner, setRenderScanner ] = useState<boolean>(false);
  const [ latestScanResult, setLatestScanResult ] = useState<QuaggaJSResultObject>();
  const scannerViewRef = useRef<HTMLInputElement>(null);

  const [
    getInterventions,
    {
      loading: loadingInterventions,
      data: dataInterventions,
      error: errorInterventions,
      refetch: refetchInterventions,
    },
  ] = useSearchInterventionsFullLazyQuery({
    variables: {
      limit: 2,
      order: 'start_date DESC'
    }
  });

  /* Div/Canva/Scanner size calculations */
  const pageWidth: number = window.innerWidth;
  const pageHeight: number = window.innerHeight;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const portraitPage: boolean = pageWidth < pageHeight;
  // const ratio: number = portraitPage ? 16/9 : 9/16;

  const cameraViewWidth: number = window.innerWidth;
  // const cameraViewHeight: number = Math.ceil(cameraViewWidth * ratio);

  const onScanButtonClick: React.MouseEventHandler<HTMLIonButtonElement> = () => {
    setScanning(!scanning);
    setLatestScanResult(undefined);
  };

  const onDetectedCallback = (pResult: QuaggaJSResultObject) => {
    if (!scanning) return;

    console.log(`[DEBUG] <ScannerPage> Scanner.onDetected pResult :`, pResult);
    setLatestScanResult(pResult);
    setScanning(false);

    /* Notify the user of the result */
    notify(
      {
        position: 'top',
        color: 'success',
        message: `Scan réussi : ${pResult.codeResult.code}`
      }
    );

    pResult.codeResult.code && getInterventions(
      {
        variables: {
          domain: compile(
            <Luq.And>
              <Luq.Eq
                field="processingIds.materialId.barcode"
                value={pResult.codeResult.code}
              />
              <Luq.In
                field="state"
                values={
                  [
                    InterventionStateKeysEnum.closed,
                    InterventionStateKeysEnum.partially_closed,
                    InterventionStateKeysEnum.planned,
                  ]
                }
              />
            </Luq.And>
          ),
        },
      }
    );
  };

  const interventions: InterventionFieldsFullFragment[] = dataInterventions?.interventions ?? [];

  // if (interventions && interventions.length === 1) {
  //   /* The intervention was found */
  //   return (
  //     <Redirect to={`/intervention/${interventions[0].id}`} />
  //   );
  // }

  /* Without this mechanism, the scanner never starts */
  renderScanner === false && setTimeout(
    () => {
      setRenderScanner(true);
      setLatestScanResult(undefined);
    },
    100,
  );

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Scanner"
      onRefresh={
        async () => {
          if (refetchInterventions !== undefined) {
            await refetchInterventions(
              {
                domain: compile(
                  <Luq.And>
                    <Luq.Eq
                      field="id"
                      value={interventions?.[0].id}
                    />
                    <Luq.In
                      field="state"
                      values={
                        [
                          InterventionStateKeysEnum.closed,
                          InterventionStateKeysEnum.partially_closed,
                          InterventionStateKeysEnum.planned,
                        ]
                      }
                    />
                  </Luq.And>
                )
              }
            );
          }
        }
      }
      footer={
        <ScanButtonDisplay
          loadingInterventions={loadingInterventions}
          interventions={interventions}
          scanning={scanning}
          latestScanResult={latestScanResult}
          onScanButtonClick={onScanButtonClick}
        />
      }
    >
      {
        latestScanResult &&
          <ScanResultDisplay
            loadingInterventions={loadingInterventions || scanning}
            interventions={interventions}
            latestScanResult={latestScanResult}
            errorInterventions={errorInterventions}
          />
      }
      {
        scanning ?
          <div
            ref={scannerViewRef}
            style={
              {
                position: 'relative',
                width: '100%',
                // border: '3px solid red'
                margin: '10px'
              }
            }
          >
            <video
              style={
                {
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  width: cameraViewWidth - 20,
                  // height: cameraViewHeight,
                  border: '3px solid orange',
                }
              }
              width={cameraViewWidth - 20}
              // height={cameraViewHeight}
            />
            {/* <canvas
              className="drawingBuffer"
              style={
                {
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  // height: '100%',
                  width: cameraViewWidth - 20,
                  border: '3px solid green',
                }
              }
              width={cameraViewWidth - 20}
            /> */}
            {
              renderScanner &&
                <Scanner
                  scannerViewRef={scannerViewRef}
                  onDetected={onDetectedCallback}
                  decoders={decoders}
                />
            }
          </div> :
          <div ref={scannerViewRef} />
      }
    </PageLayout>
  );
};

export default withAuthentication(ScannerPage);
