import React, { useState } from 'react';
import { IonItemGroup, IonSearchbar } from '@ionic/react';

import { useInterventionListQuery } from 'apollo/queries';

import useUserId from 'utils/userId';
import compile from 'utils/domain';
import * as Luq from 'luq';

import PageLayout from 'layouts/PageLayout';

import Spinner from 'components/Spinner';
import withAuthentication from 'components/AuthenticatedRoute';
import InterventionList from 'components/InterventionList';
import Alert from 'components/Alert';
import QRBCScannerButton from 'components/QRBCScannerButton';

import './styles.css';
import { useCaseInsensitiveSearch } from 'utils/search';


const getTodayLimits = () => {
  const todayEarlyLimit = new Date();
  todayEarlyLimit.setUTCHours(0, 0, 0, 0);

  const todayLateLimit = new Date();
  todayLateLimit.setUTCHours(0, 0, 0, 0);
  todayLateLimit.setUTCDate(todayLateLimit.getUTCDate() + 1);

  return [todayEarlyLimit, todayLateLimit];
};

const InterventionListPage: React.FC = () => {
  const userId = useUserId();
  const [todayEarlyLimit, todayLateLimit] = getTodayLimits();
  const [searchInput, setSearchInput] = useState('');

  const currentFilterQuery = (
    <Luq.And>
      <Luq.Eq field="techId" value={userId} />
      <Luq.Eq field="state" value="doing" />
    </Luq.And>
  );

  const todayFilterQuery = (
    <Luq.And>
      <Luq.Lt field="interventionDate" value={todayLateLimit} />
      <Luq.Eq field="techId" value={userId} />
      <Luq.In field="state" values={['planned', 'partially_closed']} />
    </Luq.And>
  );

  const otherFilterQuery = (
    <Luq.And>
      <Luq.Eq field="techId" value={userId} />
      <Luq.Gt field="interventionDate" value={todayLateLimit} />
      <Luq.In field="state" values={['planned', 'partially_closed']} />
    </Luq.And>
  );

  const doneTodayFilterQuery = (
    <Luq.And>
      <Luq.Gt field="interventionDate" value={todayEarlyLimit} />
      <Luq.Eq field="techId" value={userId} />
      <Luq.In field="state" values={['done', 'closed']} />
    </Luq.And>
  );

  const {
    loading: loadingInterventions,
    error: errorInterventions,
    data: dataInterventions,
    refetch: refetchInterventions,
  } = useInterventionListQuery({
    variables: {
      currentFilter: compile(currentFilterQuery),
      todayFilter: compile(todayFilterQuery),
      otherFilter: compile(otherFilterQuery),
      doneTodayFilter: compile(doneTodayFilterQuery),
    },
    fetchPolicy: 'network-only',
  });

  let todayInterventionsFiltered = useCaseInsensitiveSearch(dataInterventions?.todayInterventions || [], o => o, searchInput, o => [o.clientId.name || '']);
  const otherInterventionsFiltered = useCaseInsensitiveSearch(dataInterventions?.otherInterventions || [], o => o, searchInput, o => [o.clientId.name || '']);
  const doneTodayInterventionsFiltered = useCaseInsensitiveSearch(dataInterventions?.doneTodayInterventions || [], o => o, searchInput, o => [o.clientId.name || '']);

  if (loadingInterventions) {
    return (
      <Spinner />
    );
  }

  if (errorInterventions || dataInterventions?.todayInterventions === undefined || dataInterventions?.otherInterventions === undefined) {
    return (
      <Alert message={errorInterventions?.message || 'Les interventions sont vides.'} />
    );
  }

  if (dataInterventions.currentIntervention.length > 1) {
    return (
      <Alert message={`Plusieurs interventions sont en cours. Ce n'est pas normal, veuillez contacter un administrateur.`} />
    );
  }

  if (dataInterventions.currentIntervention.length > 0) {
    /* Remove this intervention from the "today" list */
    todayInterventionsFiltered = todayInterventionsFiltered.filter(
      (elmt, index) => {
        return elmt.id !== dataInterventions.currentIntervention[0].id;
      }
    );
  }

  return (
    <PageLayout
      menu
      title="Interventions"
      footer={<QRBCScannerButton isInterOngoing={dataInterventions.currentIntervention.length > 0} />}
      onRefresh={
        async () => {
          await refetchInterventions(
            {
              currentFilter: compile(currentFilterQuery),
              todayFilter: compile(todayFilterQuery),
              otherFilter: compile(otherFilterQuery),
            },
          );
        }
      }
    >
      <IonSearchbar
        placeholder="Rechercher"
        value={searchInput}
        onIonChange={(event) => setSearchInput(event.detail.value || '')}
      />
      <IonItemGroup>
        {
          dataInterventions.currentIntervention.length > 0 &&
            <InterventionList
              title="En cours"
              interventions={dataInterventions?.currentIntervention || []}
            />
        }
        <InterventionList
          title="Aujourd'hui"
          interventions={todayInterventionsFiltered}
        />
        <InterventionList
          title="Plus tard"
          interventions={otherInterventionsFiltered}
        />
        <InterventionList
          title="Terminées"
          interventions={doneTodayInterventionsFiltered}
        />
      </IonItemGroup>
    </PageLayout>
  );
};

export default withAuthentication(InterventionListPage);
