import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonList, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { useCreateStockMoveMutation } from 'apollo/mutations';
import { useProcessingStockZonesQuery, useSearchMaintenanceProductQuery } from 'apollo/queries';
import { MutationCreateMaintenanceStockMoveArgs } from 'apollo/types';
import ScannerModal from 'components/ScannerModal';
import SearchableSelect from 'components/SearchableSelect';
import PageLayout from 'layouts/PageLayout';
import { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import useNotifier from 'utils/notification';
import useUserId from 'utils/userId';
import * as Luq from 'luq';
import compile from 'utils/domain';
import SearchableZoneSelect from 'components/SearchableZoneSelect';

interface CreateStockMoveProps
  extends RouteComponentProps<{
    processingId: string;
  }> { }

const SEARCH_LIMIT = 100;


const CreateStockMove: React.FC<CreateStockMoveProps> = ({ match }) => {
  const processingId = Number(match.params.processingId);
  const [
    newStockMove,
    setNewStockMove,
  ] = useState<MutationCreateMaintenanceStockMoveArgs>({
    articleId: -1,
    originId: -1,
    destinationId: -1,
    quantity: 0,
    processingId,
  });
  const [scannerOpen, setScannerOpen] = useState(false);
  const history = useHistory();

  const userId = useUserId();
  const { loading, error, data } = useProcessingStockZonesQuery({
    variables: {
      processingId,
    },
  });

  const [searchProductsValue, setSearchProductsValue] = useState<string>('');
  // const [productOffset, setProductOffset] = useState<number>(0);

  const { data: productsData, loading: productsLoading, error: productsError } = useSearchMaintenanceProductQuery({
    variables: {
      filter: searchProductsValue !== '' ?
        compile(
          <Luq.Like field="name" pattern={`%${searchProductsValue}%`} caseInsensitive />
        )
        :
        undefined
      ,
      // offset: productOffset,
      limit: SEARCH_LIMIT,
      order: 'name asc',
    }
  });

  const notify = useNotifier();

  const [createStockMove] = useCreateStockMoveMutation({
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: 'MaintenanceProcessing',
          id: processingId,
        }),
        fields: {
          moveIds(oldMoveIds = []) {
            return [
              ...oldMoveIds,
              {
                __ref: cache.identify({
                  __typename: 'MaintenanceStockMove',
                  id: data?.createMaintenanceStockMove?.created?.id
                }),
              },
            ];
          }
        },
      });
    }
  });

  const siteZoneId = !loading && !error ? data?.getMaintenanceProcessing?.siteId?.stockZoneId?.id : null;
  const materialZoneId = !loading && !error ? data?.getMaintenanceProcessing?.materialId?.stockZoneId?.id : null;
  const allArticles = (!loading && !error && data?.searchMaintenanceProduct) || [];

  const isValid = (stockMove: MutationCreateMaintenanceStockMoveArgs): boolean => {
    return (
      stockMove.articleId &&
        stockMove.articleId > 0 &&
        stockMove.originId &&
        stockMove.originId > 0 &&
        stockMove.destinationId &&
        stockMove.destinationId > 0 &&
        stockMove.processingId &&
        stockMove.processingId > 0 &&
        stockMove.quantity &&
        stockMove.quantity > 0 &&
        userId ?
        true :
        false
    );
  };

  const handleSave = (): void => {
    if (isValid(newStockMove)) {
      createStockMove({
        /* @ts-ignore: Disable typescript because we know its valid but it cannot detect it ... what a shame. */
        variables: {
          ...newStockMove
        },
        refetchQueries: ['Processing'],
      }).then(() => history.goBack());
    }
  };

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      footer={
        <IonRow>
          <IonCol size="6">
            <IonButton
              expand="full"
              onClick={() => setScannerOpen(true)}
            >
              Scanner l'article
            </IonButton>
            <ScannerModal
              isOpen={scannerOpen}
              onDetected={code => {
                const scannedArticle = allArticles.find(article => article.barcode === code);
                if (scannedArticle) {
                  notify({
                    position: 'top',
                    color: 'success',
                    message: `Article reconnu.`,
                  });
                  setNewStockMove({
                    ...newStockMove,
                    articleId: scannedArticle.id,
                  });
                } else {
                  notify({
                    position: 'top',
                    color: 'danger',
                    message: `Cet article n'est pas reconnu. Lu : ${code}`,
                  });
                }
                setScannerOpen(false);
              }}
              onClose={() => setScannerOpen(false)}
            />
          </IonCol>
          <IonCol size="6">
            <IonButton
              expand="full"
              disabled={!isValid(newStockMove)}
              onClick={handleSave}
            >
              Valider
            </IonButton>
          </IonCol>
        </IonRow>
      }
    >
      <IonList>
        <IonItem>
          <IonLabel>Source</IonLabel>
          <IonSelect
            value={
              newStockMove.originId === -1 ?
                -1 :
                [siteZoneId, materialZoneId].includes(newStockMove.originId) ? newStockMove.originId : 0
            }
            onIonChange={e => setNewStockMove({
              ...newStockMove,
              originId: e.detail.value,
            })}
          >
            <IonSelectOption value={siteZoneId}>Site</IonSelectOption>
            <IonSelectOption value={materialZoneId}>Matériel</IonSelectOption>
            <IonSelectOption value={0}>Autre</IonSelectOption>
          </IonSelect>
        </IonItem>
        {
          newStockMove.originId !== -1 &&
          ![siteZoneId, materialZoneId].includes(newStockMove.originId) &&
          <SearchableZoneSelect
            newStockMove={newStockMove}
            setNewStockMove={(value: number) => setNewStockMove({
              ...newStockMove,
              originId: value,
            })
            }
            keyChanged="originId"
            label="Préciser la source"
          />
        }
        <IonItem>
          <IonLabel>Destination</IonLabel>
          <IonSelect
            value={
              newStockMove.destinationId === -1 ?
                -1 :
                [siteZoneId, materialZoneId].includes(newStockMove.destinationId) ? newStockMove.destinationId : 0
            }
            onIonChange={e => setNewStockMove({
              ...newStockMove,
              destinationId: e.detail.value,
            })}
          >
            <IonSelectOption value={siteZoneId}>Site</IonSelectOption>
            <IonSelectOption value={materialZoneId}>Matériel</IonSelectOption>
            <IonSelectOption value={0}>Autre</IonSelectOption>
          </IonSelect>
        </IonItem>
        {
          newStockMove.destinationId !== -1 &&
          ![siteZoneId, materialZoneId].includes(newStockMove.destinationId) &&
          <SearchableZoneSelect
            newStockMove={newStockMove}
            keyChanged="destinationId"
            setNewStockMove={(value: number) => setNewStockMove({
              ...newStockMove,
              destinationId: value,
            })
            }
            label="Préciser la destination"
          />
        }
        <SearchableSelect
          label="Article"
          value={newStockMove.articleId !== null && newStockMove.articleId !== undefined && newStockMove.articleId > 0 ? newStockMove.articleId : null}
          onChange={event => setNewStockMove({
            ...newStockMove,
            articleId: event.value || -1,
          })}
          searchValue={searchProductsValue}
          searchFunction={setSearchProductsValue}
          loading={productsLoading}
        >
          {(productsData?.searchMaintenanceProduct || []).map(article =>
            <SearchableSelect.Option
              value={article.id}
              label={article.name || ''}
              key={article.id}
            />
          )}
        </SearchableSelect>
        <IonItem>
          <IonLabel>Quantité</IonLabel>
          <IonInput
            style={{ textAlign: 'right' }}
            value={newStockMove.quantity || ''}
            onIonChange={e => setNewStockMove({
              ...newStockMove,
              quantity: Number(e.detail.value),
            })}
            inputMode="numeric"
            type="number"
          />
        </IonItem>
      </IonList>
    </PageLayout >
  );
};

export default CreateStockMove;