import {
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
  IonText
} from '@ionic/react';
import { MutationCreateMaintenanceOperationArgs } from 'apollo/types';
import { useCreateOperationMutation } from 'apollo/mutations';
import { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import PageLayout from 'layouts/PageLayout';
import useUserId from 'utils/userId';

import withAuthentication from 'components/AuthenticatedRoute';

interface CreateOperationProps
  extends RouteComponentProps<{
    processingId: string;
  }> {}

const CreateOperation: React.FC<CreateOperationProps> = ({ match }) => {
  const processingId = Number(match.params.processingId);
  const [
    newOperation,
    setNewOperation,
  ] = useState<MutationCreateMaintenanceOperationArgs>({
    comment: '',
    name: '',
    processingId,
  });

  const userId = useUserId();
  const [createOperation] = useCreateOperationMutation({
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: 'MaintenanceProcessing',
          id: processingId,
        }),
        fields: {
          operationIds(oldOperationIds = []) {
            return [
              ...oldOperationIds, 
              {
                __ref: cache.identify({
                  __typename: 'MaintenanceOperation',
                  id: data?.createMaintenanceOperation?.created?.id
                })
              },
            ];
          }
        },
      });
    },
  });
  const history = useHistory();

  const handleChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | Number;
  }) => {
    setNewOperation({
      ...newOperation,
      [key]: value,
    });
  };

  const isValidOperation = newOperation.name !== '';

  const handleSubmit = () => {
    const { processingId, name } = newOperation;
    if (userId && isValidOperation && processingId && name !== undefined && name !== null) {
      createOperation({
        variables: {
          ...newOperation,
          processingId, 
          name, 
          techId: userId,
        },
        optimisticResponse: {
          createMaintenanceOperation: {
            created: {
              __typename: 'MaintenanceOperation',
              id: 0,
              ...newOperation,
              name,
            },
          },
        },
      });
      history.goBack();
    }
  };

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      footer={
        <IonButton
          disabled={!isValidOperation}
          expand="full"
          onClick={handleSubmit}
        >Valider
        </IonButton>
      }
    >
      <IonItem>
        <IonInput
          value={newOperation.name}
          required
          placeholder="Titre"
          onIonChange={e =>
            handleChange({ key: 'name', value: e.detail.value || '' })
          }
        ></IonInput>
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea
          value={newOperation.comment}
          placeholder="Description"
          onIonChange={e =>
            handleChange({ key: 'comment', value: e.detail.value || '' })
          }
        ></IonTextarea>
      </div>
    </PageLayout>
  );
};

export default withAuthentication(CreateOperation);
