import { useSearchMaintenanceMaterialQuery, useSearchMaintenanceSiteQuery, useSearchMaintenanceStockZoneQuery } from 'apollo/queries';
import { useMemo, useState } from 'react';
import * as Luq from 'luq';
import compile from 'utils/domain';
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { caretDownSharp } from 'ionicons/icons';
import { MutationCreateMaintenanceStockMoveArgs } from 'apollo/types';
import SearchableSelect from 'components/SearchableSelect';

import './styles.css';

const SEARCH_LIMIT = 100;

interface SearchableZoneSelectProps {
  newStockMove: MutationCreateMaintenanceStockMoveArgs;
  setNewStockMove: (value: number) => void;
  keyChanged: 'destinationId' | 'originId';
  label: string;
}

const SearchableZoneSelect: React.FC<SearchableZoneSelectProps> = ({ newStockMove, setNewStockMove, keyChanged, label }) => {
  const [searchSiteValue, setSearchSiteValue] = useState<string>('');
  const [searchZoneValue, setSearchZoneValue] = useState<string>('');
  const [searchMaterialValue, setSearchMaterialValue] = useState<string>('');
  const [searchManualValue, setSearchManualValue] = useState<string>('');
  const [showSelect, setShowSelect] = useState<boolean>(false);

  const { data: sitesData, loading: sitesLoading, error: sitesError } = useSearchMaintenanceSiteQuery({
    variables: {
      filter: searchSiteValue !== '' ?
        compile(
          <Luq.Like field="name" pattern={`%${searchSiteValue}%`} caseInsensitive />
        )
        :
        undefined
      ,
      limit: SEARCH_LIMIT,
    }
  });

  const { data: stockZonesData, loading: stockZonesLoading, error: stockZonesError } = useSearchMaintenanceStockZoneQuery({
    variables: {
      filter: searchZoneValue !== '' ?
        compile(
          <Luq.And>
            <Luq.Eq field="type" value={'zone'} />
            <Luq.Like field="name" pattern={`%${searchZoneValue}%`} caseInsensitive />
          </Luq.And>
        )
        :
        compile(
          <Luq.Eq field="type" value={'zone'} />
        )
      ,
      limit: SEARCH_LIMIT,
    }
  });

  const { data: materialsData, loading: materialsLoading, error: materialsError } = useSearchMaintenanceMaterialQuery({
    variables: {
      filter: searchMaterialValue !== '' ?
        compile(
          <Luq.Like field="name" pattern={`%${searchMaterialValue}%`} caseInsensitive />
        )
        :
        undefined
      ,
      limit: SEARCH_LIMIT,
    }
  });

  const { data: manualsData, loading: manualsLoading, error: manualsError } = useSearchMaintenanceStockZoneQuery({
    variables: {
      filter: searchManualValue !== '' ?
        compile(
          <Luq.And>
            <Luq.Eq field="type" value={'manual'} />
            <Luq.Like field="name" pattern={`%${searchManualValue}%`} caseInsensitive />
          </Luq.And>
        )
        :
        compile(
          <Luq.Eq field="type" value={'manual'} />
        )
      ,
      limit: SEARCH_LIMIT,
    }
  });

  const allZones = useMemo(() => {
    return [
      ...(sitesData?.searchMaintenanceSite.map(item => item.stockZoneId) || []),
      ...(stockZonesData?.searchMaintenanceStockZone || []),
      ...(materialsData?.searchMaintenanceMaterial.map(item => item.stockZoneId) || []),
      ...(manualsData?.searchMaintenanceStockZone || []),
    ];
  }, [
    sitesData?.searchMaintenanceSite,
    stockZonesData?.searchMaintenanceStockZone,
    materialsData?.searchMaintenanceMaterial,
    manualsData?.searchMaintenanceStockZone,
  ]);

  return (
    <>
      <IonItem onClick={() => setShowSelect(true)} className="noborder-list-item">
        <IonLabel>{label}</IonLabel>
        <div style={{ width: '19px', height: '19px' }}>
          <IonIcon
            style={{ color: 'black', opacity: 0.33, width: '14px', height: '14px', marginTop: '3px', marginLeft: '3px' }}
            slot="end"
            size="small"
            icon={caretDownSharp}
          />
        </div>
      </IonItem>
      <div className="searchable-select-selection" style={{ marginBottom: '10px', borderBottom: 'solid 1px #CCC' }}>
        {newStockMove[keyChanged] !== undefined && allZones.find((elt) => elt?.id === newStockMove[keyChanged]) !== undefined &&
          <ul>
            {allZones.find((elt) => elt?.id === newStockMove[keyChanged])?.name}
          </ul>
        }
      </div>
      <IonModal isOpen={showSelect} onDidDismiss={() => setShowSelect(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Sélectionner une source</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <SearchableSelect
            key={'select-source-site'}
            label="Site"
            value={newStockMove[keyChanged] === 0 ? null : newStockMove[keyChanged]}
            onChange={event => setNewStockMove(event.value || 0)}
            searchValue={searchSiteValue}
            searchFunction={setSearchSiteValue}
            loading={sitesLoading}
            closeModal={() => setShowSelect(false)}
          >
            {
              (sitesData?.searchMaintenanceSite || []).map((value, index) =>
                <SearchableSelect.Option
                  key={value.stockZoneId?.id || index}
                  value={value.stockZoneId?.id}
                  label={value.stockZoneId?.name || ''}
                />
              )}
          </SearchableSelect>
          <SearchableSelect
            key={'select-source-zone'}
            label="Zone"
            value={newStockMove[keyChanged] === 0 ? null : newStockMove[keyChanged]}
            onChange={event => setNewStockMove(event.value || 0)}
            searchValue={searchZoneValue}
            searchFunction={setSearchZoneValue}
            loading={stockZonesLoading}
            closeModal={() => setShowSelect(false)}
          >
            {
              (stockZonesData?.searchMaintenanceStockZone || []).map((value, index) =>
                <SearchableSelect.Option
                  key={value.id || index}
                  value={value.id}
                  label={value.name || ''}
                />
              )}
          </SearchableSelect>
          <SearchableSelect
            key={'select-source-material'}
            label="Matériel"
            value={newStockMove[keyChanged] === 0 ? null : newStockMove[keyChanged]}
            onChange={event => setNewStockMove(event.value || 0)}
            searchValue={searchMaterialValue}
            searchFunction={setSearchMaterialValue}
            loading={materialsLoading}
            closeModal={() => setShowSelect(false)}
          >
            {
              (materialsData?.searchMaintenanceMaterial || []).map((value, index) =>
                <SearchableSelect.Option
                  key={value.stockZoneId?.id || index}
                  value={value.stockZoneId?.id}
                  label={value.stockZoneId?.name || ''}
                />
              )}
          </SearchableSelect>
          <SearchableSelect
            key={'select-source-manual'}
            label="Autre"
            value={newStockMove[keyChanged] === 0 ? null : newStockMove[keyChanged]}
            onChange={event => setNewStockMove(event.value || 0)}
            searchValue={searchManualValue}
            searchFunction={setSearchManualValue}
            loading={manualsLoading}
            closeModal={() => setShowSelect(false)}
          >
            {
              (manualsData?.searchMaintenanceStockZone || []).map((value, index) =>
                <SearchableSelect.Option
                  key={value.id || index}
                  value={value.id}
                  label={value.name || ''}
                />
              )}
          </SearchableSelect>
        </IonContent>
        <IonFooter>
          <IonButton
            expand="full"
            onClick={() => setShowSelect(false)}
          >
            Annuler
          </IonButton>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default SearchableZoneSelect;