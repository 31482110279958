import {
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
} from '@ionic/react';
import {
  useEffect,
  useState
} from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { MutationUpdateMaintenanceOperationArgs } from 'apollo/types';
import { useUpdateMaintenanceOperationMutation } from 'apollo/mutations';
import { useMaintenanceOperationQuery } from '../../apollo/queries';

import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';

interface UpdateOperationProps
  extends RouteComponentProps<{
    processingId: string;
    operationId: string;
  }> {}

const UpdateOperation: React.FC<UpdateOperationProps> = ({ match }) => {
  /* Gather route params */
  const processingId = Number(match.params.processingId);
  const operationId = Number(match.params.operationId);

  const history = useHistory();

  const [
    operation,
    setOperation,
  ] = useState<MutationUpdateMaintenanceOperationArgs>({
    id: operationId,
    name: '',
    comment: '',
  });

  const [ updateMaintenanceOperation, { loading: loadingUpdateProcessing } ] = useUpdateMaintenanceOperationMutation();

  const {
    error: errorOperation,
    data: dataOperation,
  } = useMaintenanceOperationQuery({
    variables: {
      id: operationId,
    }
  });
  const operationInformation = !errorOperation ? dataOperation?.getMaintenanceOperation : null;

  /* Updates operation store when operationInformation is updated */
  useEffect(() => {
    setOperation({
      ...operation,
      name: operationInformation?.name,
      comment: operationInformation?.comment,
    });
  }, [operationInformation]);

  /* Check if operation is valid */
  const isOperationValid = operation.name !== '';

  /* Handle edit form changes, updates store with new values */
  const handleChange = ({ key, value }: {
    key: string;
    value: string | Number;
  }) => {
    setOperation({
      ...operation,
      [key]: value,
    });
  };

  /* Handle submit button, updates operation and then go back */
  const handleSubmit = () => {
    updateMaintenanceOperation({
      variables: operation,
    });
    history.goBack();
  };

  return (
    <PageLayout
      menu
      backButtonLink={`/processing/${processingId}/`}
      footer={
        <IonButton
          disabled={!isOperationValid}
          expand="full"
          onClick={handleSubmit}
        >
          Valider
        </IonButton>
      }
    >
      <IonItem>
        <IonInput
          value={operation?.name}
          placeholder="Titre"
          onIonChange={e =>
            handleChange({ key: 'name', value: e.detail.value || '' })
          }
        />
      </IonItem>
      <div className="bordered-input-textarea">
        <IonTextarea
          value={operation?.comment}
          placeholder="Description"
          onIonChange={e =>
            handleChange({ key: 'comment', value: e.detail.value || '' })
          }
        ></IonTextarea>
      </div>
    </PageLayout>
  );
};

export default withAuthentication(UpdateOperation);
