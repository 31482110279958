
import { useRef, useState } from 'react';
import Scanner from 'components/Scanner';
import type { ScannerDecoderType } from 'components/Scanner/ScannerDecoderType';
import { IonButton, IonContent, IonFooter, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';

const decoders: ScannerDecoderType[] = [
  'ean_reader',
  'qr_code',
  'code_128_reader',
  'ean_8_reader',
  'code_39_reader',
  'code_39_vin_reader',
  'codabar_reader',
  'upc_reader',
  'upc_e_reader',
  'i2of5_reader',
  '2of5_reader',
  'code_93_reader',
  'code_32_reader',
];

interface ScannerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDetected: (code: string) => void;
}

const ScannerModal: React.FC<ScannerModalProps> = ({ isOpen, onClose, onDetected }) => {
  const scannerViewRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [ renderScanner, setRenderScanner ] = useState(false);
  const cameraViewWidth: number = window.innerWidth;

  /* Without this mechanism, the scanner never starts */
  renderScanner === false && isOpen && setTimeout(
    () => {
      setRenderScanner(true);
    },
    100,
  );

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Scanner</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          ref={scannerViewRef}
          style={
            {
              position: 'relative',
              width: '100%',
              // border: '3px solid red'
              margin: '10px'
            }
          }
        >
          <video
            style={
              {
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: cameraViewWidth - 20,
                // height: cameraViewHeight,
                border: '3px solid orange',
              }
            }
            width={cameraViewWidth - 20}
            // height={cameraViewHeight}
          />
          {isOpen && renderScanner && <Scanner
            scannerViewRef={scannerViewRef}
            onDetected={e => e.codeResult.code && onDetected(e.codeResult.code)}
            decoders={decoders}
          />}
        </div>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="full"
          onClick={onClose}
        >
          Annuler
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default ScannerModal;