import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';

import PageLayout from 'layouts/PageLayout';

import withAuthentication from 'components/AuthenticatedRoute';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import SectionTitle from 'components/SectionTitle';
import MaterialInfoView from 'components/MaterialInfoView';
import MaterialProcessingHistoryView from 'components/MaterialProcessingHistoryView';

import { useMaterialQuery } from 'apollo/queries';

interface MaterialPageProps
  extends RouteComponentProps<{
    materialId: string;
  }> {}

const MaterialPage: React.FC<MaterialPageProps> = ({ match }) => {
  const materialID: number = Number(match.params.materialId);

  const {
    loading: loadingMaterial,
    error: errorMaterial,
    data: dataMaterial,
    refetch: refetchMaterial,
  } = useMaterialQuery({
    variables: {
      id: materialID,
    },
  });

  if (loadingMaterial) {
    return (
      <Spinner />
    );
  }

  const materialInformation = !errorMaterial ? dataMaterial?.getMaintenanceMaterial : null;

  if (!materialInformation) {
    return (
      <Alert
        message="Il n'y a pas de materiel à afficher."
        secondaryMessage="Merci de rafraîchir la page."
      />
    );
  }

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title="Matériel"
      onRefresh={
        async () => {
          await refetchMaterial(
            {
              id: materialID,
            },
          );
        }
      }
    >
      <IonCard>
        <IonCardHeader>
          <IonCardTitle
            style={
              {
                textAlign: 'center'
              }
            }
          >
            {materialInformation.name}
          </IonCardTitle>
        </IonCardHeader>
      </IonCard>
      <SectionTitle title="Informations" />
      <IonCardContent>
        <MaterialInfoView material={materialInformation} />
      </IonCardContent>
      <SectionTitle title="Historique des traitements" />
      <IonCardContent>
        <MaterialProcessingHistoryView materialID={materialID} />
      </IonCardContent>
    </PageLayout>
  );
};

export default withAuthentication(MaterialPage);
