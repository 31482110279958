import { 
  IonButton, 
  IonCol, 
  IonContent, 
  IonFooter, 
  IonHeader, 
  IonIcon, 
  IonImg, 
  IonModal, 
  IonRow, 
  IonTitle, 
  IonToolbar 
} from '@ionic/react';
import { usePhotoGallery, Photo } from 'hooks/usePhotoGallery';
import { camera, closeCircle } from 'ionicons/icons';

interface UploadPhotosProps {
  onValidate: (images: Photo[]) => void;
  isOpen: boolean;
}

const UploadPhotos: React.FC<UploadPhotosProps> = ({ onValidate, isOpen }) => {
  const { photos, takePhoto, removePhoto, clear } = usePhotoGallery();

  const selectImage = (): void => {
    takePhoto();
  };

  const handleRemove = (photo: Photo): void => {
    removePhoto(photo);
  };

  const handleValidate = (): void => {
    onValidate(photos);
    clear();
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Ajouter des photos
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow>
          {photos.map((photo, i) => 
            <IonCol size="4" key={i}>
              <IonImg src={photo.webviewPath} />
              <IonButton size="small" color="danger" expand="full" onClick={() => handleRemove(photo)}>
                <IonIcon icon={closeCircle} size="small" />
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonRow>
          <IonCol size="6">
            <IonButton fill="clear" expand="full" onClick={selectImage}>
              <IonIcon slot="start" icon={camera} /> Ajouter
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton fill="clear" expand="full" onClick={handleValidate}>
              {photos.length > 0 ? 'Valider' : 'Annuler'}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonModal>
  );
};

export default UploadPhotos;
