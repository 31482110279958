import { ToastOptions, IonToast } from '@ionic/react';
import React, { useContext, useRef, useState } from 'react';
import { closeOutline } from 'ionicons/icons';

type NotifyFunction = (options: ToastOptions) => void;

const context = React.createContext<React.MutableRefObject<NotifyFunction | null> | null>(null);

interface ProviderProps {
  children: React.ReactNode;
};

type ToastsType = { [key: string]: ToastOptions };

const useToasts = () => {
  const toastKey = useRef(0);
  const [toasts, setToasts] = useState<ToastsType>({});

  const addToast = (options: ToastOptions) => {
    setToasts({
      ...toasts,
      [toastKey.current++]: options,
    });
  };

  const removeToast = (key: string) => {
    const newToasts = { ...toasts };
    delete newToasts[key];
    setToasts(newToasts);
  };

  return { toasts, addToast, removeToast };
};

interface ToastsProps {
  addToastRef: React.MutableRefObject<NotifyFunction | null>;
}

const Toasts: React.FC<ToastsProps> = ({ addToastRef }) => {
  const { toasts, addToast, removeToast } = useToasts();

  addToastRef.current = addToast;

  return <>
    {
      Object.entries(toasts).map(([key, options]) => {

        const dismiss = () => removeToast(key);

        return (
          <IonToast
            key={key}
            isOpen
            animated
            onDidDismiss={dismiss}
            buttons={[{ icon: closeOutline, handler: dismiss }]}
            duration={5000}
            {...options}
          />
        );
      })
    }
  </>;
};

export const NotificationsProvider: React.FC<ProviderProps> = ({ children }) => {
  const addToastRef = useRef<NotifyFunction | null>(null);

  return <context.Provider value={addToastRef}>
    {children}
    <Toasts addToastRef={addToastRef} />
  </context.Provider>;
};

export const useNotifier = () => {
  const addToastRef = useContext(context);

  return (options: ToastOptions) => {
    if (addToastRef !== null && addToastRef.current !== null) {
      addToastRef.current(options);
    }
  };
};

export default useNotifier;