import { useSearchProcessingsQuery } from 'apollo/queries';
import compile from 'utils/domain';
import * as Luq from 'luq';
import useUserId from 'utils/userId';

const useCurrentIntervention = () => {
  const userId = useUserId();
  const { data } = useSearchProcessingsQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="techId" value={userId} />
          <Luq.Eq field="state" value="open" />
        </Luq.And>
      )
    }
  });
  if (data?.searchMaintenanceProcessing && 
      data?.searchMaintenanceProcessing.length > 0
  ) {
    return data?.searchMaintenanceProcessing[0].id;
  } else {
    return null;
  }
};

export default useCurrentIntervention;