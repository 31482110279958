export enum ProcessingStateKeysEnum {
  waiting = 'waiting',
  open = 'open',
  closed = 'closed',
  report = 'report',
  unknown = 'unknown',
};

export const ProcessingState = {
  waiting: 'Non démarré',
  open: 'En cours',
  closed: 'Terminé',
  report: 'Rapporté',
  unknown: 'État inconnu',
};

export const interventionStateFromEnum = (key: ProcessingStateKeysEnum): string => {
  return ProcessingState[key];
};