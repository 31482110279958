import React from 'react';
import { useHistory } from 'react-router';
import {
  IonButton,
  IonIcon,
} from '@ionic/react';
import { qrCodeOutline } from 'ionicons/icons';
import useNotifier from 'utils/notification';

interface QRBCScannerButtonProps {
  isInterOngoing?: boolean;
}

const QRBCScannerButton: React.FC<QRBCScannerButtonProps> = ({ isInterOngoing = false }) => {
  const history = useHistory();
  const notify = useNotifier();

  const onClickScannerButton: React.MouseEventHandler<HTMLIonButtonElement> = () => {
    if (isInterOngoing) {
      notify(
        {
          position: 'top',
          color: 'danger',
          message: `Une intervention est déjà en cours...`
        }
      );
    } else {
      history.push('/scanner');
    }
  };

  return (
    <IonButton
      expand="full"
      color="success"
      onClick={onClickScannerButton}
      disabled={isInterOngoing}
    >
      <IonIcon icon={qrCodeOutline} />
      &nbsp;
      Scanner un QR/Bar code
    </IonButton>
  );
};

export default QRBCScannerButton;
