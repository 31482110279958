import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import {
  IonIcon,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonText,
  IonAlert,
} from '@ionic/react';
import {
  informationCircleOutline,
  constructOutline,
  documentTextOutline,
  informationCircle as informationCircleFull,
  construct as constructFull,
  documentText as documentTextFull,
  camera,
  cameraOutline,
  play,
  briefcase,
  briefcaseOutline,
} from 'ionicons/icons';

import { useProcessingQuery } from 'apollo/queries';
import { MutationUpdateMaintenanceProcessingArgs } from 'apollo/types';
import {
  useAddPhotoToProcessingMutation,
  useBeginProcessingMutation,
  useCloseProcessingMutation,
  useUpdateProcessingMutation
} from 'apollo/mutations';

import PageLayout from 'layouts/PageLayout';

import Spinner from 'components/Spinner';
import withAuthentication from 'components/AuthenticatedRoute';
import Alert from 'components/Alert';

import ProcessingInfoView from './ProcessingInfoView';
import ProcessingOperations from './ProcessingOperations';
import ProcessingReportView from './ProcessingReportView';

import { formatDateForSQL } from 'utils/formatDate';
import { ProcessingStateKeysEnum } from 'utils/ProcessingState';
import useNotifier from 'utils/notification';

import './styles.css';
import ProcessingPhotos from './ProcessingPhotos';
import UploadPhotos from 'components/UploadPhotos';
import { Photo } from 'hooks/usePhotoGallery';
import useCurrentProcessing from 'hooks/useCurrentProcessing';
import { Link } from 'react-router-dom';
import { InterventionStateKeysEnum } from 'utils/InterventionState';
import ProcessingStock from './ProcessingStock';

interface ProcessingPageProps
  extends RouteComponentProps<{
    processingId: string;
  }> {
  isOld: boolean;
}

type ProcessingViewsKeys = 'processing-info' |
  'processing-operations' |
  'processing-photos' |
  'processing-stock' |
  'processing-report';

type ProcessingViews = {
  [key in ProcessingViewsKeys]: () => React.ReactNode;
};

const ProcessingPage: React.FC<ProcessingPageProps> = ({ match, isOld }) => {
  const processingId = Number(match.params.processingId);

  const [ processingViewKey, setProcessingViewKey ] = useState('processing-info' as ProcessingViewsKeys);

  const {
    loading: loadingProcessing,
    error: errorProcessing,
    data: dataProcessing,
    refetch: refetchProcessing,
  } = useProcessingQuery({
    variables: {
      processingId: processingId,
    },
  });

  const processing = !loadingProcessing && !errorProcessing && dataProcessing ? dataProcessing.getMaintenanceProcessing : null;
  const intervention = processing && processing.interventionId;

  const [ beginProcessing,  { loading: loadingBeginProcessing }  ] = useBeginProcessingMutation();
  const [ updateProcessing, { loading: loadingUpdateProcessing } ] = useUpdateProcessingMutation();
  const [ closeProcessing,  { loading: loadingCloseProcessing }  ] = useCloseProcessingMutation();

  const [ showPhotoModal, setShowPhotoModal ] = useState(false);
  const [ alertMessage, setAlertMessage ] = useState('');
  const currentProcessingId = useCurrentProcessing();

  const history = useHistory();
  const notify = useNotifier();

  const [ addPhotoToProcessing ] = useAddPhotoToProcessingMutation({
    update(cache, { data: addPhotoToProcessing }) {
      cache.modify({
        id: cache.identify({
          __typename: 'MaintenanceProcessing',
          id: Number(match.params.processingId),
        }),
        fields: {
          imageIds(oldImages = []) {
            return [
              ...oldImages,
              {
                __ref: cache.identify({
                  __typename: 'MaintenanceImage',
                  id: addPhotoToProcessing?.createMaintenanceImage?.created?.id
                })
              },
            ];
          }
        },
      });
    }
  });

  const [localReport, setLocalReport] = useState<MutationUpdateMaintenanceProcessingArgs>({
    id: processing?.id || 0,
    result: processing?.result || '',
    comment: processing?.comment || '',
    materialKeepedStopped: processing?.materialKeepedStopped || false,
    installationOk: processing?.installationOk || false,
  });

  const isReportValid = localReport.result !== '';

  useEffect(() => {
    setLocalReport({
      id: processing?.id || 0,
      result: processing?.result || '',
      comment: processing?.comment || '',
      materialKeepedStopped: processing?.materialKeepedStopped || false,
      installationOk: processing?.installationOk || false,
    });
  }, [processing, setLocalReport]);

  const handleUploadPhotos = (photos: Photo[]) => {
    for (const photo of photos) {
      addPhotoToProcessing(
        {
          variables: {
            processingId,
            name: photo.filepath,
            base64image: photo.base64data.split(',')[1],
          },
        }
      ).catch(
        (pException) => {
          console.error(`[ERROR] <ProcessingPage> beginProcessing failed :`, pException);
          throw new Error(`[ERROR] <ProcessingPage> beginProcessing failed !`);
        }
      );
    };
    setShowPhotoModal(false);
  };


  const onClickProcessingButton = (): void => {
    if (currentProcessingId) {
      setAlertMessage('Un traitement est déjà en cours sur un autre matériel. Pour en démarrer un nouveau, merci de finaliser le précédent.');
      return;
    }
    if (
      intervention?.state?.key === 'doing' &&
      intervention.currentReportId &&
      processing?.state?.key === 'waiting'
    ) {
      beginProcessing(
        {
          variables: {
            processingId: Number(match.params.processingId),
            interventionReportId: intervention?.currentReportId?.id,
            currentDate: formatDateForSQL(new Date()),
          },
        }
      ).catch(
        (pException) => {
          console.error(`[ERROR] <ProcessingPage> beginProcessing failed :`, pException);
          throw new Error(`[ERROR] <ProcessingPage> beginProcessing failed !`);
        }
      );
    }
  };

  const onSaveReport = (): void => {
    updateProcessing(
      {
        variables: localReport,
      }
    ).catch(
      (pException) => {
        console.error(`[ERROR] <ProcessingPage> updateProcessing failed :`, pException);
        throw new Error(`[ERROR] <ProcessingPage> updateProcessing failed !`);
      }
    );
  };

  const onValidateReport = (): void => {
    if (isReportValid) {
      closeProcessing(
        {
          variables: {
            ...localReport,
            currentDate: formatDateForSQL(new Date()),
          },
        }
      ).then(
        (pResult) => {
          if (intervention) {
            history.push(`/intervention/${intervention.id}`);
          } else {
            history.push('/interventions');
          }
        }
      ).catch(
        (pException) => {
          console.error(`[ERROR] <ProcessingPage> closeProcessing failed :`, pException);
          throw new Error(`[ERROR] <ProcessingPage> closeProcessing failed !`);
        }
      );
    } else {
      notify({
        color: 'warning',
        message: 'Merci de remplir le résultat du traitement avant de sauvegarder.',
      });
    }
  };

  const processingViews: ProcessingViews = {
    'processing-info': () => {
      return (
        <ProcessingInfoView
          processing={processing}
        />
      );
    },
    'processing-operations': () => {
      return (
        <ProcessingOperations
          interventionId={intervention?.id}
          processing={processing}
          edit={processing?.state?.key === 'open'}
        />
      );
    },
    'processing-photos': () => {
      return (
        <ProcessingPhotos
          photos={processing?.imageIds || []}
        />
      );
    },
    'processing-stock': () => {
      return (
        <ProcessingStock
          processingId={processingId}
          materialStocks={processing?.materialId?.stockIds || []}
          siteStocks={processing?.siteId?.stockIds || []}
          processingStockMoves={processing?.moveIds || []}
          edit={processing?.state?.key === 'open'}
        />
      );
    },
    'processing-report': () => {
      return (
        <ProcessingReportView
          edit={processing?.state?.key === 'open'}
          report={localReport}
          saveReport={onSaveReport}
          setReport={setLocalReport}
        />
      );
    },
  };

  const processingFooters: ProcessingViews = {
    'processing-info': () => {
      if (
        processing?.interventionId?.state?.key === InterventionStateKeysEnum.doing &&
        processing?.state?.key === ProcessingStateKeysEnum.waiting
      ) {
        return <IonButton
          expand="full"
          color="success"
          onClick={onClickProcessingButton}
        >
          <IonIcon icon={play} /> Démarrer le traitement
        </IonButton>;
      } else {
        return null;
      }
    },
    'processing-operations': () => {
      if (processing?.state?.key === ProcessingStateKeysEnum.open) {
        return <Link to={`/processing/${processing?.id}/createOperation`}>
          <IonButton expand="full">
            Ajouter une opération
          </IonButton>
        </Link>;
      } else {
        return null;
      }
    },
    'processing-photos': () => {
      if (processing?.state?.key === ProcessingStateKeysEnum.open) {
        return (
          <>
            <IonButton expand="full" onClick={() => setShowPhotoModal(true)}>Ajouter des photos</IonButton>
            <UploadPhotos isOpen={showPhotoModal} onValidate={handleUploadPhotos} />
          </>
        );
      } else {
        return null;
      }
    },
    'processing-stock': () => {
      if (processing?.state?.key === ProcessingStateKeysEnum.open) {
        return <Link to={`/processing/${processing?.id}/createStockMove`}>
          <IonButton expand="full">
            Ajouter un mouvement
          </IonButton>
        </Link>;
      } else {
        return null;
      }
    },
    'processing-report': () => {
      if (processing?.state?.key === ProcessingStateKeysEnum.open) {
        return (
          <IonButton
            expand="full"
            onClick={onValidateReport}
          >
            Valider
          </IonButton>
        );
      } else {
        return null;
      }
    },
  };

  return (
    <PageLayout
      menu
      backButtonLink="/interventions"
      title={isOld ? 'Ancien traitement' : 'Traitement'}
      footer={
        processing?.interventionId?.state?.key === InterventionStateKeysEnum.doing &&
        processing?.state?.key === ProcessingStateKeysEnum.waiting ?
          processingFooters['processing-info']() :
          processingFooters[processingViewKey]()
      }
      onRefresh={
        async () => {
          await refetchProcessing(
            {
              processingId: processingId,
            },
          );
        }
      }
    >
      <IonAlert
        isOpen={alertMessage !== ''}
        onDidDismiss={() => setAlertMessage('')}
        header={'Erreur'}
        message={alertMessage}
        buttons={[
          {
            text: 'Voir',
            cssClass: 'primary',
            handler: () => {
              setAlertMessage('');
              history.push(`/processing/${currentProcessingId}`);
            }
          },
          {
            text: 'Annuler',
            role: 'cancel',
            cssClass: 'secondary',
          }
        ]}
      />
      {
        loadingProcessing
          ? (
            <Spinner />
          )
          : errorProcessing
            ? (
              <Alert
                type="error"
                title="Erreur"
                message={errorProcessing.message}
                showIcon
              />
            )
            : (
              <>
                <IonToolbar>
                  <IonSegment
                    value={processingViewKey}
                    onIonChange={e => e.detail.value &&
                        setProcessingViewKey(e.detail.value as ProcessingViewsKeys)
                    }
                  >
                    <IonSegmentButton
                      value="processing-info"
                    >
                      {
                        processingViewKey === 'processing-info' ?
                          <IonIcon icon={informationCircleFull} /> :
                          <IonIcon icon={informationCircleOutline} />
                      }
                      <IonText style={{ fontSize: '11px' }}>Infos</IonText>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="processing-operations"
                    >
                      {
                        processingViewKey === 'processing-operations' ?
                          <IonIcon icon={constructFull} /> :
                          <IonIcon icon={constructOutline} />
                      }
                      <IonText style={{ fontSize: '11px' }}>Oprts</IonText>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="processing-photos"
                    >
                      {
                        processingViewKey === 'processing-photos' ?
                          <IonIcon icon={camera} /> :
                          <IonIcon icon={cameraOutline} />
                      }
                      <IonText style={{ fontSize: '11px' }}>Photos</IonText>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="processing-stock"
                    >
                      {
                        processingViewKey === 'processing-stock' ?
                          <IonIcon icon={briefcase} /> :
                          <IonIcon icon={briefcaseOutline} />
                      }
                      <IonText style={{ fontSize: '11px' }}>Articles</IonText>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="processing-report"
                    >
                      {
                        processingViewKey === 'processing-report' ?
                          <IonIcon icon={documentTextFull} /> :
                          <IonIcon icon={documentTextOutline} />
                      }
                      <IonText style={{ fontSize: '11px' }}>Rapport</IonText>
                    </IonSegmentButton>
                  </IonSegment>
                </IonToolbar>
                {processingViews[processingViewKey]()}
              </>
            )
      }
    </PageLayout>
  );
};

export default withAuthentication(ProcessingPage);
