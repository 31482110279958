import {
  IonList,
  IonListHeader,
  IonImg,
} from '@ionic/react';

import Alert from 'components/Alert';
import InterventionListItem from './InterventionListItem';

import { InterventionFieldsLightFragment } from 'apollo/queries/types';

import './styles.css';

interface InterventionsListProps {
  interventions: InterventionFieldsLightFragment[],
  title?: string;
}

const InterventionList: React.FC<InterventionsListProps> = ({ interventions, title }) => {
  return (
    <IonList>
      {title && <IonListHeader>
        {title}
      </IonListHeader>}
      {
        (!interventions) ?
          <Alert
            message="Il n'y a pas d'historique de traitements à afficher."
            secondaryMessage="Merci de rafraîchir la page."
          /> :
          (interventions.length < 1) ?
            <div
              style={
                {
                  textAlign: 'center',
                }
              }
            >
              <IonImg
                style={
                  {
                    maxWidth: '20%',
                    display: 'inline-block',
                  }
                }
                src="/assets/empty-data.svg"
              />
              <h6>
                {`Pas d'interventions à afficher`}
              </h6>
            </div> :
            interventions.map(
              (intervention, index) => {
                return (
                  <InterventionListItem
                    key={intervention.id}
                    intervention={intervention}
                  />
                );
              }
            )
      }
    </IonList>
  );
};

export default InterventionList;
