import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on MaintenanceImage {
    id
    name
    url128
    url1920
  }
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on ResPartner {
    id
    name
    street
    street2
    zip
    city
    phone
    mobile
    email
  }
`;
export const SelectionFragmentDoc = gql`
  fragment Selection on Selection {
    key
    value
  }
`;
export const SiteFieldsFragmentDoc = gql`
  fragment SiteFields on MaintenanceSite {
    id
    name
    street
    street2
    zip
    city
    phone
    mobile
    email
    displayAddress
    partnerId {
      ...PartnerFields
    }
    clientId {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export const InterventionFieldsLightFragmentDoc = gql`
  fragment InterventionFieldsLight on MaintenanceIntervention {
    id
    name
    description
    clientId {
      ...PartnerFields
      childIds {
        ...PartnerFields
      }
      image128
    }
    typeId {
      id
      name
    }
    domainId {
      id
      name
    }
    interventionDate
    state {
      ...Selection
    }
    siteId {
      ...SiteFields
    }
  }
  ${PartnerFieldsFragmentDoc}
  ${SelectionFragmentDoc}
  ${SiteFieldsFragmentDoc}
`;
export const ZoneFieldsFragmentDoc = gql`
  fragment ZoneFields on MaintenanceSiteZone {
    id
    name
    description
  }
`;
export const InterventionFieldsFullFragmentDoc = gql`
  fragment InterventionFieldsFull on MaintenanceIntervention {
    ...InterventionFieldsLight
    comment
    createDate
    orderDate
    writeDate
    startDate
    endDate
    origin
    zoneId {
      ...ZoneFields
    }
  }
  ${InterventionFieldsLightFragmentDoc}
  ${ZoneFieldsFragmentDoc}
`;
export const InterventionReportFieldsFragmentDoc = gql`
  fragment InterventionReportFields on MaintenanceInterventionReport {
    id
    name
    state {
      ...Selection
    }
    startDate
    endDate
    description
    comment
    signature
    onContract
    nbNights
    nbMeals
    journeyTime
    productionStop
    multipleTechs
    multipleTechsComment
    forceCloseIntervention
  }
  ${SelectionFragmentDoc}
`;
export const MaterialFieldsFragmentDoc = gql`
  fragment MaterialFields on MaintenanceMaterial {
    id
    name
    description
    comment
    displayName
    number
    barcode
    installDate
    previousTurnover
    nextTurnover
    materialType {
      id
      name
    }
    clientId {
      id
      name
      image128
    }
    siteId {
      id
      name
    }
    zoneId {
      id
      name
    }
  }
`;
export const OperationFieldsFragmentDoc = gql`
  fragment OperationFields on MaintenanceOperation {
    id
    name
    description
    comment
    date
    done
  }
`;
export const ProcessingFieldsFragmentDoc = gql`
  fragment ProcessingFields on MaintenanceProcessing {
    id
    name
    description
    startDate
    endDate
    result
    materialKeepedStopped
    installationOk
    comment
    state {
      ...Selection
    }
  }
  ${SelectionFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on MaintenanceProduct {
    id
    name
    description
    ref
    barcode
  }
`;
export const StockZoneFieldsFragmentDoc = gql`
  fragment StockZoneFields on MaintenanceStockZone {
    id
    name
    type {
      key
      value
    }
    external
  }
`;
export const StockFieldsFragmentDoc = gql`
  fragment StockFields on MaintenanceStock {
    id
    quantity
    articleId {
      ...ProductFields
    }
    stockZoneId {
      ...StockZoneFields
    }
  }
  ${ProductFieldsFragmentDoc}
  ${StockZoneFieldsFragmentDoc}
`;
export const StockMoveFieldsFragmentDoc = gql`
  fragment StockMoveFields on MaintenanceStockMove {
    id
    displayName
    quantity
    abortMoveId {
      id
    }
    abortedByMoveIds {
      id
    }
    articleId {
      ...ProductFields
    }
    originId {
      ...StockZoneFields
    }
    destinationId {
      ...StockZoneFields
    }
    processingId {
      id
    }
  }
  ${ProductFieldsFragmentDoc}
  ${StockZoneFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on ResUsers {
    id
    name
    image128
  }
`;
export const ClientDocument = gql`
  query Client($id: Int!) {
    getResPartner(id: $id) {
      ...PartnerFields
      comment
      image256
      childIds {
        id
        name
      }
      siteIds {
        id
        name
      }
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export type ClientProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ClientQuery,
    Types.ClientQueryVariables
  >;
} &
  TChildProps;
export function withClient<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ClientQuery,
    Types.ClientQueryVariables,
    ClientProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ClientQuery,
    Types.ClientQueryVariables,
    ClientProps<TChildProps, TDataName>
  >(ClientDocument, {
    alias: "client",
    ...operationOptions,
  });
}

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ClientQuery,
    Types.ClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ClientQuery, Types.ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export function useClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ClientQuery,
    Types.ClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ClientQuery, Types.ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<
  Types.ClientQuery,
  Types.ClientQueryVariables
>;
export const ContactDocument = gql`
  query Contact($id: Int!) {
    getResPartner(id: $id) {
      ...PartnerFields
      comment
      image256
    }
  }
  ${PartnerFieldsFragmentDoc}
`;
export type ContactProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ContactQuery,
    Types.ContactQueryVariables
  >;
} &
  TChildProps;
export function withContact<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ContactQuery,
    Types.ContactQueryVariables,
    ContactProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ContactQuery,
    Types.ContactQueryVariables,
    ContactProps<TChildProps, TDataName>
  >(ContactDocument, {
    alias: "contact",
    ...operationOptions,
  });
}

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ContactQuery,
    Types.ContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ContactQuery, Types.ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export function useContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ContactQuery,
    Types.ContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ContactQuery, Types.ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<
  Types.ContactQuery,
  Types.ContactQueryVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    getMyData {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type CurrentUserProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >;
} &
  TChildProps;
export function withCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables,
    CurrentUserProps<TChildProps, TDataName>
  >(CurrentUserDocument, {
    alias: "currentUser",
    ...operationOptions,
  });
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CurrentUserQuery,
    Types.CurrentUserQueryVariables
  >(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  Types.CurrentUserQuery,
  Types.CurrentUserQueryVariables
>;
export const InterventionDocument = gql`
  query Intervention($id: Int!) {
    getMaintenanceIntervention(id: $id) {
      ...InterventionFieldsFull
      managerId {
        ...UserFields
      }
      processingIds {
        ...ProcessingFields
        materialId {
          ...MaterialFields
        }
      }
      currentReportId {
        ...InterventionReportFields
      }
      interventionReportIds {
        ...InterventionReportFields
      }
    }
  }
  ${InterventionFieldsFullFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProcessingFieldsFragmentDoc}
  ${MaterialFieldsFragmentDoc}
  ${InterventionReportFieldsFragmentDoc}
`;
export type InterventionProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.InterventionQuery,
    Types.InterventionQueryVariables
  >;
} &
  TChildProps;
export function withIntervention<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.InterventionQuery,
    Types.InterventionQueryVariables,
    InterventionProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.InterventionQuery,
    Types.InterventionQueryVariables,
    InterventionProps<TChildProps, TDataName>
  >(InterventionDocument, {
    alias: "intervention",
    ...operationOptions,
  });
}

/**
 * __useInterventionQuery__
 *
 * To run a query within a React component, call `useInterventionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterventionQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.InterventionQuery,
    Types.InterventionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.InterventionQuery,
    Types.InterventionQueryVariables
  >(InterventionDocument, options);
}
export function useInterventionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.InterventionQuery,
    Types.InterventionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.InterventionQuery,
    Types.InterventionQueryVariables
  >(InterventionDocument, options);
}
export type InterventionQueryHookResult = ReturnType<
  typeof useInterventionQuery
>;
export type InterventionLazyQueryHookResult = ReturnType<
  typeof useInterventionLazyQuery
>;
export type InterventionQueryResult = Apollo.QueryResult<
  Types.InterventionQuery,
  Types.InterventionQueryVariables
>;
export const InterventionListDocument = gql`
  query InterventionList(
    $currentFilter: String!
    $todayFilter: String!
    $otherFilter: String!
    $doneTodayFilter: String!
    $limit: Int
    $offset: Int
    $order: String
  ) {
    currentIntervention: searchMaintenanceIntervention(filter: $currentFilter) {
      ...InterventionFieldsLight
    }
    todayInterventions: searchMaintenanceIntervention(
      filter: $todayFilter
      order: $order
    ) {
      ...InterventionFieldsLight
    }
    otherInterventions: searchMaintenanceIntervention(
      filter: $otherFilter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      ...InterventionFieldsLight
    }
    doneTodayInterventions: searchMaintenanceIntervention(
      filter: $doneTodayFilter
    ) {
      ...InterventionFieldsLight
    }
  }
  ${InterventionFieldsLightFragmentDoc}
`;
export type InterventionListProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables
  >;
} &
  TChildProps;
export function withInterventionList<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables,
    InterventionListProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables,
    InterventionListProps<TChildProps, TDataName>
  >(InterventionListDocument, {
    alias: "interventionList",
    ...operationOptions,
  });
}

/**
 * __useInterventionListQuery__
 *
 * To run a query within a React component, call `useInterventionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterventionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterventionListQuery({
 *   variables: {
 *      currentFilter: // value for 'currentFilter'
 *      todayFilter: // value for 'todayFilter'
 *      otherFilter: // value for 'otherFilter'
 *      doneTodayFilter: // value for 'doneTodayFilter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInterventionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables
  >(InterventionListDocument, options);
}
export function useInterventionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.InterventionListQuery,
    Types.InterventionListQueryVariables
  >(InterventionListDocument, options);
}
export type InterventionListQueryHookResult = ReturnType<
  typeof useInterventionListQuery
>;
export type InterventionListLazyQueryHookResult = ReturnType<
  typeof useInterventionListLazyQuery
>;
export type InterventionListQueryResult = Apollo.QueryResult<
  Types.InterventionListQuery,
  Types.InterventionListQueryVariables
>;
export const LoggedInDocument = gql`
  query LoggedIn {
    isLoggedIn @client
  }
`;
export type LoggedInProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >;
} &
  TChildProps;
export function withLoggedIn<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables,
    LoggedInProps<TChildProps, TDataName>
  >(LoggedInDocument, {
    alias: "loggedIn",
    ...operationOptions,
  });
}

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export function useLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LoggedInQuery,
    Types.LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LoggedInQuery, Types.LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<
  typeof useLoggedInLazyQuery
>;
export type LoggedInQueryResult = Apollo.QueryResult<
  Types.LoggedInQuery,
  Types.LoggedInQueryVariables
>;
export const MaintenanceOperationDocument = gql`
  query MaintenanceOperation($id: Int!) {
    getMaintenanceOperation(id: $id) {
      id
      name
      done
      description
      comment
    }
  }
`;
export type MaintenanceOperationProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables
  >;
} &
  TChildProps;
export function withMaintenanceOperation<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables,
    MaintenanceOperationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables,
    MaintenanceOperationProps<TChildProps, TDataName>
  >(MaintenanceOperationDocument, {
    alias: "maintenanceOperation",
    ...operationOptions,
  });
}

/**
 * __useMaintenanceOperationQuery__
 *
 * To run a query within a React component, call `useMaintenanceOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceOperationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables
  >(MaintenanceOperationDocument, options);
}
export function useMaintenanceOperationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.MaintenanceOperationQuery,
    Types.MaintenanceOperationQueryVariables
  >(MaintenanceOperationDocument, options);
}
export type MaintenanceOperationQueryHookResult = ReturnType<
  typeof useMaintenanceOperationQuery
>;
export type MaintenanceOperationLazyQueryHookResult = ReturnType<
  typeof useMaintenanceOperationLazyQuery
>;
export type MaintenanceOperationQueryResult = Apollo.QueryResult<
  Types.MaintenanceOperationQuery,
  Types.MaintenanceOperationQueryVariables
>;
export const MaterialDocument = gql`
  query Material($id: Int!) {
    getMaintenanceMaterial(id: $id) {
      ...MaterialFields
    }
  }
  ${MaterialFieldsFragmentDoc}
`;
export type MaterialProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.MaterialQuery,
    Types.MaterialQueryVariables
  >;
} &
  TChildProps;
export function withMaterial<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.MaterialQuery,
    Types.MaterialQueryVariables,
    MaterialProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.MaterialQuery,
    Types.MaterialQueryVariables,
    MaterialProps<TChildProps, TDataName>
  >(MaterialDocument, {
    alias: "material",
    ...operationOptions,
  });
}

/**
 * __useMaterialQuery__
 *
 * To run a query within a React component, call `useMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaterialQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MaterialQuery,
    Types.MaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MaterialQuery, Types.MaterialQueryVariables>(
    MaterialDocument,
    options
  );
}
export function useMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MaterialQuery,
    Types.MaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MaterialQuery, Types.MaterialQueryVariables>(
    MaterialDocument,
    options
  );
}
export type MaterialQueryHookResult = ReturnType<typeof useMaterialQuery>;
export type MaterialLazyQueryHookResult = ReturnType<
  typeof useMaterialLazyQuery
>;
export type MaterialQueryResult = Apollo.QueryResult<
  Types.MaterialQuery,
  Types.MaterialQueryVariables
>;
export const SearchMaterialDocument = gql`
  query searchMaterial(
    $filter: String
    $domain: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    materials: searchMaintenanceMaterial(
      filter: $filter
      domain: $domain
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...MaterialFields
    }
  }
  ${MaterialFieldsFragmentDoc}
`;
export type SearchMaterialProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables
  >;
} &
  TChildProps;
export function withSearchMaterial<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables,
    SearchMaterialProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables,
    SearchMaterialProps<TChildProps, TDataName>
  >(SearchMaterialDocument, {
    alias: "searchMaterial",
    ...operationOptions,
  });
}

/**
 * __useSearchMaterialQuery__
 *
 * To run a query within a React component, call `useSearchMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMaterialQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      domain: // value for 'domain'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchMaterialQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables
  >(SearchMaterialDocument, options);
}
export function useSearchMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchMaterialQuery,
    Types.SearchMaterialQueryVariables
  >(SearchMaterialDocument, options);
}
export type SearchMaterialQueryHookResult = ReturnType<
  typeof useSearchMaterialQuery
>;
export type SearchMaterialLazyQueryHookResult = ReturnType<
  typeof useSearchMaterialLazyQuery
>;
export type SearchMaterialQueryResult = Apollo.QueryResult<
  Types.SearchMaterialQuery,
  Types.SearchMaterialQueryVariables
>;
export const MaterialProcessingHistoryDocument = gql`
  query MaterialProcessingHistory(
    $filter: String!
    $domain: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    processingHistory: searchMaintenanceProcessing(
      filter: $filter
      domain: $domain
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...ProcessingFields
      techId {
        id
        name
      }
    }
  }
  ${ProcessingFieldsFragmentDoc}
`;
export type MaterialProcessingHistoryProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables
  >;
} &
  TChildProps;
export function withMaterialProcessingHistory<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables,
    MaterialProcessingHistoryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables,
    MaterialProcessingHistoryProps<TChildProps, TDataName>
  >(MaterialProcessingHistoryDocument, {
    alias: "materialProcessingHistory",
    ...operationOptions,
  });
}

/**
 * __useMaterialProcessingHistoryQuery__
 *
 * To run a query within a React component, call `useMaterialProcessingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialProcessingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialProcessingHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      domain: // value for 'domain'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMaterialProcessingHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables
  >(MaterialProcessingHistoryDocument, options);
}
export function useMaterialProcessingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.MaterialProcessingHistoryQuery,
    Types.MaterialProcessingHistoryQueryVariables
  >(MaterialProcessingHistoryDocument, options);
}
export type MaterialProcessingHistoryQueryHookResult = ReturnType<
  typeof useMaterialProcessingHistoryQuery
>;
export type MaterialProcessingHistoryLazyQueryHookResult = ReturnType<
  typeof useMaterialProcessingHistoryLazyQuery
>;
export type MaterialProcessingHistoryQueryResult = Apollo.QueryResult<
  Types.MaterialProcessingHistoryQuery,
  Types.MaterialProcessingHistoryQueryVariables
>;
export const ProcessingDocument = gql`
  query Processing($processingId: Int!) {
    getMaintenanceProcessing(id: $processingId) {
      ...ProcessingFields
      materialId {
        ...MaterialFields
        zoneId {
          ...ZoneFields
        }
        stockIds {
          ...StockFields
        }
      }
      operationIds {
        ...OperationFields
      }
      interventionId {
        ...InterventionFieldsLight
        currentReportId {
          ...InterventionReportFields
        }
      }
      siteId {
        ...SiteFields
        stockIds {
          ...StockFields
        }
      }
      imageIds {
        ...ImageFields
      }
      moveIds {
        ...StockMoveFields
      }
    }
  }
  ${ProcessingFieldsFragmentDoc}
  ${MaterialFieldsFragmentDoc}
  ${ZoneFieldsFragmentDoc}
  ${StockFieldsFragmentDoc}
  ${OperationFieldsFragmentDoc}
  ${InterventionFieldsLightFragmentDoc}
  ${InterventionReportFieldsFragmentDoc}
  ${SiteFieldsFragmentDoc}
  ${ImageFieldsFragmentDoc}
  ${StockMoveFieldsFragmentDoc}
`;
export type ProcessingProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables
  >;
} &
  TChildProps;
export function withProcessing<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables,
    ProcessingProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables,
    ProcessingProps<TChildProps, TDataName>
  >(ProcessingDocument, {
    alias: "processing",
    ...operationOptions,
  });
}

/**
 * __useProcessingQuery__
 *
 * To run a query within a React component, call `useProcessingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingQuery({
 *   variables: {
 *      processingId: // value for 'processingId'
 *   },
 * });
 */
export function useProcessingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProcessingQuery, Types.ProcessingQueryVariables>(
    ProcessingDocument,
    options
  );
}
export function useProcessingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ProcessingQuery,
    Types.ProcessingQueryVariables
  >(ProcessingDocument, options);
}
export type ProcessingQueryHookResult = ReturnType<typeof useProcessingQuery>;
export type ProcessingLazyQueryHookResult = ReturnType<
  typeof useProcessingLazyQuery
>;
export type ProcessingQueryResult = Apollo.QueryResult<
  Types.ProcessingQuery,
  Types.ProcessingQueryVariables
>;
export const ProcessingStockZonesDocument = gql`
  query ProcessingStockZones($processingId: Int!) {
    getMaintenanceProcessing(id: $processingId) {
      siteId {
        stockZoneId {
          ...StockZoneFields
        }
      }
      materialId {
        stockZoneId {
          ...StockZoneFields
        }
      }
    }
    searchMaintenanceStockZone {
      ...StockZoneFields
    }
    searchMaintenanceProduct {
      ...ProductFields
    }
  }
  ${StockZoneFieldsFragmentDoc}
  ${ProductFieldsFragmentDoc}
`;
export type ProcessingStockZonesProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables
  >;
} &
  TChildProps;
export function withProcessingStockZones<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables,
    ProcessingStockZonesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables,
    ProcessingStockZonesProps<TChildProps, TDataName>
  >(ProcessingStockZonesDocument, {
    alias: "processingStockZones",
    ...operationOptions,
  });
}

/**
 * __useProcessingStockZonesQuery__
 *
 * To run a query within a React component, call `useProcessingStockZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingStockZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingStockZonesQuery({
 *   variables: {
 *      processingId: // value for 'processingId'
 *   },
 * });
 */
export function useProcessingStockZonesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables
  >(ProcessingStockZonesDocument, options);
}
export function useProcessingStockZonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ProcessingStockZonesQuery,
    Types.ProcessingStockZonesQueryVariables
  >(ProcessingStockZonesDocument, options);
}
export type ProcessingStockZonesQueryHookResult = ReturnType<
  typeof useProcessingStockZonesQuery
>;
export type ProcessingStockZonesLazyQueryHookResult = ReturnType<
  typeof useProcessingStockZonesLazyQuery
>;
export type ProcessingStockZonesQueryResult = Apollo.QueryResult<
  Types.ProcessingStockZonesQuery,
  Types.ProcessingStockZonesQueryVariables
>;
export const SearchMaintenanceStockZoneDocument = gql`
  query SearchMaintenanceStockZone(
    $filter: String
    $offset: Int
    $limit: Int!
  ) {
    searchMaintenanceStockZone(
      filter: $filter
      offset: $offset
      limit: $limit
    ) {
      ...StockZoneFields
    }
  }
  ${StockZoneFieldsFragmentDoc}
`;
export type SearchMaintenanceStockZoneProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables
  >;
} &
  TChildProps;
export function withSearchMaintenanceStockZone<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables,
    SearchMaintenanceStockZoneProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables,
    SearchMaintenanceStockZoneProps<TChildProps, TDataName>
  >(SearchMaintenanceStockZoneDocument, {
    alias: "searchMaintenanceStockZone",
    ...operationOptions,
  });
}

/**
 * __useSearchMaintenanceStockZoneQuery__
 *
 * To run a query within a React component, call `useSearchMaintenanceStockZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMaintenanceStockZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMaintenanceStockZoneQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchMaintenanceStockZoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables
  >(SearchMaintenanceStockZoneDocument, options);
}
export function useSearchMaintenanceStockZoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchMaintenanceStockZoneQuery,
    Types.SearchMaintenanceStockZoneQueryVariables
  >(SearchMaintenanceStockZoneDocument, options);
}
export type SearchMaintenanceStockZoneQueryHookResult = ReturnType<
  typeof useSearchMaintenanceStockZoneQuery
>;
export type SearchMaintenanceStockZoneLazyQueryHookResult = ReturnType<
  typeof useSearchMaintenanceStockZoneLazyQuery
>;
export type SearchMaintenanceStockZoneQueryResult = Apollo.QueryResult<
  Types.SearchMaintenanceStockZoneQuery,
  Types.SearchMaintenanceStockZoneQueryVariables
>;
export const SearchMaintenanceSiteDocument = gql`
  query SearchMaintenanceSite($filter: String, $offset: Int, $limit: Int!) {
    searchMaintenanceSite(filter: $filter, offset: $offset, limit: $limit) {
      stockZoneId {
        ...StockZoneFields
      }
    }
  }
  ${StockZoneFieldsFragmentDoc}
`;
export type SearchMaintenanceSiteProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables
  >;
} &
  TChildProps;
export function withSearchMaintenanceSite<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables,
    SearchMaintenanceSiteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables,
    SearchMaintenanceSiteProps<TChildProps, TDataName>
  >(SearchMaintenanceSiteDocument, {
    alias: "searchMaintenanceSite",
    ...operationOptions,
  });
}

/**
 * __useSearchMaintenanceSiteQuery__
 *
 * To run a query within a React component, call `useSearchMaintenanceSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMaintenanceSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMaintenanceSiteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchMaintenanceSiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables
  >(SearchMaintenanceSiteDocument, options);
}
export function useSearchMaintenanceSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchMaintenanceSiteQuery,
    Types.SearchMaintenanceSiteQueryVariables
  >(SearchMaintenanceSiteDocument, options);
}
export type SearchMaintenanceSiteQueryHookResult = ReturnType<
  typeof useSearchMaintenanceSiteQuery
>;
export type SearchMaintenanceSiteLazyQueryHookResult = ReturnType<
  typeof useSearchMaintenanceSiteLazyQuery
>;
export type SearchMaintenanceSiteQueryResult = Apollo.QueryResult<
  Types.SearchMaintenanceSiteQuery,
  Types.SearchMaintenanceSiteQueryVariables
>;
export const SearchMaintenanceMaterialDocument = gql`
  query SearchMaintenanceMaterial($filter: String, $offset: Int, $limit: Int!) {
    searchMaintenanceMaterial(filter: $filter, offset: $offset, limit: $limit) {
      stockZoneId {
        ...StockZoneFields
      }
    }
  }
  ${StockZoneFieldsFragmentDoc}
`;
export type SearchMaintenanceMaterialProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables
  >;
} &
  TChildProps;
export function withSearchMaintenanceMaterial<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables,
    SearchMaintenanceMaterialProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables,
    SearchMaintenanceMaterialProps<TChildProps, TDataName>
  >(SearchMaintenanceMaterialDocument, {
    alias: "searchMaintenanceMaterial",
    ...operationOptions,
  });
}

/**
 * __useSearchMaintenanceMaterialQuery__
 *
 * To run a query within a React component, call `useSearchMaintenanceMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMaintenanceMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMaintenanceMaterialQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchMaintenanceMaterialQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables
  >(SearchMaintenanceMaterialDocument, options);
}
export function useSearchMaintenanceMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchMaintenanceMaterialQuery,
    Types.SearchMaintenanceMaterialQueryVariables
  >(SearchMaintenanceMaterialDocument, options);
}
export type SearchMaintenanceMaterialQueryHookResult = ReturnType<
  typeof useSearchMaintenanceMaterialQuery
>;
export type SearchMaintenanceMaterialLazyQueryHookResult = ReturnType<
  typeof useSearchMaintenanceMaterialLazyQuery
>;
export type SearchMaintenanceMaterialQueryResult = Apollo.QueryResult<
  Types.SearchMaintenanceMaterialQuery,
  Types.SearchMaintenanceMaterialQueryVariables
>;
export const SearchMaintenanceProductDocument = gql`
  query SearchMaintenanceProduct(
    $filter: String
    $offset: Int
    $limit: Int!
    $order: String
  ) {
    searchMaintenanceProduct(
      filter: $filter
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...ProductFields
    }
  }
  ${ProductFieldsFragmentDoc}
`;
export type SearchMaintenanceProductProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables
  >;
} &
  TChildProps;
export function withSearchMaintenanceProduct<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables,
    SearchMaintenanceProductProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables,
    SearchMaintenanceProductProps<TChildProps, TDataName>
  >(SearchMaintenanceProductDocument, {
    alias: "searchMaintenanceProduct",
    ...operationOptions,
  });
}

/**
 * __useSearchMaintenanceProductQuery__
 *
 * To run a query within a React component, call `useSearchMaintenanceProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMaintenanceProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMaintenanceProductQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchMaintenanceProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables
  >(SearchMaintenanceProductDocument, options);
}
export function useSearchMaintenanceProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchMaintenanceProductQuery,
    Types.SearchMaintenanceProductQueryVariables
  >(SearchMaintenanceProductDocument, options);
}
export type SearchMaintenanceProductQueryHookResult = ReturnType<
  typeof useSearchMaintenanceProductQuery
>;
export type SearchMaintenanceProductLazyQueryHookResult = ReturnType<
  typeof useSearchMaintenanceProductLazyQuery
>;
export type SearchMaintenanceProductQueryResult = Apollo.QueryResult<
  Types.SearchMaintenanceProductQuery,
  Types.SearchMaintenanceProductQueryVariables
>;
export const SearchInterventionsLightDocument = gql`
  query SearchInterventionsLight(
    $filter: String!
    $domain: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    interventions: searchMaintenanceIntervention(
      filter: $filter
      domain: $domain
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...InterventionFieldsLight
    }
  }
  ${InterventionFieldsLightFragmentDoc}
`;
export type SearchInterventionsLightProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables
  >;
} &
  TChildProps;
export function withSearchInterventionsLight<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables,
    SearchInterventionsLightProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables,
    SearchInterventionsLightProps<TChildProps, TDataName>
  >(SearchInterventionsLightDocument, {
    alias: "searchInterventionsLight",
    ...operationOptions,
  });
}

/**
 * __useSearchInterventionsLightQuery__
 *
 * To run a query within a React component, call `useSearchInterventionsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInterventionsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInterventionsLightQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      domain: // value for 'domain'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchInterventionsLightQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables
  >(SearchInterventionsLightDocument, options);
}
export function useSearchInterventionsLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchInterventionsLightQuery,
    Types.SearchInterventionsLightQueryVariables
  >(SearchInterventionsLightDocument, options);
}
export type SearchInterventionsLightQueryHookResult = ReturnType<
  typeof useSearchInterventionsLightQuery
>;
export type SearchInterventionsLightLazyQueryHookResult = ReturnType<
  typeof useSearchInterventionsLightLazyQuery
>;
export type SearchInterventionsLightQueryResult = Apollo.QueryResult<
  Types.SearchInterventionsLightQuery,
  Types.SearchInterventionsLightQueryVariables
>;
export const SearchInterventionsFullDocument = gql`
  query SearchInterventionsFull(
    $filter: String
    $domain: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    interventions: searchMaintenanceIntervention(
      filter: $filter
      domain: $domain
      offset: $offset
      limit: $limit
      order: $order
    ) {
      ...InterventionFieldsFull
      managerId {
        ...UserFields
      }
      processingIds {
        ...ProcessingFields
        materialId {
          ...MaterialFields
        }
      }
      currentReportId {
        ...InterventionReportFields
      }
    }
  }
  ${InterventionFieldsFullFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${ProcessingFieldsFragmentDoc}
  ${MaterialFieldsFragmentDoc}
  ${InterventionReportFieldsFragmentDoc}
`;
export type SearchInterventionsFullProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables
  >;
} &
  TChildProps;
export function withSearchInterventionsFull<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables,
    SearchInterventionsFullProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables,
    SearchInterventionsFullProps<TChildProps, TDataName>
  >(SearchInterventionsFullDocument, {
    alias: "searchInterventionsFull",
    ...operationOptions,
  });
}

/**
 * __useSearchInterventionsFullQuery__
 *
 * To run a query within a React component, call `useSearchInterventionsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInterventionsFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInterventionsFullQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      domain: // value for 'domain'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchInterventionsFullQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables
  >(SearchInterventionsFullDocument, options);
}
export function useSearchInterventionsFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchInterventionsFullQuery,
    Types.SearchInterventionsFullQueryVariables
  >(SearchInterventionsFullDocument, options);
}
export type SearchInterventionsFullQueryHookResult = ReturnType<
  typeof useSearchInterventionsFullQuery
>;
export type SearchInterventionsFullLazyQueryHookResult = ReturnType<
  typeof useSearchInterventionsFullLazyQuery
>;
export type SearchInterventionsFullQueryResult = Apollo.QueryResult<
  Types.SearchInterventionsFullQuery,
  Types.SearchInterventionsFullQueryVariables
>;
export const SearchProcessingsDocument = gql`
  query SearchProcessings($filter: String!) {
    searchMaintenanceProcessing(filter: $filter) {
      ...ProcessingFields
    }
  }
  ${ProcessingFieldsFragmentDoc}
`;
export type SearchProcessingsProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables
  >;
} &
  TChildProps;
export function withSearchProcessings<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables,
    SearchProcessingsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables,
    SearchProcessingsProps<TChildProps, TDataName>
  >(SearchProcessingsDocument, {
    alias: "searchProcessings",
    ...operationOptions,
  });
}

/**
 * __useSearchProcessingsQuery__
 *
 * To run a query within a React component, call `useSearchProcessingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProcessingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProcessingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchProcessingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables
  >(SearchProcessingsDocument, options);
}
export function useSearchProcessingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SearchProcessingsQuery,
    Types.SearchProcessingsQueryVariables
  >(SearchProcessingsDocument, options);
}
export type SearchProcessingsQueryHookResult = ReturnType<
  typeof useSearchProcessingsQuery
>;
export type SearchProcessingsLazyQueryHookResult = ReturnType<
  typeof useSearchProcessingsLazyQuery
>;
export type SearchProcessingsQueryResult = Apollo.QueryResult<
  Types.SearchProcessingsQuery,
  Types.SearchProcessingsQueryVariables
>;
export const SiteDocument = gql`
  query Site($id: Int!) {
    getMaintenanceSite(id: $id) {
      ...SiteFields
      hasDeliveryAddress
      deliveryStreet
      deliveryStreet2
      deliveryZip
      deliveryCity
      hasInvoicingAddress
      invoicingStreet
      invoicingStreet2
      invoicingZip
      invoicingCity
      zoneIds {
        id
        name
      }
      materialIds {
        id
        name
      }
    }
  }
  ${SiteFieldsFragmentDoc}
`;
export type SiteProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.SiteQuery,
    Types.SiteQueryVariables
  >;
} &
  TChildProps;
export function withSite<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.SiteQuery,
    Types.SiteQueryVariables,
    SiteProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.SiteQuery,
    Types.SiteQueryVariables,
    SiteProps<TChildProps, TDataName>
  >(SiteDocument, {
    alias: "site",
    ...operationOptions,
  });
}

/**
 * __useSiteQuery__
 *
 * To run a query within a React component, call `useSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SiteQuery,
    Types.SiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SiteQuery, Types.SiteQueryVariables>(
    SiteDocument,
    options
  );
}
export function useSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SiteQuery,
    Types.SiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SiteQuery, Types.SiteQueryVariables>(
    SiteDocument,
    options
  );
}
export type SiteQueryHookResult = ReturnType<typeof useSiteQuery>;
export type SiteLazyQueryHookResult = ReturnType<typeof useSiteLazyQuery>;
export type SiteQueryResult = Apollo.QueryResult<
  Types.SiteQuery,
  Types.SiteQueryVariables
>;
export const ZoneDocument = gql`
  query Zone($id: Int!) {
    getMaintenanceSiteZone(id: $id) {
      ...ZoneFields
      comment
      siteId {
        id
        name
      }
      clientId {
        id
        name
      }
      materialIds {
        id
        name
      }
    }
  }
  ${ZoneFieldsFragmentDoc}
`;
export type ZoneProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.ZoneQuery,
    Types.ZoneQueryVariables
  >;
} &
  TChildProps;
export function withZone<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.ZoneQuery,
    Types.ZoneQueryVariables,
    ZoneProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.ZoneQuery,
    Types.ZoneQueryVariables,
    ZoneProps<TChildProps, TDataName>
  >(ZoneDocument, {
    alias: "zone",
    ...operationOptions,
  });
}

/**
 * __useZoneQuery__
 *
 * To run a query within a React component, call `useZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useZoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ZoneQuery,
    Types.ZoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ZoneQuery, Types.ZoneQueryVariables>(
    ZoneDocument,
    options
  );
}
export function useZoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ZoneQuery,
    Types.ZoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ZoneQuery, Types.ZoneQueryVariables>(
    ZoneDocument,
    options
  );
}
export type ZoneQueryHookResult = ReturnType<typeof useZoneQuery>;
export type ZoneLazyQueryHookResult = ReturnType<typeof useZoneLazyQuery>;
export type ZoneQueryResult = Apollo.QueryResult<
  Types.ZoneQuery,
  Types.ZoneQueryVariables
>;
